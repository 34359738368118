import gql from "graphql-tag";
import { createWildfiresReview } from "../../graphql/mutations";
import { CognitoConfig } from '../wildfires/utils/CognitoConfig';

export async function createWildfireReview (data) {
	const now = new Date();
	const nowString = now.toISOString().slice(0, 16);

	const newReview = await CognitoConfig.mutate({
		mutation: gql(createWildfiresReview),
		variables: {
			input: {
				...data,
				review_date: nowString
			}
		},
	});
	return newReview;
}
