import { useTranslation } from 'react-i18next'
import { Box, Divider, Slide, TextField, Typography } from "@mui/material";
import React from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
} from "../../StyledComponents/Dialog";
import { Button } from "../../StyledComponents/Button";
import emailjs from "emailjs-com";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Feedback({ open, close }) {
	const { t } = useTranslation();
	const messageRef = React.useRef();
	const nameRef = React.useRef();
	const emailRef = React.useRef();
	const userData = useSelector((state) => state.user.userData.userData);
	const loggedIn = useSelector((state) => state.user?.isLoggedIn);

	const handleSubmit = () => {
		const data = {
			name: loggedIn ? userData.email : nameRef.current.value,
			email: loggedIn ? userData.name : emailRef.current.value,
			msg1: messageRef.current.value,
			msg2: "",
		};

		if (messageRef.current.value.length === 0) {
			swal(
				"Has dejado campos sin completar",
				"Por favor, rellena todos los campos antes de enviar",
				"error"
			);
			return;
		}

		emailjs
			.send(
				"service_6xrfft1",
				"template_90bw56f",
				data,
				"user_nxVUgnLDmTUknTkYy4Qzg"
			)
			.then(
				(res) => {
					//dispatch(modalActions.toggleFeedbackModal());
					close();
					swal(
						t('swal-enviar-comentario-titulo'),
						t('swal-enviar-comentario-texto'),
						"success"
					);
				},
				(err) => {
					swal(
						t('swal-error-enviar-comentario-titulo'),
						t('swal-error-enviar-comentario-texto'),
						"error"
					);
				}
			);
	};

	const handleClose = async () => {
		close();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="md"
				scroll="body"
				TransitionComponent={Transition}
			>
				<DialogTitle>{t('titulo-comentarios')}</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "40px",
					}}
				>
					<Box
						//component="form"
						//onSubmit={handleSubmit}
						sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
					>
						<Box
							display="flex"
							flexDirection="column"
							gap="20px"
							alignItems="center"
						>
							<Typography
								variant="span"
								color="#707176"
								fontSize="1rem"
								width="95%"
							>
								{t('texto-comentarios')}
							</Typography>
							{!loggedIn ? (
								<>
									<TextField
										inputRef={nameRef}
										name="name"
										label={t("campo-nombre")}
										variant="standard"
										sx={{ width: "90%" }}
										required
									/>
									<TextField
										inputRef={emailRef}
										name="email"
										label={t("campo-mail")}
										variant="standard"
										sx={{ width: "90%" }}
										required
									/>
								</>
							) : (
								<></>
							)}
							<TextField
								inputRef={messageRef}
								name="text"
								label={t('placeholder-campo-comentarios')}
								variant="standard"
								sx={{ width: "90%" }}
								required
							/>
						</Box>
						<Button
							//type="submit"
							onClick={handleSubmit}
							variant="contained"
							sx={{
								width: "fit-content",
								alignSelf: "flex-end",
								marginRight: "5%",
							}}
						>
							{t('boton-enviar-mensaje')}
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
