import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function NotFound() {
  return (
    <>
      <div className="mt-5 p-3 text-center bg-light w-75 ml-auto mr-auto rounded-lg shadow-lg ">
        <h1 className=" mb-5">You are in 404 page</h1>
        <h4 className="mb-5">This means you have followed the wrong link</h4>
        <Link to="/">
          <Button variant="dark" size="lg">
            Go back to Home Page
          </Button>
        </Link>
      </div>
    </>
  );
}