import {
	Add,
	EditOutlined,
	NotificationImportantOutlined
} from "@mui/icons-material";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItemButton,
	ListItemText, Tooltip, Typography
} from "@mui/material";
import MuiListItem from "@mui/material/ListItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loginIlustration from "../../../assets/loginIlustration.svg";
import notPremiumIlustration from "../../../assets/notPremiumIlustration.svg";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as polygonsActions } from "../../../redux/slices/polygonsSlice";
import { actions as settings } from "../../../redux/slices/settingsSlice";
import { actions as tourActions } from "../../../redux/slices/tourSlice";
import NotificationsDialog from "../../modals/NotificationsDialog";
import userAccess from "../../../api/user/userAccess.json"
import {
	Button, InvertedLoadingButton, LightButton, LoadingButton
} from "../../StyledComponents/Button";
import TourResumer from "../../utils/TourResumer";
import EditarZona from "../Dialogs/EditarZona";
import NuevaZona from "../Dialogs/NuevaZona";
import PanelHeader from "../Panel/PanelHeader";

export default function MisZonas() {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const history = useHistory();
	const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const userType = useSelector((state) => state.user?.userData?.userData?.userType);
	const polygons = useSelector((state) => state.polygons.polygons);
	const notificationsEnabled = useSelector(
		(state) => state.settings.notificationsEnabled
	);
	const notificationsStatus = useSelector((state) => state.settings.status);
	const polygonStatus = useSelector((state) => state.polygons.status.createStatus)

	const [notiButtonState, setNotiButtonState] = useState(false);

	const closeDialog = () => history.push("/alertas");
	const openDialog = () => history.push("/alertas/crear");

	const handleEdit = (item) => {
		history.push("/alertas/editar?id=" + item.id);
	};

	useEffect(() => {
		if (!(history.location.pathname === "/alertas" &&
			userAccess.alerts.includes(userType) &&
			!localStorage.getItem("tour") &&
			polygons.length === 0)) return;

		localStorage.setItem("tour", "true");

		dispatch(tourActions.openModal())

	}, [history.location.pathname, polygons, userType, dispatch]);

	useEffect(() => {
		if (isLoggedIn) dispatch(polygonsActions.getPolygons());
		//eslint-disable-next-line
	}, [isLoggedIn]);

	React.useEffect(() => {
		//dispatch(mapActions.setPolygon([selectedAlerts.length ? selectedAlerts : [], polygons]));
		dispatch(mapActions.setPolygon([[], polygons]));
	}, [polygons, dispatch]);

	useEffect(() => {
		dispatch(settings.checkNotificationsEnabled());
	}, [dispatch]);

	useEffect(() => {
		if (notificationsEnabled) setNotiButtonState(true);
		else setNotiButtonState(false);
	}, [notificationsEnabled]);

	useEffect(() => {
		if (polygonStatus === 'succeded')
			if (!notificationsEnabled) {
				setTimeout(function () {
					setNotificationsDialogOpen(true)
				}, 2000)
				dispatch(polygonsActions.clearStatus());
			}
		//eslint-disable-next-line
	}, [polygonStatus]);

	const handleSendToForm = () => {
		history.push("/form");
	}

	const NotPremium = () => {
		const { t } = useTranslation();
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				gap="10px"
				overflow="hidden"
				sx={{ marginTop: "20px", height: "100%" }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="100%"
					textAlign="center"
					gap="20px"
					sx={{
						"& img": {
							width: "90%",
							maxHeight: "40%",
						},
						"& span.MuiTypography-root": {
							width: "95%",
						},
					}}
				>
					<img src={notPremiumIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t('rellenar-form-texto')}
					</Typography>
					<Button
						variant="contained"
						sx={{ width: "100%" }}
						onClick={() => handleSendToForm()}
					>
						{t('boton-rellenar-form')}
					</Button>
				</Box>
			</Box>
		);
	};

	return (
		<>
			<TourResumer wakeUpSteps={[0, 6, 12, 16]} />
			<Box sx={{ height: "100%" }} display="flex" flexDirection="column">
				<NotificationsDialog
					open={notificationsDialogOpen}
					setOpen={setNotificationsDialogOpen}
				/>
				<PanelHeader
					title={t("alertas")}
					action={
						isLoggedIn ? (
							notiButtonState ? (
								<Tooltip title={t("desactivar-alertas-tooltip")} placement="right">
									<InvertedLoadingButton
										id="tour-12"
										loading={notificationsStatus === "loading"}
										variant="contained"
										startIcon={<NotificationImportantOutlined />}
										onClick={() => {
											dispatch(settings.disableAlerts());
										}}
									>
										{t("desactivar-alertas")}
									</InvertedLoadingButton>
								</Tooltip>
							) : (
								<Tooltip title={t("activar-alertas-tooltip")} placement="right">
									<LoadingButton
										id="tour-12"
										loading={notificationsStatus === "loading"}
										variant="contained"
										startIcon={<NotificationImportantOutlined />}
										onClick={() => {
											dispatch(settings.enableAlerts());
										}}
									>
										{t("activar-alertas")}
									</LoadingButton>
								</Tooltip>
							)
						) : (
							<></>
						)
					}
				/>
				{isLoggedIn ? (
					<>
						{userAccess.alerts.includes(userType) ? (
							<>
								<NuevaZona
									open={history.location.pathname.includes("/alertas/crear")}
									close={closeDialog}
								/>
								<EditarZona
									open={history.location.pathname.includes("/alertas/editar")}
									close={closeDialog}
								/>

								<Box
									display="flex"
									flexDirection="column"
									justifyContent="space-between"
									gap="10px"
									overflow="hidden"
									sx={{ marginTop: "20px", height: "100%" }}
									id="tour-6"
								>
									{Object.values(polygons).length ? (
										<Box
											display="flex"
											flexDirection="column"
											gap="10px"
											overflow="hidden"
										//justifyContent="space-between"
										//sx={{ marginTop: "20px", height: "100%" }}
										>
											<Typography variant="span" color="#44464E" fontSize=".9rem">
												{t("typography-alertas")}
											</Typography>

											<List sx={{ overflowY: "auto" }} id="tour-7">
												{Object.values(polygons).map((item, i) => {
													return (
														<ListItem
															key={`zone-${item.id}`}
															item={item}
															editAction={handleEdit}
														/>
													);
												})}
											</List>

											<LightButton
												variant="contained"
												startIcon={<Add />}
												onClick={openDialog}
											>
												{t("agregar-zona")}
											</LightButton>

										</Box>
									) : (
										<NoZones openDialog={openDialog} />
									)}
									{/* {userType === "Cameras" &&
										<Box position="relative" alignSelf="end">
											<Button onClick={() => history.push("/camaras")} style={{ width: "6rem", alignSelf: "end" }} variant="outlined" startIcon={<VideoCameraBack />}>
												Camaras
											</Button>
										</Box>
									} */}
								</Box>
							</>
						)
							: (
								<NotPremium />
							)
						}
					</>
				) : (
					<NotLoggedIn />
				)}
			</Box>
		</>
	);
}


const ListItem = ({ item, editAction }) => {
	const history = useHistory();
	return (
		<>
			<MuiListItem
				secondaryAction={
					<IconButton onClick={() => editAction(item)}>
						<EditOutlined sx={{ color: "primary.main" }} />
					</IconButton>
				}
				disablePadding
			>
				<ListItemButton
					onClick={() => history.push("/alertas/zona?id=" + item.id)}
				>
					<ListItemText primary={item.name} secondary={item.description} />
				</ListItemButton>
			</MuiListItem>
			<Divider />
		</>
	);
};


const NoZones = ({ openDialog }) => {
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100%"
			textAlign="center"
			gap="30px"
			sx={{
				"& img": {
					width: "90%",
					maxHeight: "40%",
				},
				"& span.MuiTypography-root": {
					width: "95%",
				},
			}}
		>
			<img src={noZoneIlustration} alt="ilustración" />
			<Typography variant="span" color="#1B1B1F" fontSize="1rem">
				{t("no-zonas-texto")}
			</Typography>
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={openDialog}
				//sx={{ width: "45%" }}
				id="tour-0"
			>
				{t("agregar-zona")}
			</Button>
			{/* <Box display="flex" justifyContent="space-between">
				<LightButton
					variant="contained"
					startIcon={<PlayCircle />}
					sx={{ width: "50%" }}
					onClick={() => dispatch(tourActions.startTour())}
				>
					Tutorial
				</LightButton>
				<Button
					variant="contained"
					startIcon={<Add />}
					onClick={openDialog}
					sx={{ width: "45%" }}
					id="tour-0"
				>
					{t("agregar-zona")}
				</Button>
			</Box> */}
		</Box>
	);
};

export const NotLoggedIn = () => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			gap="10px"
			overflow="hidden"
			sx={{ height: "100%" }}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height="100%"
				textAlign="center"
				gap="20px"
				sx={{
					"& img": {
						width: "90%",
						maxHeight: "40%",
					},
					"& span.MuiTypography-root": {
						width: "95%",
					},
				}}
			>
				<img src={loginIlustration} alt="ilustración" />
				<Typography variant="span" color="#1B1B1F" fontSize="1rem">
					{t("no-logueado-alertas")}
				</Typography>
				<Button
					variant="contained"
					sx={{ width: "fit-content" }}
					onClick={() => history.push("/login")}
				>
					{t("iniciar-sesion")}
				</Button>
			</Box>
		</Box>
	);
};

