import gql from 'graphql-tag';
import { listUserCameras, listUserCameraEvents } from "../../graphql/queries";
import { updateCameraEvent, updateCamera } from "../../graphql/mutations";
import { CognitoConfig } from '../wildfires/utils/CognitoConfig';

async function getCams() {

    const { data: { listUserCameras: { items } } } = await CognitoConfig.query({
        query: gql(listUserCameras),
        limit: 10000,
        fetchPolicy: 'network-only'
        // https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
    });

    /* items.forEach((poly)=>{
        if (Array.isArray(poly.coords)) return;
        else poly.coords = JSON.parse(poly.coords)
    }) */
    return items;
}

async function query (cameraId, token = "") {
    let variables = { cameraId: cameraId, limit: 300 };
    if (token !== "") variables["nextToken"] = token;
    
    const { data: { listUserCameraEvents: { items, nextToken } } } = await CognitoConfig.query({
        query: gql(listUserCameraEvents),
        fetchPolicy: 'network-only',
        variables
    })
    return { items, nextToken }
}

async function get(cameraId, backwardsHours = 24) {

    let wildfires = [];
    let jsonQuery = {nextToken: ""};

    while (typeof(jsonQuery["nextToken"]) === "string") {

        jsonQuery = await query(cameraId,jsonQuery["nextToken"])

        for (const wildfire of jsonQuery["items"]) {
            const date = new Date(wildfire["date_id"].split("_")[0]);
            const diffTime = Math.abs(new Date().getTime() - date.getTime());
            const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
            if (diffHours <= backwardsHours) {
                wildfires.push(wildfire);
            }
            else{
                return wildfires;
            } 
        }
    }

    return wildfires;
}

async function putCameraEvent(data) {
    const newTodo = await CognitoConfig.mutate({ mutation: gql(updateCameraEvent), variables: { input: data } });
    return newTodo;
}

async function putCamera(data) {
    const newTodo = await CognitoConfig.mutate({ mutation: gql(updateCamera), variables: { input: data } });
    return newTodo;
}

const functions = {
    get,
    getCams,
    putCameraEvent,
    putCamera
};


export default functions;
//https://github.com/aws/serverless-application-model/issues/717