/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import {
	Close,
	DeleteOutlined,
	Done,
	EditOutlined,
	LayersOutlined,
} from "@mui/icons-material";
import {
	Box,
	Fab as MuiFab,
	IconButton,
	styled,
	TextField,
	Typography,
	FormControlLabel,
	Checkbox
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button } from "../../StyledComponents/Button";
import { useHistory, Switch, Route, useLocation } from "react-router-dom";
import LeafletMap from "../../mapa/LeafletMap";
import { useDispatch, useSelector } from "react-redux";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import ConfirmationDialog from "../../modals/ConfirmationDialog";
import { actions as mapActions } from "../../../redux/slices/mapSlice";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EditarZona({ setShowMap }) {
	const history = useHistory();
	const dispatch = useDispatch();
	let query = useQuery();

	const id = query.get("id");
	const polygons = useSelector((state) => state.polygons.polygons);
	const [polygon, setPolygon] = useState();
	const [datos, setDatos] = useState({
		nombreZona: "",
		description: "",
		coords: "",
		detectRays: false,
		detectWildfires: false,
	});

	React.useEffect(() => {
		//Se consigue el poligono con el ID del path
		if (polygons.length > 0) {
			polygons.forEach((poly) => {
				if (poly.id === id) setPolygon(poly)
			});
		}
	}, [polygons, id]);

	useEffect(() => {
		if (!polygon) return;
		setDatos({
			nombreZona: polygon.name,
			description: polygon.description,
			coords: polygon.coords,
			detectRays: polygon.detectRays,
			detectWildfires: polygon.detectWildfires,
		});
		dispatch(appbar.setTitle(`Editar ${polygon.name}`));
	}, [polygon]);

	useEffect(() => {
		setShowMap(false);
	}, []);

	const handleInputChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handleTypeOfAlertsChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const sendData = {
			id: polygon.id,
			name: datos.nombreZona,
			description: datos.description,
			coords: JSON.stringify(datos.coords),
			detectWildfires: datos.detectWildfires,
			detectRays: datos.detectRays,
		};
		dispatch(polygonActions.updatePolygon(sendData));
		history.push("/alertas");
	};

	const handleSubmit = (newFeatureCoords) => {
		let coordinates;
		if (newFeatureCoords) {
			coordinates = newFeatureCoords.map((item) =>
				item.map((elem) => [elem.lat, elem.lng])
			);
			coordinates = coordinates[0];
		} else coordinates = datos.coords;
		setDatos({
			...datos,
			coords: coordinates,
		});
		history.push("/alertas/editar?id=" + id);
	};

	return (
		<>
			{datos.nombreZona !== "" ? (
				<Switch>
					<Route path="/alertas/editar/dibujar">
						<DibujarZona handleSubmit={handleSubmit} datos={datos} />
					</Route>
					<Route path="/alertas/editar">
						<FormZona
							handleSubmit={handleFormSubmit}
							handleInputChange={handleInputChange}
							datos={datos}
							id={id}
							polygon={polygon}
							handleTypeOfAlertsChange={handleTypeOfAlertsChange}
						/>
					</Route>
				</Switch>
			) : (
				<></>
			)}
		</>
	);
}

const FormZona = ({ handleSubmit, handleInputChange, datos, id, polygon, handleTypeOfAlertsChange }) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const handleDelete = () => {
		setDeleteDialogOpen(true);
	};

	useEffect(() => {
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px">
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={handleDelete}
					>
						<DeleteOutlined />
					</IconButton>
					{/* <IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => window.abrirInstructivo()}
					>
						<InfoOutlined />
					</IconButton> */}
				</Box>
			)
		);
	}, []);

	return (
		<>
			<ConfirmationDialog
				zone={polygon}
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				afterClose={() => history.push("/alertas")}
			/>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{ height: "100%", padding: "20px 20px" }}
				display="flex"
				flexDirection="column"
				gap="20px"
			>
				<Box
					sx={{ height: "100%" }}
					display="flex"
					flexDirection="column"
					gap="20px"
				>
					<Typography
						variant="span"
						color="#707176"
						fontSize="1rem"
						//sx={{ marginTop: "20px" }}
					>
						{t('typography-editar-zona')}
					</Typography>
					<Box
						display="flex"
						flexDirection="column"
						gap="10px"
						sx={{ padding: "0 10px" }}
					>
						<TextField
							name="nombreZona"
							label={t("nombre-de-la-zona")}
							variant="standard"
							required
							defaultValue={datos.nombreZona}
							onChange={handleInputChange}
						/>
						<TextField
							name="description"
							label={t("descripcion-de-la-zona")}
							variant="standard"
							defaultValue={datos.description}
							onChange={handleInputChange}
						/>
					</Box>
					<Box display="flex" flexDirection="column" gap="5px" /* marginTop="20px" */>
							<Typography variant="span" color="#707176" fontSize="1rem">
							{t('typography-editar-focos-rayos')}
							</Typography>
							<Box
								display="flex"
								justifyContent="space-between"
								//gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
								width="70%"
								alignSelf="center"
							>
								<FormControlLabel
									name="detectWildfires"
									control={
										<Checkbox
											style={{
												color: "#B71C1C",
											}}
											edge="start"
											checked={datos.detectWildfires}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-calor')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>
								<FormControlLabel
									name="detectRays"
									control={
										<Checkbox
											style={{
												color: "#4264FB",
											}}
											edge="start"
											checked={datos.detectRays}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-rayos')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>

							</Box>
						</Box>
					<Box position="relative" sx={{ color: "white" }}>
					{/* <Typography
						variant="span"
						color="#707176"
						fontSize="1rem"
						sx={{ marginTop: "20px"}}

					>
						Clickea en el botón del lápiz para comenzar a editar los puntos de
						tu zona
					</Typography> */}
						<Box
							width="100%"
							position="relative"
							sx={{
								aspectRatio: "16 / 9",
								borderRadius: "10px",
								overflow: "hidden",
								filter: "brightness(30%)",
								"& *": { pointerEvents: "none" },
								"& .leaflet-control-container": { display: "none" },
							}}
						>
							<LeafletMap
								featureCoords={datos.coords}
								edit={true}
								mapProps={{
									doubleClickZoom: false,
									closePopupOnClick: false,
									dragging: false,
									zoomSnap: false,
									zoomDelta: false,
									trackResize: false,
									touchZoom: false,
									scrollWheelZoom: false,
								}}
							/>
						</Box>
						<Button
							variant="outlined"
							color="inherit"
							startIcon={<EditOutlined />}
							onClick={() => history.push("/alertas/editar/dibujar?id=" + id)}
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 10,
							}}
						>
							{t('titulo-editar-zona')}
						</Button>
					</Box>
				<Button variant="contained" type="submit">
					{t('boton-guardar-cambios')}
				</Button>
				</Box>
			</Box>
		</>
	);
};

const DibujarZona = ({ handleSubmit, datos }) => {
	const history = useHistory();
	const [editing, setEditing] = useState(false);
	const [featureCoords, setFeatureCoords] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [drawControl, setDrawControl] = useState();

	const dispatch = useDispatch();
	useEffect(() => {
		if (!datos.nombreZona) {
			history.push("/alertas");
			return;
		}
	}, []);

	useEffect(() => {
		if (submitting) {
			handleSubmit(featureCoords);
			setSubmitting(false);
		}
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px">
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => {
							if (editing) {
								saveEdit();
								setSubmitting(true);
							} else {
								handleSubmit(featureCoords);
							}
						}}
					>
						<Done />
					</IconButton>
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={restoreDraw}
					>
						<Close />
					</IconButton>
					{/* <IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => window.abrirInstructivo()}
					>
						<InfoOutlined />
					</IconButton> */}
				</Box>
			)
		);
	}, [featureCoords, editing]);

	const [key, setKey] = useState(Math.random().toString().slice(2, 11));

	const restoreDraw = () => {
		setKey(Math.random().toString().slice(2, 11));
		setEditing(false);
	};

	const editDraw = () => {
		drawControl._toolbars.edit._modes.edit.handler.enable();
		setEditing(true);
	};

	const saveEdit = () => {
		drawControl._toolbars.edit._modes.edit.handler.save();
		drawControl._toolbars.edit._modes.edit.handler.disable();
		setEditing(false);
	};

	const changeLayer = () => {
		dispatch(mapActions.toggleLayerLeaflet());
	};

	return (
		<Box width="100%" height="100%" position="relative">
			<LeafletMap
				key={key}
				featureCoords={datos.coords}
				setFeatureCoords={setFeatureCoords}
				edit={true}
				restoreDraw={restoreDraw}
				onEdit={() => setEditing(false)}
				setDrawControl={setDrawControl}
			/>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-end"
				gap="10px"
				position="absolute"
				zIndex="9"
				sx={{
					right: "10px",
					bottom: "15px",
					"& .MuiSvgIcon-root": { animation: "iconChange .3s" },
				}}
			>
				<Fab size="small" onClick={changeLayer}>
					<LayersOutlined />
				</Fab>
				<Fab size="medium" onClick={editing ? saveEdit : editDraw}>
					{editing ? <Done /> : <EditOutlined />}
				</Fab>
			</Box>
		</Box>
	);
};

const Fab = styled(MuiFab)(({ theme }) => ({
	boxShadow: "none",
	borderRadius: "12px",
	backgroundColor: theme.palette.primary.light,
	"&.MuiFab-sizeMedium": {
		height: "46px",
		width: "46px",
		"& .MuiSvgIcon-root": {
			height: "25px",
			width: "25px",
		},
	},
	"&.MuiFab-sizeSmall": {
		height: "36px",
		width: "36px",
		"& .MuiSvgIcon-root": {
			height: "20px",
			width: "20px",
		},
	},
}));
