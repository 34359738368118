import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    /* Dialog, */
    Divider,
    DialogTitle,
    TextField,
    Paper,
    DialogContent,
    Button,
    /* DialogContentText, */
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Send } from '@mui/icons-material'
import emailjs from 'emailjs-com';
import { Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux'
/* import StarPicker from 'react-star-picker'; */
//import { actions as polygonsActions } from '../../redux/slices/polygonsSlice'
/* import { actions as sidebarActions } from '../../redux/slices/sidebarSlice' */
import { actions as modalActions } from "../../redux/slices/modalsSlice"
import "../../scss/bottombar.scss"
import "../../scss/betadescription.scss"
import { selectLoggedIn, selectUserData } from '../../redux/slices/userSlice';

const usestyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: "0",
        top: "0",
        color: theme.palette.grey[500],
        //padding: '1rem'
    },
}));

export default function GenericModal() {
    const { t } = useTranslation();
    const classes = usestyles();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectLoggedIn)
    const userData = useSelector(selectUserData);
    const [datos, setDatos] = React.useState({
        name: "",
        mail: "",
        message: "",
    });

    React.useEffect(() => {
        if (isLoggedIn) setDatos({
            name: userData.username,
            mail: userData.email
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value,
        });
    };

    function sendEmail(e) {
        e.preventDefault();
        const data = {
            name: datos.name,
            email: datos.mail,
            //rating: rating,
            message: datos.message,
        }
        emailjs
            .send(
                'service_6xrfft1',
                'template_y0oh2cu',
                data,
                'user_nxVUgnLDmTUknTkYy4Qzg',
            )
            .then(
                (res) => {
                    dispatch(modalActions.toggleGenericModal())
                    swal(
                        t('swal-enviar-comentario-titulo'),
                        t('swal-enviar-comentario-texto'),
                        'success',
                    );
                },
                (err) => {
                    swal(
                        t('swal-error-enviar-comentario-titulo'),
                        t('swal-error-enviar-comentario-textp'),
                        'error',
                    );
                },
            );

        e.target.reset();
    }

    return <>
        <Paper className="beta-container">

            <DialogTitle id="simple-dialog-title">Ayudanos a mejorar</DialogTitle>
            <Divider />
            <DialogContent style={{ height: '85%' }}/* className="beta-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} */>
                <Form onSubmit={sendEmail} style={{ height: '100%' }}>
                    <div className="feedback-content">
                        {/* <div>
                            <span>Que tan contento estas con la aplicacion?</span>
                            <StarPicker onChange={onChange} value={rating} />
                        </div> */}
                        <span style={{marginBottom: '1rem'}}>Hola! Cómo estás?
                            Estamos constantemente mejorando nuestro sistema para brindar el mejor servicio posible. Por eso, te agradeceríamos si pudieras contarnos qué producto complementario, funcionalidad o mejora sentis que haría más fácil tu trabajo con incendios. Todos los comentarios serán tenidos en cuenta y probablemente agregados a la aplicación. Muchas gracias.</span>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                {isLoggedIn ?
                                    null//<TextField label="Nombre" value={datos.name} disabled variant="outlined" style={{ marginTop: '-0.3rem' }} autoComplete='off' />
                                    :
                                    <TextField label={t("campo-nombre")} value={datos.name} name="name" onChange={handleInputChange} variant="outlined" style={{ marginTop: '-0.3rem' }} autoComplete='off' />
                                }
                            </div>
                            <div>
                                {isLoggedIn ?
                                    null//<TextField label="Mail" value={datos.mail} fullwidth disabled variant="outlined" style={{ marginTop: '-0.3rem' }} autoComplete='off' />
                                    :
                                    <TextField label="Mail" value={datos.mail} fullwidth name="mail" onChange={handleInputChange} variant="outlined" style={{ marginTop: '-0.3rem' }} autoComplete='off' />
                                }
                            </div>

                        </div>

                        <div>
                            <TextField required multiline rows={3} name="message" id="standard-basic" label="Dejar comentario" value={datos.message} onChange={handleInputChange} variant="outlined" style={{ width: '100%', marginTop: '1rem' }} autoComplete='off' />
                        </div>
                        {/* <div>
                            <span>Si pudieras cambiar algo o agregar algo a la aplicacion, que seria?</span>
                            <TextField name="message" id="standard-basic" label="Dejar comentario" value={msg2} onChange={handleChangeMsg2} variant="standard" style={{ width: '100%', marginTop: '-0.3rem' }} autoComplete='off' />
                        </div> */}

                        <Button variant="contained" type="submit" style={{ alignSelf: 'center', width: '7rem' }} color="primary" endIcon={<Send />}>
                            Enviar
                        </Button>
                    </div>
                </Form>

            </DialogContent>
            <IconButton
                className={classes.closeButton}
                onClick={() => dispatch(modalActions.toggleGenericModal())}
                size="large">
                <Close />
            </IconButton>


        </Paper>
    </>;
}