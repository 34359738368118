import gql from "graphql-tag";
import { getUserReviews } from "../../graphql/queries";
import { CognitoConfig } from './utils/CognitoConfig';
import { WildfireReview } from "../../redux/slices/types/wildfireTypes";

type UserReviewsResponse = {
	getUserReviews: {
		items: WildfireReview[];
	}
};

const fetchUserReviews = async (): Promise<{ [key: string]: WildfireReview }> => {
	const reviews = await CognitoConfig.query({
		query: gql(getUserReviews)
	}).then((response) => {
		const data = response.data as UserReviewsResponse;
		let newItems: { [key: string]: WildfireReview } = {};
		data.getUserReviews.items.forEach((item: WildfireReview) => {
			newItems[item.wildfire_id] = item
		});
		return newItems;
	});
	return reviews;
}

export default fetchUserReviews;