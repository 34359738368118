import { TFunction } from "i18next";

export function areSameDate(lhs: Date, rhs: Date): boolean {
  return (
		lhs.getFullYear() === rhs.getFullYear() &&
		lhs.getMonth() === rhs.getMonth() &&
		lhs.getDate() === rhs.getDate()
	);
}

export function formatDateDDMMYYYY(date: Date): string {
  return date.toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
}

export function formatDateHHMM(date: Date): string {
  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatLayerExtraText(t: TFunction, layerName: string) {
	const now = new Date();
	now.setMinutes(0);

	if (layerName === "windy-wind") {
		return `${t("vientos-de")} ${formatDateDDMMYYYY(now)} ${t("a-las")} ${formatDateHHMM(now)}`;
	}

	if (layerName === "windy-temp") {
		return `${t("temperatura-de")} ${formatDateDDMMYYYY(now)} ${t("a-las")} ${formatDateHHMM(now)}`;
	}

	if (layerName === "windy-pressure") {
		return `${t("presion-de")} ${formatDateDDMMYYYY(now)} ${t("a-las")} ${formatDateHHMM(now)}`;
	}

	return null;
}