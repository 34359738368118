import { createSlice } from "@reduxjs/toolkit";

const defaultActions = <></>;

const initialState = {
	actions: defaultActions,
	title: "",
	menuOpen: false,
	menuAnchorRef: null,
	menuItems: [],
};

const slice = createSlice({
	name: "appbar",
	initialState,
	reducers: {
		setActions: (state, action) => {
			state.actions = action.payload;
		},
		setDefaultActions: (state) => {
			state.actions = defaultActions;
		},
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setMenuItems: (state, action) => {
			state.menuItems = action.payload;
		},
		setMenuAnchorRef: (state, action) => {
			state.menuAnchorRef = action.payload;
		},
		toggleMenu: (state, action) => {
			state.menuOpen = !state.menuOpen;
		},
		closeMenu: (state, action) => {
			state.menuOpen = false;
		},
	},
});

// Selectors
export const selectActions = (state) => state.appbar.actions;
export const selectTitle = (state) => state.appbar.title;
export const selectMenu = (state) => state.appbar.menuOpen;

// Actions
export const actions = { ...slice.actions };

export default slice.reducer;
