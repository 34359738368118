/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as cameraActions } from "../../../redux/slices/cameraSlice";

import { useLocation } from "react-router-dom";

import {
    Check,
    ExpandMore,
    LocalFireDepartment,
    MapOutlined,
    MoreVert,
    Cameraswitch,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Typography,
    useTheme,
    Chip,
    DialogContentText,
    FormControlLabel,
    Switch,
} from "@mui/material";
import {
    DialogContent, MobileDialog, MobileDialogActions, MobileDialogTitle
} from "../../StyledComponents/Dialog";
//import ReactGA from "react-ga4";
import { List } from "react-virtualized";
//import { getZone } from "../../../../api/wildfires/utils/getZone";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { getDate, getTime, formatConfidence } from "../../mapa/leafletUtils/FormatDateTime";
//import getCenter from "../../../panel/alertas/utils/getCenter";
import { Button } from "../../StyledComponents/Button"
import DatesOverlay from "../Misc/DatesOverlay";
import { NotLoggedIn } from "../Tabs/MisZonas";

import TourResumer from "../../utils/TourResumer";
import Viewer from 'react-viewer';
import './zone.scss';

const items = [
    'ultimas-12hs',
    'ultimas-24hs',
    'ultimas-48hs',
    'ultimas-72hs',
    'ultimas-5dias',
    'ultimas-7dias',
    "ultimas-30dias"
];
const values = [12, 24, 48, 72, 120, 168, 720];

function useQuery() {
    const { search } = useLocation();
    //console.log(search)
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const validationColors = {
    "Y": "#1ECD97",
    "N": "#E64949",
    "D": "#F2D361",
}

const validationTexts = {
    "Y": "✔",
    "N": "X",
    "D": "?",
}

export default function Zona(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let query = useQuery();

    const sub = useSelector((state) => state.user?.userData?.sub);

    const itemsContainerRef = React.useRef();
    const listRef = React.useRef();

    const cameraID = query.get("id");
    //const fireID = query.get("coords");
    //const userType = useSelector((state) => state.user?.userData?.userData?.userType);
    const wildfires = useSelector((state) => state.camera.wildfiresCameras);
    const cameras = useSelector((state) => state.camera.cameras);
    const alertsLoading = useSelector((state) => state.polygons.status.alertsLoading);
    //const onMove = useSelector((state) => state.map.onMove);
    const wildfireClicked = useSelector((state) => state.map.wildfireClicked);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

    const [currentCamera, setCurrentCamera] = useState();

    const [images, setImages] = useState();
    const [visible, setVisible] = useState(false);

    //const [zone, setZone] = React.useState(); //Variable que contiene la zona de cada uno de los focos con reverse geocoding
    //let center;

    //Paginación
    const [horasFocos, setHorasFocos] = React.useState(24);
    const startDate = new Date();
    startDate.setTime(startDate.getTime() - horasFocos * 60 * 60 * 1000);

    const [cameraMovementDialog, setCameraMovementDialog] = React.useState(false);
    const [cameraMovement, setCameraMovement] = React.useState();

    const closeCameraMovementDialog = () => setCameraMovementDialog(false);
    const openCameraMovementDialog = () => setCameraMovementDialog(true);

    const getWildfires = () => {
        const params = {
            cameraID: cameraID,
            backwardHours: horasFocos
        }
        dispatch(cameraActions.getWildfiresCameras(params));
    }

    React.useEffect(() => {
        if (isLoggedIn) dispatch(cameraActions.getCameras());
    }, [isLoggedIn]);

    React.useEffect(() => {
        if (!currentCamera) return;
        dispatch(appbar.setTitle(t(currentCamera.name)));
        setCameraMovement(currentCamera.movementType || "man");
    }, [currentCamera]);


    React.useEffect(() => {
        if (cameraID) {
            getWildfires();
            const currentCam = cameras.filter((item) => (
                item.cameraId === cameraID
            ))
            setCurrentCamera(currentCam[0])
        }
    }, [cameras, cameraID]);

    React.useEffect(() => {
        if (wildfires.length === 0) return;

        loadImages();

    }, [wildfires]);

    // Fin de funciones de traer alertas

    React.useEffect(() => {
        const selectedIndex = values.findIndex((value) => value === horasFocos);
        itemsContainerRef.current?.children[selectedIndex].scrollIntoView({
            inline: "center",
            behavior: "smooth",
        });
    }, [horasFocos]);

    React.useEffect(() => {
        if (!itemsContainerRef.current) return;
        itemsContainerRef.current.childNodes.forEach((item, index) => {
            item.addEventListener("click", () => {
                setHorasFocos(values[index]);
            });
        });
        const selectedIndex = values.findIndex((value) => value === horasFocos);
        itemsContainerRef.current?.children[selectedIndex].scrollIntoView({
            inline: "center",
            behavior: "smooth",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsContainerRef]);


    React.useEffect(() => {
        props.setShowMap(false);
        dispatch(mapActions.setWildfireClicked(null));
    }, []);

    React.useEffect(() => {
        dispatch(
            appbar.setActions(
                <Box display="flex" flexDirection="row" gap="10px">
                    <IconButton onClick={() => props.setShowMap((old) => !old)}>
                        {props.showMap ? <LocalFireDepartment /> : <MapOutlined />}
                    </IconButton>
                    <IconButton
                        id="menuAnchorRef"
                        onClick={() => dispatch(appbar.toggleMenu())}
                    >
                        <MoreVert id="tour-11" />
                    </IconButton>
                </Box>
            )
        );
        dispatch(
            appbar.setMenuItems([
                {
                    title: 'Movimiento cámara',
                    icon: Cameraswitch,
                    action: openCameraMovementDialog,
                },
            ])
        );
    }, [props.showMap, cameraID]);

    React.useEffect(() => {
        if (currentCamera) getWildfires();
    }, [horasFocos, isLoggedIn]);

    const loadImages = () => {
        setImages(wildfires.map((elem) => ({
            src: getImage(elem.date_id, sub, cameraID)
        })))
    }

    function getImage(date, userId, cameraId) {
        let newDate = date.replace(/:/g, "%3A").replace("+", "%2B");
        try {
            const fileAccessURL = `https://camaras.s3.amazonaws.com/Pictures/Boxed/${userId}/${cameraId}/${newDate}.jpg`
            return fileAccessURL;
        } catch (err) {
            console.log(err)
        }
    }

    const onValidationClick = (wildfireID, answer) => {
        const data = {
            cameraId: cameraID,
            date_id: wildfireID,
            confirmWildfire: answer
        }
        dispatch(cameraActions.updateCameraEvent(data));
    }


    async function handleExpanded(newExpanded, index) {
        //const coords = await getZone([x, y]);
        //setZone(coords);
        //setExpanded(newExpanded === expanded ? false : newExpanded);
        if (newExpanded === wildfireClicked) {
            setExpandedItem(-1);
            dispatch(mapActions.setWildfireClicked(null));
            //dispatch(mapActions.setPolygon([alerts ? alerts : [], polygon]));
        } else {
            setExpandedItem(index);
            dispatch(mapActions.setWildfireClicked(newExpanded));
        }
        listRef.current.recomputeRowHeights(index);
        listRef.current.forceUpdateGrid();
    }

    const [expandedItem, setExpandedItem] = React.useState(-1);

    function rowHeight({ index }) {
        if (expandedItem === index) return 310;
        return 77;
    }

    function renderRow(params) {
        const { index, key, style } = params;
        const el = wildfires[index];

        if (!el) return <></>;

        return (
            <Box
                style={style}
                display="flex"
                flexDirection="column"
                sx={{ paddingBottom: "5px" }}
            >
                <Accordion
                    className="foco"
                    sx={{
                        backgroundColor: "#E1E1EC",
                        borderRadius: "10px !important",
                        padding: "0 5px",
                    }}
                    elevation={0}
                    id={el.date_id}
                    expanded={wildfireClicked === el.date_id}
                    onChange={() => handleExpanded(el.date_id, index)}
                    //onClick={() => handleWildfireClick(el)}
                    key={key}
                >
                    <AccordionSummary
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            padding: 0,
                            "& .MuiAccordionSummary-content": {
                                width: "100%",
                                overflow: "hidden",
                            },
                            "& .MuiAccordionSummary-expandIconWrapper": {
                                justifyContent: "center",
                                width: "34px",
                            },
                        }}
                        expandIcon={<ExpandMore />}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            height={wildfireClicked !== el.date_id ? "3em" : "6em"}
                            width="100%"
                            alignItems="center"
                            gap="5px"
                            sx={{ padding: "0 5px" }}
                        >
                            <Chip
                                sx={{
                                    position: "absolute",
                                    top: "3px",
                                    right: "1rem",
                                    width: "40px",
                                    fontSize: "15px",
                                    "& span": {
                                        padding: "0"
                                    },
                                    backgroundColor: validationColors[el.confirmWildfire],
                                }}
                                label={el.confirmWildfire ? validationTexts[el.confirmWildfire] : "-"} size="small"
                            />
                            <img
                                src={getImage(el.date_id, sub, cameraID)}
                                style={wildfireClicked !== el.date_id ?
                                    { width: "60px", height: "60px" } :
                                    { height: "90%", width: "10rem", borderRadius: "10px", margin: "0 auto", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px" }}
                                alt="Imagen"
                                onClick={() => setVisible(true)}
                            />
                            <div>

                            </div>
                            <Box display="flex" flexDirection="column">

                                {wildfireClicked !== el.date_id && (
                                    <Typography
                                        whiteSpace="nowrap"
                                        fontSize=".8rem"
                                        color="rgba(0,0,0,0.7)"
                                    >
                                        {`${getDate(el.date_id)} ${t('a-las')} ${getTime(el.date_id)}`}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{ padding: "0px 16px 8px", fontSize: "15px !important" }}
                    >
                        {wildfireClicked === el.date_id && (
                            <>
                                <Box display="flex" flexDirection="column">
                                    <Typography>
                                        <b>{t('hora-campo-foco')} </b>
                                        {getTime(el.date_id)}
                                    </Typography>
                                    <Typography>
                                        <b>{t('fecha-campo-foco')} </b>
                                        {getDate(el.date_id)}
                                    </Typography>
                                    <Typography>
                                        <b>Probabilidad: </b>
                                        {formatConfidence(el.confidence)}
                                    </Typography>
                                    {el.x &&
                                        <Typography>
                                            <b>Coordenadas: </b>
                                            {`   ${el.x?.toFixed(6)}, ${el.y?.toFixed(6)} `}
                                        </Typography>
                                    }
                                    <Box marginTop="5px">
                                        <Typography style={{ fontSize: "14px", display: 'table', margin: '0 auto' }}>¿Es un incendio?</Typography>
                                        <Box display="flex" justifyContent="center" gap="10px">

                                            <Button onClick={(e) => onValidationClick(el.date_id, "Y")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem", backgroundColor: el.confirmWildfire === "Y" && validationColors.Y }} variant="outlined">Si</Button>
                                            <Button onClick={(e) => onValidationClick(el.date_id, "N")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem", backgroundColor: el.confirmWildfire === "N" && validationColors.N }} variant="outlined">No</Button>
                                            <Button onClick={(e) => onValidationClick(el.date_id, "D")} style={{ padding: "0.75rem", minWidth: "0", width: "4rem", backgroundColor: el.confirmWildfire === "D" && validationColors.D }} variant="outlined">No sé</Button>
                                        </Box>
                                    </Box>
                                    {/* <Typography>
                                    <b>{t('coords-campo-foco')} </b>
                                    
                                </Typography>
                                <Typography>
                                    <b>{t('zona-campo-foco')} </b>
                                </Typography> */}
                                </Box>
                            </>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }

    const NoFires = () => {
        const { t } = useTranslation();
        const selectedIndex = values.findIndex((value) => value === horasFocos);
        const str = itemsContainerRef.current?.children[selectedIndex].textContent.toLowerCase().split(" ");
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
                textAlign="center"
                padding="30px 0"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="100%"
                    gap="20px"
                    sx={{
                        maxWidth: "90%",
                        "& img": {
                            width: "80%",
                            maxHeight: "50%",
                        },
                    }}
                >
                    <img src={noZoneIlustration} alt="ilustración" />
                    <Typography variant="span" color="#1B1B1F" fontSize="1rem">
                        {`${t("no-encontramos-focos")} ${str !== undefined && str[1]} ${str !== undefined && str[2]}`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <>
            {isLoggedIn === false ? <NotLoggedIn /> : (
                <>
                    <TourResumer wakeUpSteps={[8]} />
                    <CameraMovementDialog
                        open={cameraMovementDialog}
                        close={closeCameraMovementDialog}
                        config={cameraMovement}
                        setConfig={setCameraMovement}
                        cameraID={cameraID}
                    />
                    <Box display="flex" flexDirection="column">
                        <Viewer
                            visible={visible}
                            onClose={() => setVisible(false)}
                            images={images}
                            //drag={false}
                            scalable={false}
                            rotatable={false}
                            zoomSpeed={0.4}
                            minScale={0.7}
                            downloadInNewWindow={true}
                        />
                        <Box
                            ref={itemsContainerRef}
                            display="flex"
                            flexDirection="row"
                            gap="10px"
                            id="tour-10"
                            sx={{
                                backgroundColor: "white",
                                padding: "5px 10px 10px 10px",
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflowX: "auto",
                                zIndex: 9,
                                boxSizing: "border-box",
                                "::-webkit-scrollbar": {
                                    display: "none",
                                },
                            }}
                        >
                            {items.map((item, index) => {
                                return (
                                    <Item
                                        key={`tiempo-real-${index}`}
                                        item={item}
                                        checked={horasFocos === values[index]}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                    <DatesOverlay startDate={startDate} endDate={new Date()} screen={0} />

                    {props.showMap ? (
                        <></>
                    ) :
                        (
                            alertsLoading ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height="100%"
                                    textAlign="center"
                                    padding="30px 0"
                                />
                            ) : (
                                wildfires.length > 0 ? (
                                    <FocosList
                                        listRef={listRef}
                                        rowHeight={rowHeight}
                                        renderRow={renderRow}
                                        wildfires={wildfires}
                                    />
                                ) : (
                                    <NoFires />
                                )
                            )
                        )
                    }
                </>
            )}
        </>
    );
}

const FocosList = ({ listRef, rowHeight, renderRow, wildfires }) => {

    return (
        <Box
            sx={{ height: "100%", overflow: "hidden", padding: "0 10px" }}
            display="flex"
            flexDirection="column"
            gap="10px"
            id="tour-8"
        >
            {/* <AutoSizer>
				{({ width, height }) => {
					return ( */}
            <List
                id="virtualizedList"
                ref={listRef}
                width={window.visualViewport.width - 5}
                height={window.visualViewport.height - 170}
                rowHeight={rowHeight}
                rowRenderer={renderRow}
                rowCount={wildfires?.length}
                style={{ height: "100%", width: "100%" }}
            />
            {/* );
				}}
			</AutoSizer> */}
        </Box>
    );
};

const CameraMovementDialog = ({ open, close, config, setConfig, cameraID }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSwitchChange = (e) => {
        if (e.target.value === "man") setConfig("auto")
        if (e.target.value === "auto") setConfig("man")
    };

    const submitChanges = (e) =>{
        const data = {
            cameraId: cameraID,
            movementType: config
        }
        dispatch(cameraActions.updateCamera(data))
        close()
    }

    return (
        <MobileDialog open={open} onClose={close}>
            <Cameraswitch
                color="primary"
                sx={{ alignSelf: "center", height: "30px", width: "30px" }}
            />
            <MobileDialogTitle>Movimiento de la cámara</MobileDialogTitle>
            <DialogContent>
                <DialogContentText>
                    Puedes configurar el movimiento de la cámara de forma automática o manual.
                </DialogContentText>
            </DialogContent>
            <Box alignSelf='center'>

                <FormControlLabel
                    control={
                        <Switch
                            name="movementType"
                            color="primary"
                            checked={config === 'auto'}
                            onChange={handleSwitchChange}
                            value={config}
                        />
                    }
                    label={config === 'man' ? "Manual" : "Automático"}
                    labelPlacement="start"
                    sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            marginRight: "10px",
                        },
                    }}
                />
            </Box>
            <MobileDialogActions>

                <Button onClick={close}>{t('boton-cancelar')}</Button>
                <Button onClick={submitChanges} >
                    {t('boton-guardar-cambios')}
                </Button>
            </MobileDialogActions>
        </MobileDialog>
    );
};

const Item = ({ item, checked }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            display="flex"
            flexDirection="row"
            gap="5px"
            alignItems="center"
            sx={{
                padding: "8px 10px",
                border: checked
                    ? "1px solid " + theme.palette.primary.light
                    : "1px solid #44464E",
                borderRadius: "6px",
                color: checked ? "#00164E" : "#44464E",
                bgcolor: checked ? "primary.light" : undefined,
                boxSizing: "border-box",
                height: "40px",
                fontWeight: "500",
            }}
        >
            {checked ? <Check color="inherit" /> : <></>}
            <span>{t(item)}</span>
        </Box>
    );
};



