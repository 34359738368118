import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip, Chip } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';

export default function DrawerListItem({ item, index, open, setSelectedItem, selected }) {
	const { t } = useTranslation();
	return (
		<ListItem
			id={index === 3 ? "tour-13" : "drawer-item-" + index}
			button={open}
			key={"menu_item_" + index}
			onClick={() => setSelectedItem(index)}
			sx={{
				position: "relative",
				"&": open
					? {
						borderRadius: "0 100px 100px 0",
						width: "90%",
					}
					: {},
				"& .MuiTouchRipple-root": open
					? {
						borderRadius: "0 100px 100px 0",
						width: "100%",
					}
					: {},
				"&::before":
					selected && open
						? {
							content: '""',
							position: "absolute",
							left: "0",
							height: "2em",
							width: "100%",
							borderRadius: "0 100px 100px 0",
							backgroundColor: "#DAE1FF",
							zIndex: "-1",
						}
						: selected
							? {
								content: '""',
								position: "absolute",
								left: "calc(16px - 0.4em)",
								height: "1.8em",
								width: "1.8em",
								borderRadius: "100%",
								backgroundColor: "#DAE1FF",
								zIndex: "-1",
							}
							: {},
			}}
		>
			{index === 7 &&
					<Chip
						sx={{
							position: "absolute",
							top: "0rem",
							right: "0rem",
							width: "1.5rem",
							fontSize: "10px",
							"& span": {
								padding: "0",
								fontSize: "8px"
							},
						}}
						label="BETA" color="primary" size="small" />
			}
			<ListItemIcon>
				{open ? (
					<item.icon />
				) : (
					<Tooltip title={t(item.label)} placement="right">
						<IconButton
							sx={{
								padding: "0",
								"& .MuiTouchRipple-root": {
									height: "1.8em",
									width: "1.8em",
									left: "-0.4em",
									top: "-0.4em",
								},
							}}
						>
							<item.icon />
						</IconButton>
					</Tooltip>
				)}
			</ListItemIcon>
			<ListItemText primary={t(item.label)} sx={{ color: "#44464E" }} />
		</ListItem>
	);
}
