/* eslint-disable react-hooks/exhaustive-deps */
import i18n from '../../../i18nextConf';
import { useTranslation } from 'react-i18next'
import {
	Autocomplete, Box, Checkbox, Divider, FormControlLabel, MenuItem, Select, Slide, Switch, TextField, Typography, IconButton
} from "@mui/material";
import { PlayCircle } from "@mui/icons-material"
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loginIlustration from "../../../assets/loginIlustration.svg";
import MailIcon from "../../../assets/Mail.png";
import mapaPolPrev from "../../../assets/mapa-pol-preview.png";
import mapaSatPrev from "../../../assets/mapa-sat-preview.png";
import PushIcon from "../../../assets/Push.png";
import WppIcon from "../../../assets/WhatsApp.png";
import { actions } from "../../../redux/slices/sidebarSlice";
import { actions as tourActions } from "../../../redux/slices/tourSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import userAccess from "../../../api/user/userAccess.json"

import {
	selectUserData,
	updateUserConfig
} from "../../../redux/slices/userSlice";
import TourResumer from '../../utils/TourResumer';
import { Button, LightButton } from "../../StyledComponents/Button";
import {
	Dialog,
	DialogContent,
	DialogTitle
} from "../../StyledComponents/Dialog";
import { filtrosDeFoco } from "../../utils/Utils";
import EmptyPhoneNumber from "./EmptyPhoneNumber";
import RedeemCode from "./RedeemCode";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Ajustes({ open, close }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();

	let defaultConfig = useSelector(selectUserData).userData;
	let tillDays;

	if (defaultConfig && defaultConfig.userTypeTill) {
		const parts = defaultConfig.userTypeTill.split("-");
		const tillDate = new Date(parts[0], parts[1] - 1, parts[2]);
		tillDays = datediff(new Date(), tillDate);
	}
	
	const loggingIn = useSelector((state) => state.user.status);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const userType = useSelector(
		(state) => state.user?.userData?.userData?.userType
	);

	const [datos, setDatos] = useState({
		country: "",
		defaultLayer: "satellite-v9",
		email: "",
		freeTrial: null,
		name: "",
		notifyByEmail: false,
		notifyByPhone: false,
		notifyByPush: false,
		notifyMinuteGap: 10,
		phone: "0",
		state: null,
		surname: null,
		typeOfAlert: null,
		userType: "",
		userTypeTill: "",
		language: "",
	});
	const [focos, setFocos] = useState([]);
	const [errorMessage, setErrorMessage] = useState(false);


	useEffect(() => {
		if (!open) return;
		dispatch(actions.openCollapse());
		if (loggingIn !== "succeeded" && !isLoggedIn) return;
		setDatos({
			...defaultConfig,
			typeOfAlert: JSON.parse(defaultConfig.typeOfAlert),
			language: i18n.language.includes("es") ? "Español" : "English",
		});
		if (defaultConfig.typeOfAlert === null) {
			setFocos(filtrosDeFoco.map((filtro) => filtro.value));
		} else {
			setFocos(JSON.parse(defaultConfig.typeOfAlert));
		}
	}, [open, loggingIn, isLoggedIn]);

	useEffect(() => {
		if (focos.length === 7) {
			setDatos({
				...datos,
				typeOfAlert: null,
			});
		} else {
			setDatos({
				...datos,
				typeOfAlert: focos,
			});
		}
	}, [focos]);

	const handleChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handlePhoneChange = (e) => {
		setErrorMessage(false);
		setDatos({
			...datos,
			phone: e,
		});
	};

	const handleSwitchChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
		if (e.target.name === "notifyByPhone" && e.target.checked)
			if (datos.phone === "0" || datos.phone === "") {
				openPhoneDialog();
				setDatos({ ...datos, [e.target.name]: false })
			}
	};

	const handleLayerChange = (layer) => {
		setDatos({
			...datos,
			defaultLayer: layer,
		});
	};

	const handleFiltrosChange = (e, value) => {
		if (focos.includes(value)) {
			setFocos((oldFocos) => oldFocos.filter((foco) => foco !== value));
		} else {
			setFocos((oldFocos) => [...oldFocos, value]);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!phoneValidation(datos.phone)) return;
		handleClose();
		if (datos.language) i18n.changeLanguage(datos.language.includes("Es") ? "es" : "en");
		const sendData = {
			...datos,
			typeOfAlert: JSON.stringify(datos.typeOfAlert),
			language: datos.language.includes("Es") ? "es" : "en",
		};
		dispatch(updateUserConfig(sendData));
	};

	const playTour = () => {
		handleClose();
		history.push('/alertas')
		dispatch(polygonActions.startMockModeNoPolygons());
		dispatch(polygonActions.getPolygons());
		dispatch(tourActions.startTour())
	}

	const handleClose = async () => {
		close();
	};

	const phoneValidation = (number) => {
		var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);
		if (pattern.test(number) === false) {
			setErrorMessage(true);
			return false;
		} else if (number.length > 1 && number.length < 8) {
			setErrorMessage(true);
			return false;
		}
		return true;
	}

	const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);

	const closeRedeemDialog = () => {
		setRedeemDialogOpen(false);
	};

	const openRedeemDialog = () => {
		setRedeemDialogOpen(true);
	};

	const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);

	const closePhoneDialog = () => {
		setPhoneDialogOpen(false);
	};

	const openPhoneDialog = () => {
		setPhoneDialogOpen(true);
	};

	return (
		<>
			<TourResumer wakeUpSteps={[14]} />
			<RedeemCode open={redeemDialogOpen} close={closeRedeemDialog} />
			<EmptyPhoneNumber open={phoneDialogOpen} close={closePhoneDialog} />
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={isLoggedIn}
				maxWidth={isLoggedIn ? "md" : undefined}
				scroll="body"
				TransitionComponent={Transition}
			>
				{isLoggedIn ? (
					<>
						<DialogTitle>{t('titulo-configuracion-general')}</DialogTitle>
						<Divider />
						<DialogContent
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "40px",
							}}
						>
							<Box display="flex" flexDirection="column" gap="20px">
								<Typography variant="span" color="#707176" fontSize="1rem">
									{t('datos-personales-typography-configuracion-general')}
								</Typography>
								<Box
									display="flex"
									flexDirection="column"
									gap="10px"
									width="95%"
									alignSelf="center"
								>
									<TextField
										name="name"
										label={t('campo-nombre-usuario')}
										variant="standard"
										value={datos?.name}
										onChange={handleChange}
									/>
									<TextField
										disabled
										name="email"
										label={t("campo-mail")}
										variant="standard"
										value={datos?.email}
										onChange={handleChange}
									/>
								</Box>
							</Box>

							<Box display="flex" flexDirection="column" gap="20px">
								<Typography variant="span" color="#707176" fontSize="1rem">
									{t('vista-predeterminada-typography-configuracion-general')}
								</Typography>
								<Box
									display="flex"
									flexDirection="row"
									gap="10px"
									width="95%"
									alignSelf="center"
								>
									<DefaultMapItem
										selected={datos?.defaultLayer !== "streets-v11"}
										handleLayerChange={() => handleLayerChange("satellite-v9")}
										title={t('layer-satelital')}
										img={mapaSatPrev}
									/>
									<DefaultMapItem
										selected={datos?.defaultLayer === "streets-v11"}
										handleLayerChange={() => handleLayerChange("streets-v11")}
										title={t('layer-politica')}
										img={mapaPolPrev}
									/>
								</Box>
							</Box>

							<Box display="flex" flexDirection="column" gap="20px">
								<Typography variant="span" color="#707176" fontSize="1rem">
									{t('idioma')}
								</Typography>
								<Box sx={{ width: '100%' }}>
									<Autocomplete
										blurOnSelect
										options={["Español", "English"]}
										style={{ width: 300 }}
										value={datos?.language}
										defaultValue={i18n.language.includes("es") ? "Español" : "English"}
										disableClearable
										onChange={(event, newValue) => {
											setDatos({
												...datos,
												language: newValue,
											});
										}}
										renderInput={(params) => (
											<TextField {...params} fullWidth />
										)}
									/>
								</Box>
							</Box>


						</DialogContent>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="flex-end"
							justifyContent="space-between"
						>
							<DialogTitle>{t('titulo-configuracion-alertas')}</DialogTitle>
							{tillDays && tillDays <= 90 && (userAccess.alerts.includes(userType)) ? (
								<Typography color="#BA1B1B">
									{tillDays} {t('dias-restantes')}
								</Typography>
							) : (
								<></>
							)}
						</Box>
						<Divider />
						<DialogContent
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "40px",
								overflowX: "hidden",
							}}
						>
							{userAccess.alerts.includes(userType) ? (
								<>
									<Box display="flex" flexDirection="column" gap="20px">
										<Typography variant="span" color="#707176" fontSize="1rem">
											{t('preferencias-typography-configuracion-alertas')}
										</Typography>
										<Box
											display="grid"
											gridTemplateColumns="50% auto"
											gap="10px"
											justifyItems="start"
											width="95%"
											alignSelf="center"
											id="tour-14"
										>

											<Box display="flex" gap="10px" width="80%">
												<img src={MailIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
												<FormControlLabel
													control={
														<Switch
															name="notifyByEmail"
															color="primary"
															checked={datos?.notifyByEmail}
															onChange={handleSwitchChange}
														/>
													}
													label={t('switch-notificaciones-por-correo')}
													labelPlacement="start"
													sx={{
														width: "100%",
														justifyContent: "space-between",
														margin: 0,
														"& .MuiFormControlLabel-label": {
															marginRight: "10px",
														},
													}}
												/>
											</Box>

											<FormControlLabel
												control={
													<Select
														name="notifyMinuteGap"
														value={
															datos?.notifyMinuteGap
																? datos?.notifyMinuteGap
																: 0
														}
														onChange={handleChange}
													>
														<MenuItem value={0}>{t('espera-ultima-notificacion-no-esperar')}</MenuItem>
														<MenuItem value={10}>{`10 ${t('minutos')}`}</MenuItem>
														<MenuItem value={20}>{`20 ${t('minutos')}`}</MenuItem>
														<MenuItem value={30}>{`30 ${t('minutos')}`}</MenuItem>
														<MenuItem value={60}>{`1 ${t('hora')}`}</MenuItem>
														<MenuItem value={120}>{`2 ${t('horas')}`}</MenuItem>
													</Select>
												}
												label={t('espera-ultima-notificacion')}
												labelPlacement="start"
												sx={{
													margin: 0,
													justifySelf: "start",
													"& .MuiFormControlLabel-label": {
														marginRight: "10px",
													},
												}}
											/>
											{userAccess.alerts.includes(userType) &&
												(
													<>
														<Box display="flex" gap="10px" width="80%">
															<img src={WppIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
															<FormControlLabel
																control={
																	<Switch
																		name="notifyByPhone"
																		color="primary"
																		checked={datos?.notifyByPhone}
																		onChange={handleSwitchChange}
																	/>
																}
																label={t('switch-notificaciones-wpp')}
																labelPlacement="start"
																sx={{
																	width: "100%",
																	justifyContent: "space-between",
																	margin: 0,
																	"& .MuiFormControlLabel-label": {
																		marginRight: "10px",
																	},
																}}
															/>
														</Box>
														<FormControlLabel
															control={
																<Box>
																	<MuiPhoneNumber
																		autoFormat={false}
																		defaultCountry={'ar'}
																		disableAreaCodes="false"
																		onChange={handlePhoneChange}
																		id="tour-15"
																		name="phone"
																		value={datos?.phone === "0" ? "" : datos?.phone}
																		placeholder={t('ingresar-telefono')}
																		regions={'america'}
																	/>
																	{errorMessage && <Typography color="red" fontSize="0.9rem" >{t('numero-invalido')}</Typography>}
																</Box>
															}
															label={
																<>
																	<Typography>{t('numero-de-telefono')}</Typography>
																	<Typography fontSize=".8rem" color="#5c5c5c">
																		(+54 9 11 34084691)
																	</Typography>
																</>
															}
															labelPlacement="start"
															sx={{
																justifySelf: "start",
																//justifyContent: "space-between",
																margin: 0,
																"& .MuiFormControlLabel-label": {
																	marginRight: "10px",
																},
															}}
														/>
													</>
												)}
											<Box display="flex" gap="10px" width="80%">
												<img src={PushIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
												<FormControlLabel
													control={
														<Switch
															name="notifyByPush"
															color="primary"
															checked={datos?.notifyByPush}
															onChange={handleSwitchChange}
														/>
													}
													label={
														<>
															<Typography>{t('switch-notificaciones-push')}</Typography>
															<Typography fontSize=".8rem" color="#5c5c5c">
																{t('subtitulo-switch-notificaciones-push')}
															</Typography>
														</>
													}
													labelPlacement="start"
													sx={{
														width: "100%",
														justifyContent: "space-between",
														justifySelf: "start",
														margin: 0,
														"& .MuiFormControlLabel-label": {
															marginRight: "10px",
														},
													}}
												/>
											</Box>

											{ userAccess.alerts.includes(userType) &&
												<Box>
													<FormControlLabel
														control={
															<IconButton
																variant="contained"
																size="large"
																aria-label="Tutorial"
																//sx={{ width: "50%" }}
																onClick={() => playTour()}
															>
																<PlayCircle sx={{ width: "40px", height: "40px" }} />
															</IconButton>
														}
														label={
															<>
																<Typography>{t('tutorial-config')}</Typography>
															</>
														}
														labelPlacement="start"
														sx={{
															width: "100%",
															justifyContent: "space-between",
															justifySelf: "start",
															margin: 0,
															"& .MuiFormControlLabel-label": {
																marginRight: "10px",
															},
														}}
													/>

												</Box>
											}


										</Box>
									</Box>

									<Box display="flex" flexDirection="column" gap="20px">
										<Typography variant="span" color="#707176" fontSize="1rem">
											{t('focos-a-notificar-typography-configuracion-alertas')}
										</Typography>
										<Box
											display="grid"
											gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
											width="95%"
											alignSelf="center"
										>
											{filtrosDeFoco.map((item, i) => {
												return (
													<FormControlLabel
														key={`foco-${item.label}`}
														control={
															<Checkbox
																style={{
																	color: item.color,
																}}
																edge="start"
																checked={
																	focos === null || focos.includes(item.value)
																}
																disableRipple
																onChange={(e) =>
																	handleFiltrosChange(e, item.value)
																}
															/>
														}
														label={t(item.label)}
														labelPlacement="end"
														sx={{ margin: 0 }}
													/>
												);
											})}
										</Box>
									</Box>
								</>
							) : (
								<>
									<LightButton onClick={openRedeemDialog}>
										{t('ingresar-codigo-suscripcion')}
									</LightButton>
								</>
							)}
						</DialogContent>
						<Box
							display="flex"
							flexDirection="row"
							gap="10px"
							sx={{ marginTop: "20px", float: "right" }}
						>
							<Button variant="text" onClick={handleClose}>
								{t("boton-cancelar")}
							</Button>
							<Button variant="contained" onClick={handleSubmit}>
								{t('boton-guardar-cambios')}
							</Button>
						</Box>
					</>
				) : (
					<NotLoggedIn />
				)}
			</Dialog>
		</>
	);
}

const DefaultMapItem = ({ title, img, selected, handleLayerChange }) => {
	return (
		<Box
			display="flex"
			flexDirection="row"
			gapap="20px"
			alignItems="center"
			sx={{
				padding: "0 0 0 15px",
				backgroundColor: "#E1E1EC",
				width: "fit-content",
				border: selected ? "2px solid #3E5AA9" : "2px solid transparent",
				borderRadius: "10px",
				overflow: "hidden",
				height: "60px",
				cursor: "pointer",
			}}
			onClick={handleLayerChange}
		>
			<Typography variant="span" color="#1B1B1F" fontSize="1.1rem" fontWeight="400" style={{ marginRight: "15px" }}>
				{title}
			</Typography>
			<img src={img} alt="preview mapa satelital" width="60px" />
		</Box>
	);
};

function datediff(first, second) {
	return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
}

const NotLoggedIn = () => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			height="100%"
			textAlign="center"
			padding="30px 0"
			gap="20px"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="100%"
				gap="20px"
				sx={{
					maxWidth: "90%",
					"& img": {
						width: "60%",
						maxHeight: "50%",
					},
				}}
			>
				<img src={loginIlustration} alt="ilustración" />
				<Typography variant="span" color="#1B1B1F" fontSize="1rem">
					{t("no-logueado-configuracion")}
				</Typography>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "fit-content" }}
				onClick={() => history.push("/login")}
			>
				{t("iniciar-sesion")}
			</Button>
		</Box>
	);
};