/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPolygon = /* GraphQL */ `
  query GetPolygon($id: String!) {
    getPolygon(id: $id) {
      id
      name
      description
      coords
      detectRays
      detectWildfires
      lastModificationDate
    }
  }
`;
export const listUserCameras = /* GraphQL */ `
  query ListUserCameras(
    $filter: TableCamerasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        cameraId
        cameraUrl
        cgiPort
        ip
        user
        password
        x
        y
        elevation
        sensorHeight
        sensorWidth
        movementType
        lastDetectionDate
      }
      nextToken
    }
  }
`;
export const listUserCameraEvents = /* GraphQL */ `
  query ListUserCameraEvents(
    $cameraId: String!
    $filter: TableCameraEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCameraEvents(
      cameraId: $cameraId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date_id
        bbox
        classNumber
        confidence
        confirmWildfire
        hasBeenDetectedByHuman
        x
        y
      }
      nextToken
    }
  }
`;
export const listPolygons = /* GraphQL */ `
  query ListPolygons(
    $filter: TablePolygonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolygons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        coords
        detectRays
        detectWildfires
        lastModificationDate
      }
      nextToken
    }
  }
`;
export const getUserWildfire = /* GraphQL */ `
  query GetUserWildfire($polygonId: String!, $date_id: String!) {
    getUserWildfire(polygonId: $polygonId, date_id: $date_id) {
      date_id
      cat
      conf
      sat
      x
      y
    }
  }
`;
export const listUserWildfires = /* GraphQL */ `
  query ListUserWildfires(
    $polygonId: String!
    $filter: TableUserWildfiresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserWildfires(
      polygonId: $polygonId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date_id
        cat
        conf
        sat
        x
        y
      }
      nextToken
    }
  }
`;
export const getUserLightning = /* GraphQL */ `
  query GetUserLightning($polygonId: String!, $date_id: String!) {
    getUserLightning(polygonId: $polygonId, date_id: $date_id) {
      date_id
      cat
      conf
      sat
      x
      y
    }
  }
`;
export const listUserLightnings = /* GraphQL */ `
  query ListUserLightnings(
    $polygonId: String!
    $filter: TableUserWildfiresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLightnings(
      polygonId: $polygonId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date_id
        cat
        conf
        sat
        x
        y
      }
      nextToken
    }
  }
`;
export const listUserWildfiresKML = /* GraphQL */ `
  query ListUserWildfiresKML($polygonId: String!) {
    listUserWildfiresKML(polygonId: $polygonId) {
      kml
    }
  }
`;
export const getNotificationEndpoint = /* GraphQL */ `
  query GetNotificationEndpoint($endpointId: String!) {
    getNotificationEndpoint(endpointId: $endpointId) {
      endpointId
      endpointName
      endpoint
    }
  }
`;
export const listNotificationEndpoints = /* GraphQL */ `
  query ListNotificationEndpoints(
    $filter: TableNotificationEndpointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationEndpoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        endpointId
        endpointName
        endpoint
      }
      nextToken
    }
  }
`;
export const getUserConfig = /* GraphQL */ `
  query GetUserConfig {
    getUserConfig {
      name
      email
      country
      phone
      organizationName
      organizationType
      freeTrial
      language
      notifyByEmail
      notifyByPush
      notifyByPhone
      userType
      userTypeTill
      defaultLayer
      notifyMinuteGap
      typeOfAlert
    }
  }
`;
export const listPublicWildfires = /* GraphQL */ `
  query ListPublicWildfires(
    $filter: PublicWildfiresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicWildfires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        date
        id
        cat
        conf
        sat
        x
        y
      }
      nextToken
    }
  }
`;
export const getPublicWildfireByDate = /* GraphQL */ `
  query GetPublicWildfireByDate(
    $date: String!
    $idGtThan: String
    $filter: PublicWildfiresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicWildfireByDate(
      date: $date
      idGtThan: $idGtThan
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        id
        cat
        conf
        sat
        x
        y
      }
      nextToken
    }
  }
`;
export const getPublicSRWildfireByDate = /* GraphQL */ `
  query GetPublicSRWildfireByDate(
    $date: String!
    $idGtThan: String
    $filter: PublicWildfiresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublicSRWildfireByDate(
      date: $date
      idGtThan: $idGtThan
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        id
        cat
        conf
        sat
        x
        y
      }
      nextToken
    }
  }
`;
export const getWildfireGroupData = /* GraphQL */ `
  query GetWildfireGroupData($date: String!, $id: String!) {
    getWildfireGroupData(date: $date, id: $id) {
      startDate
      lastDate
      lastDateComplete
      clusterId
      x
      y
    }
  }
`;
export const getWildfireReviewByUser = /* GraphQL */ `
  query GetWildfireReviewByUser($user_id: String!, $wildfire_id: String!) {
    getWildfireReviewByUser(user_id: $user_id, wildfire_id: $wildfire_id) {
      user_id
      wildfire_id
      wildfire_date
      result
      origin
      review_date
      user_description
      source
      cathegory
      x
      y
    }
  }
`;
export const getUserReviews = /* GraphQL */ `
  query GetUserReviews(
    $filter: TableWildfiresReviewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user_id
        wildfire_id
        wildfire_date
        result
        origin
        review_date
        user_description
        source
        cathegory
        x
        y
      }
      nextToken
    }
  }
`;
