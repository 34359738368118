import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import store from "./redux/store";
import { Provider } from "react-redux";

// Material UI
import { StyledEngineProvider, createTheme } from "@mui/material/styles";

import { ThemeProvider } from "@mui/material";

// React-Router
import { Router, BrowserRouter } from "react-router-dom";

// History
import history from "./services/history";

// Translations
import "./i18nextConf";

// React Google Analytics
import ReactGA4 from "react-ga4";

// Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// Amplify
import configureCognito from "./api/cognito/configure";
configureCognito();

// Google Analytics
ReactGA4.initialize("G-W5QVSY0EPD");

// Bugsnag
Bugsnag.start({
  apiKey: '5e2d5f24c76e87edf3aa9ca27f78c4cf',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// Configuración de MUI
const theme = createTheme({
	palette: {
		primary: {
			main: "#3E5AA9",
			light: "#DAE1FF",
		},
		hovered: {
			light: "#b5c2f9",
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
		<Router history={history}>
			<BrowserRouter>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<App />
						</ThemeProvider>
					</StyledEngineProvider>
				</Provider>
			</BrowserRouter>
		</Router>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
