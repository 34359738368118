import { Box, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { selectLayer } from "../../../redux/slices/mapSlice";
import { areSameDate, formatDateDDMMYYYY, formatDateHHMM, formatLayerExtraText } from "../../utils/DateUtils";

export default function DatesOverlay({ startDate, endDate, screen }) {
	const { t } = useTranslation();
	const layer = useSelector(selectLayer);
	const extraText = formatLayerExtraText(t, layer);

	return (
		<Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingBottom: "10px" }}>
			{areSameDate(startDate, endDate) ? (
				<Typography textAlign="center" variant="span" fontSize=".9rem">
					{t('focos-del.focos-del')}{" "}
					{formatDateDDMMYYYY(startDate)}{" "}
					{t('focos-del.entre-las')}{" "}
					{formatDateHHMM(startDate)}{" "}
					{t('focos-del.y-las')}{" "}
					{formatDateHHMM(endDate)}
				</Typography>
			) : (
				<Typography textAlign="center" variant="span" fontSize=".9rem">
					{screen === 1 ? t('focos-entre.rayos-entre') : t('focos-entre.focos-entre')}{" "}
					{formatDateDDMMYYYY(startDate)}{" "}
					{t('a-las')}{" "}
					{formatDateHHMM(startDate)}{" "}
					{t('focos-entre.y-el')}{" "}
					{formatDateDDMMYYYY(endDate)}{" "}
					{t('a-las')}{" "}
					{formatDateHHMM(endDate)}
				</Typography>
			)}

			{extraText ? <Typography textAlign="center" variant="span" fontSize=".9rem">{extraText}</Typography> : <></>}
		</Box>
	);
}
