import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Box, Divider, Slide, TextField, Typography } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
	Dialog,
	DialogContent,
	DialogTitle,
} from "../../StyledComponents/Dialog";
import { Button, LoadingButton } from "../../StyledComponents/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmWildfire({ open, close, submit, initialDate }) {
	const { t } = useTranslation();
	const [date, setDate] = useState(initialDate);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		setDate(initialDate);
	}, [initialDate]);

	const onDateChange = (newDate) => {
		setDate(newDate);
	};

	const handleSubmit = () => {
		setSubmitting(true);
		submit(date);
		setSubmitting(false);
	};

	const handleClose = async () => {
		setSubmitting(false);
		close();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="sm"
				TransitionComponent={Transition}
			>
				<DialogTitle>{t('confirmar-foco-modal')}</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "40px",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						gap="20px"
						alignItems="center"
					>
						<Typography
							variant="span"
							color="#707176"
							fontSize="1rem"
							width="95%"
						>
							{t('confirmar-foco-descripcion')}
						</Typography>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDateTimePicker
								label={t('confirmar-foco-label')}
								value={date}
								onChange={onDateChange}
								renderInput={(params) => <TextField {...params} />}
								minDate={new Date("Fri Aug 06 2021 17:06:06 GMT-0300")}
								maxDate={new Date()}
							/>
						</LocalizationProvider>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						gap="10px"
						sx={{ alignSelf: "flex-end" }}
					>
						<Button
							onClick={close}
							variant="text"
							sx={{ width: "fit-content" }}
						>
							{t("boton-cancelar")}
						</Button>
						<LoadingButton
							loading={submitting}
							onClick={handleSubmit}
							variant="contained"
							sx={{ width: "fit-content", alignSelf: "flex-end" }}
						>
							{t("boton-ingresar")}
						</LoadingButton>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
