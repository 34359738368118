import { PlayCircle } from "@mui/icons-material";
import { Box, DialogActions, DialogContentText, Slide } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import AlertsIlustration from "../../assets/AlertsIlustration.png";
import { actions as polygonActions } from "../../redux/slices/polygonsSlice";
import { actions as tourActions } from "../../redux/slices/tourSlice";
import "../../scss/bottombar.scss";
import { Button } from "../StyledComponents/Button";
import {
	Dialog, DialogContent, DialogTitle, MobileDialog, MobileDialogTitle
} from "../StyledComponents/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function TourModal (props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const mobile = useSelector((state) => state.sidebar.mobile);
	const openNewVersionDialog = useSelector((state) => state.tour.tourModal);

	const start = () => {
		handleClose();
		dispatch(polygonActions.startMockModeNoPolygons());
		dispatch(polygonActions.getPolygons());
		dispatch(tourActions.startTour())
	}

	function handleClose() {
		//ReactGA.event({ category: "Nueva Actualización", action: username || "No logueado" });
		/* dispatch(polygonActions.getPolygons());
		dispatch(polygonActions.endMockMode()); */
		dispatch(tourActions.closeModal())
	}

	return (
		<>
			{mobile ? (
				<MobileDialog
					open={openNewVersionDialog}
					onClose={() => handleClose()}
					TransitionComponent={Transition}
				>
					{/* <NewReleases
						color="primary"
						sx={{ alignSelf: "center", height: "30px", width: "30px" }}
					/> */}
					<Box gap="20px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<img src={AlertsIlustration} style={{ height: "60%", width: "60%", alignSelf: "center" }} alt={AlertsIlustration} />
						<MobileDialogTitle>Bienvenido al sistema de alertas</MobileDialogTitle>
						<DialogContent>
							<DialogContentText>
								Puedes mirar el tutorial para ver como crear una zona
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => handleClose(false)}>{t('boton-entendido')}</Button>
							<Button startIcon={<PlayCircle />} variant="contained" sx={{ alignSelf: "center", height: "65px", width: "130px", justifyContent: "center" }} onClick={() => start()}>Ver tutorial</Button>
						</DialogActions>
					</Box>
				</MobileDialog>
			) : (
				<>
					<Dialog
						open={openNewVersionDialog}
						onClose={() => handleClose()}
						TransitionComponent={Transition}
					>
						<Box gap="20px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<img src={AlertsIlustration} style={{ height: "30%", width: "30%", alignSelf: "center" }} alt={AlertsIlustration} />
							<DialogTitle sx={{ alignSelf: "center" }}>{t("alertas-bienvenida")}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{t("alertas-bienvenida-descripcion")}
								</DialogContentText>
							</DialogContent>
								{/* <Button onClick={() => handleClose(false)}>{t('boton-entendido')}</Button> */}
								<Button startIcon={<PlayCircle />} variant="contained" sx={{ alignSelf: "center", height: "65px", width: "130px", justifyContent: "center" }} onClick={() => start()}>{t("alertas-bienvenida-boton")}</Button>
						</Box>
					</Dialog>
				</>
			)}
		</>
	);
}
