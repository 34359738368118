import React from "react";
import {
	Dialog,
	DialogTitle,
	IconButton,
	Slide,
	Typography,
	Divider,
	Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close, Whatshot } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
/* import {actions as sidebarActions, selectDialogInfo} from '../../redux/slices/sidebarSlice' */
import { selectOpenInfoModal } from "../../redux/slices/modalsSlice";
import { actions as modalActions } from "../../redux/slices/modalsSlice";
import "../../scss/bottombar.scss";

const usestyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInfo() {
	const classes = usestyles();
	const open = useSelector(selectOpenInfoModal);
	const dispatch = useDispatch();

	return (
		<>
			<Dialog
				className="dialog-info"
				onClose={() => dispatch(modalActions.toggleInfoModal())}
				TransitionComponent={Transition}
				aria-labelledby="simple-dialog-title"
				open={open}
			>
				<DialogTitle id="simple-dialog-title">
					Información de la detección
				</DialogTitle>
				<Divider />
				<div className="parent">
					<Typography className="text">
						Nuestra detección consta de la combinación de seis satélites, cuatro
						de órbita baja y dos geoestacionarios. Los satélites que utilizamos
						detectan focos de calor, esto es una variación térmica en la tierra,
						la cual puede ser un incendio. Las categorías de cada punto caliente
						indican la probabilidad de que sean incendios reales.
					</Typography>
					<Typography style={{ marginTop: "3rem" }} variant="h5">
						Satélites geoestacionarios
					</Typography>
					<Typography className="text">
						Por el lado de los geoestacionarios, al estar todo el tiempo
						observando América, nos proveen datos de los focos de calor
						detectados en los últimos 10 minutos. Estos se dividen en las
						siguientes categorías:
					</Typography>
					<Paper className="item">
						<Whatshot style={{ color: "#771717" }} />
						<Typography className="bold">Procesado:</Typography>
						<Typography className="definition">
							Foco con mayor fiabilidad.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#B71C1C" }} />
						<Typography className="bold">Saturado:</Typography>
						<Typography className="definition">
							Foco certero, pero los pixeles detectados están saturados.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#90A4AE" }} />
						<Typography className="bold">Contaminado por nubes:</Typography>
						<Typography className="definition">
							Foco con buen grado de certeza pero está parcialmente obstruido
							por nubes.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#F44336" }} />
						<Typography className="bold">Probabilidad alta:</Typography>
						<Typography className="definition">
							Foco probable pero no cumple con los requisitos para ser de tipo
							Procesado.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#FF7A00" }} />
						<Typography className="bold">Probabilidad media:</Typography>
						<Typography className="definition">
							Foco de mediana certeza.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#F6BF26" }} />
						<Typography className="bold">Probabilidad baja:</Typography>
						<Typography className="definition">
							Foco menos certero, puede contener un gran número de falsas
							alarmas.
						</Typography>
					</Paper>

					<Typography style={{ marginTop: "3rem" }} variant="h5">
						Satélites de órbita baja
					</Typography>
					<Typography className="text">
						Por el lado de los satélites de órbita baja, tienen una mayor
						resolución que los geoestacionarios pero pasan aproximadamente cada
						3 o 4 horas por América del Sur. Estos se dividen en las siguientes
						categorías:
					</Typography>
					<Paper className="item">
						<Whatshot style={{ color: "#ff4d00" }} />
						<Typography className="bold">Probabilidad alta:</Typography>
						<Typography className="definition">
							Foco con un alto grado de certeza.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#ff6f00" }} />
						<Typography className="bold">Probabilidad media:</Typography>
						<Typography className="definition">
							Foco de mediana certeza.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#fff200" }} />
						<Typography className="bold">Probabilidad baja:</Typography>
						<Typography className="definition">
							Foco menos certero, puede contener un gran número de falsas
							alarmas.
						</Typography>
					</Paper>
					<Paper className="item">
						<Whatshot style={{ color: "#999999" }} />
						<Typography className="bold"> % de probabilidad</Typography>
						<Typography className="definition">
							Foco con certeza medida en porcentaje, ocurre en los satélites
							Aqua y Terra con elemento MODIS.
						</Typography>
					</Paper>
					<Typography className="text">
						En cuanto a la superficie mínima que tiene que tener un incendio
						para ser detectado por los satélites, varía por la resolución de las
						imágenes tomadas por estos.
					</Typography>
					<Typography className="text">
						En el caso de los satélites geoestacionarios, el incendio tiene que
						tener apróximadamente 0,004 km<sup>2</sup> para ser detectado.
					</Typography>
					<Typography className="text">
						Los satélites de órbita baja poseen mucha mejor resolución, por lo
						que pueden detectar incendios de tan solo 0.000005 km<sup>2</sup>,
						es decir, 5 m<sup>2</sup>.
					</Typography>
				</div>

				<IconButton
					className={classes.closeButton}
					onClick={() => dispatch(modalActions.toggleInfoModal())}
					size="large"
				>
					<Close />
				</IconButton>
			</Dialog>
		</>
	);
}