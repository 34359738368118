/* eslint-disable react-hooks/exhaustive-deps */
import {
	Close,
	DeleteOutlined,
	Done,
	EditOutlined,
	LayersOutlined
} from "@mui/icons-material";
import {
	Box, Checkbox, createSvgIcon, DialogContentText, Fab as MuiFab, FormControlLabel, IconButton,
	styled,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import LeafletMap from "../../mapa/LeafletMap";
import TourResumer from '../../utils/TourResumer';
import { Button } from "../../StyledComponents/Button";
import {
	DialogContent, MobileDialog, MobileDialogActions, MobileDialogTitle
} from "../../StyledComponents/Dialog";

export default function CrearZona({ setShowMap }) {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [datos, setDatos] = React.useState({
		nombreZona: "",
		description: "",
		coords: "",
		detectWildfires: false,
		detectRays: false,
	});

	useEffect(() => {
		setShowMap(false);
		dispatch(appbar.setTitle(t("titulo-crear-zona")));
	}, []);

	const handleInputChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handleTypeOfAlertsChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();
		history.push("/alertas/crear/dibujar");
	};

	const handleDrawSubmit = (newFeatureCoords) => {
		let coordinates;
		if (newFeatureCoords) {
			coordinates = newFeatureCoords.map((item) =>
				item.map((elem) => [elem.lat, elem.lng])
			);
			coordinates = coordinates[0];
		}
		setDatos({
			...datos,
			coords: coordinates,
		});
		history.push("/alertas/crear");
	};

	const handleSubmit = (e) => {
		if (!datos.detectWildfires && !datos.detectRays){
			swal(
				t("swal-error-no-seleccionaste-alertas-titulo"),
				t("swal-error-no-seleccionaste-alertas-texto"),
				"info"
			);
			return;
		}
		if (!Array.isArray(datos.coords))
			swal(
				t('swal-error-no-dibujaste-poligono-titulo'),
				t('swal-error-no-dibujaste-poligono-texto'),
				"error"
			);
		else if (datos.coords.length > 15) {
			swal(
				t("swal-no-crear-poligono-vertices-titulo"),
				t("swal-no-crear-poligono-vertices-texto"),
				"error"
			);
		} else {
			history.push("/alertas");
			const sendData = {
				name: datos.nombreZona,
				description: datos.description,
				coords: JSON.stringify(datos.coords),
				detectWildfires: datos.detectWildfires,
				detectRays: datos.detectRays,
			};
			dispatch(polygonActions.createPolygon(sendData));
		}
	};

	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const handleFinish = (e) => {
		e.preventDefault();
		setConfirmationDialogOpen(true);
	};
	const closeConfirmationDialog = () => {
		setConfirmationDialogOpen(false);
	};

	const MobileCheck = createSvgIcon(
		<path
			fillRule="evenodd"
			d="M0 20V2C0 0.9 0.9 0.01 2 0.01L12 0C13.1 0 14 0.9 14 2V6H12V5H2V17H12V16H14V20C14 21.1 13.1 22 12 22H2C0.9 22 0 21.1 0 20ZM12 20V19H2V20H12ZM2 2H12V3H2V2ZM17.2 8.55L15.8 7.15L10.75 12.2L8.4 9.85L7 11.25L10.75 15L17.2 8.55Z"
		/>,
		"MobileCheck"
	);

	return (
		<>
			<MobileDialog
				maxWidth="xs"
				open={confirmationDialogOpen}
				onClose={closeConfirmationDialog}
			>
				<MobileCheck
					color="primary"
					sx={{ alignSelf: "center", height: "30px", width: "30px" }}
				/>
				<MobileDialogTitle>{t('confirmar-zona-titulo')}</MobileDialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('confirmar-zona-texto')}
					</DialogContentText>
				</DialogContent>
				<MobileDialogActions>
					<Button onClick={closeConfirmationDialog}>{t('boton-cancelar')}</Button>
					<Button onClick={handleSubmit}>{t('boton-crear-zona')}</Button>
				</MobileDialogActions>
			</MobileDialog>
			<Switch>
				<Route exact path="/alertas/crear">
					<FormZona
						handleSubmit={handleFormSubmit}
						handleFinish={handleFinish}
						handleInputChange={handleInputChange}
						datos={datos}
						handleTypeOfAlertsChange={handleTypeOfAlertsChange}
					/>
				</Route>
				<Route exact path="/alertas/crear/dibujar">
					<DibujarZona handleSubmit={handleDrawSubmit} datos={datos} />
				</Route>
			</Switch>
		</>
	);
}

const FormZona = ({ handleSubmit, handleFinish, handleInputChange, datos, handleTypeOfAlertsChange }) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	useEffect(() => {
		dispatch(appbar.setDefaultActions());
	}, []);

	return (
		<>
			<TourResumer wakeUpSteps={[1]}/>
			<Box
				component="form"
				onSubmit={handleFinish}
				sx={{ height: "100%", padding: "10px 20px" }}
				display="flex"
				flexDirection="column"
				gap="20px"
			>
				<Box
					sx={{ height: "100%" }}
					display="flex"
					flexDirection="column"
					gap="20px"
				>
					<Typography
						variant="span"
						color="#707176"
						fontSize="1rem"
						sx={{ marginTop: "20px" }}
					>
						{/* TODO */}
						{t('typography-crear-zona')}
					</Typography>
					<Box
						id="tour-1"
						display="flex"
						flexDirection="column"
						gap="10px"
						sx={{ padding: "0 10px" }}
					>
						<TextField
							name="nombreZona"
							label={t("nombre-de-la-zona")}
							variant="standard"
							required
							defaultValue={datos.nombreZona}
							onChange={handleInputChange}
						/>
						<TextField
							name="description"
							label={t("descripcion-de-la-zona")}
							variant="standard"
							defaultValue={datos.description}
							onChange={handleInputChange}
						/>
					</Box>
					<Box display="flex" flexDirection="column" gap="5px" marginTop="20px" id="tour-2">
								<Typography variant="span" color="#707176" fontSize="1rem">
								{t('typography-focos-rayos')}
								</Typography>
								<Box
									display="flex"
									justifyContent="space-between"
									//gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
									width="70%"
									alignSelf="center"
								>
									<FormControlLabel
										name="detectWildfires"
										control={
											<Checkbox
												style={{
													color: "#B71C1C",
												}}
												edge="start"
												checked={datos.detectWildfires}
												disableRipple
												onChange={(e) =>
													handleTypeOfAlertsChange(e)
												}
											/>
										}
										label={t('focos-de-calor')}
										labelPlacement="end"
										sx={{ margin: 0 }}
									/>
									<FormControlLabel
										name="detectRays"
										control={
											<Checkbox
												style={{
													color: "#4264FB",
												}}
												edge="start"
												checked={datos.detectRays}
												disableRipple
												onChange={(e) =>
													handleTypeOfAlertsChange(e)
												}
											/>
										}
										label={t('focos-de-rayos')}
										labelPlacement="end"
										sx={{ margin: 0 }}
									/>

								</Box>
							</Box>
					<Box position="relative" sx={{ color: "white" }} id="tour-3">
						<Box
							width="100%"
							position="relative"
							sx={{
								aspectRatio: "16 / 9",
								borderRadius: "10px",
								overflow: "hidden",
								marginTop: "20px",
								filter: "brightness(30%)",
								"& *": { pointerEvents: "none" },
								"& .leaflet-control-container": { display: "none" },
							}}
						>
							<LeafletMap
								featureCoords={datos.coords}
								edit={false}
								mapProps={{
									doubleClickZoom: false,
									closePopupOnClick: false,
									dragging: false,
									zoomSnap: false,
									zoomDelta: false,
									trackResize: false,
									touchZoom: false,
									scrollWheelZoom: false,
								}}
							/>
						</Box>
						<Button
							id="tour-4"
							variant="outlined"
							color="inherit"
							startIcon={<EditOutlined />}
							onClick={handleSubmit}
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 10,
							}}
						>
							{t("boton-dibujar-zona")}
						</Button>
					</Box>
					<Button id="tour-5" variant="contained" type="submit">
				{t("boton-crear-zona")}
				</Button>
				</Box>
				
			</Box>
		</>
	);
};

const DibujarZona = ({ handleSubmit, datos }) => {
	const [drawing, setDrawing] = useState(false);
	const [editing, setEditing] = useState(false);
	const [featureCoords, setFeatureCoords] = useState();
	const [drawControl, setDrawControl] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!featureCoords) deleteDraw();
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px">
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => handleSubmit(featureCoords)}
					>
						<Done />
					</IconButton>
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={deleteDraw}
					>
						<Close />
					</IconButton>
					{/* <IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => window.abrirInstructivo()}
					>
						<InfoOutlined />
					</IconButton> */}
				</Box>
			)
		);
	}, [featureCoords]);

	const draw = () => {
		drawControl._toolbars.draw._modes.polygon?.handler.enable();
		setDrawing(true);
	};

	const finishDraw = () => {
		drawControl._toolbars.draw._modes.polygon.handler._finishShape();
		if (!drawControl._toolbars.draw._modes.polygon.handler.enabled())
			setDrawing(false);
	};

	const deleteDraw = () => {
		if (!drawControl) return;
		if (drawControl._toolbars.draw._modes.polygon.handler.enabled())
			drawControl._toolbars.draw._modes.polygon.handler.disable();
		try {
			drawControl._toolbars.edit._modes.remove.handler.removeAllLayers();
		} catch (e) {}
		setDrawing(false);
		setEditing(false);
		setFeatureCoords();
	};

	const editDraw = () => {
		if (
			drawControl._toolbars.draw._modes.polygon?.handler.enabled() ||
			(!featureCoords && !datos.coords)
		)
			return;
		drawControl._toolbars.edit._modes.edit.handler.enable();
		setEditing(true);
	};

	const saveEdit = () => {
		drawControl._toolbars.edit._modes.edit.handler.save();
		drawControl._toolbars.edit._modes.edit.handler.disable();
		setEditing(false);
	};

	const changeLayer = () => {
		dispatch(mapActions.toggleLayerLeaflet());
	};

	const handleOnEdit = (coords) => {
		setEditing(false);
	};

	return (
		<Box width="100%" height="100%" position="relative">
			<LeafletMap
				featureCoords={datos.coords}
				setFeatureCoords={setFeatureCoords}
				edit={false}
				onCreate={() => setDrawing(false)}
				onEdit={handleOnEdit}
				deleteDraw={deleteDraw}
				setDrawControl={setDrawControl}
			/>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-end"
				gap="10px"
				position="absolute"
				zIndex="9"
				sx={{
					right: "10px",
					bottom: "15px",
					"& .MuiSvgIcon-root": { animation: "iconChange .3s" },
				}}
			>
				<Fab size="small" onClick={changeLayer}>
					<LayersOutlined />
				</Fab>
				<Fab size="small" onClick={deleteDraw}>
					<DeleteOutlined />
				</Fab>
				{/* <Fab size="small" onClick={editing ? saveEdit : editDraw}>
					{editing ? <Done /> : <Eraser />}
				</Fab>
				<Fab size="medium" onClick={drawing ? finishDraw : draw}>
					{drawing ? <Done /> : <EditOutlined />}
				</Fab> */}
				<Fab
					size="medium"
					onClick={
						featureCoords
							? editing
								? saveEdit
								: editDraw
							: drawing
							? finishDraw
							: draw
					}
				>
					{featureCoords ? (
						editing ? (
							<Done />
						) : (
							<EditOutlined />
						)
					) : drawing ? (
						<Done />
					) : (
						<Polygon />
					)}
				</Fab>
			</Box>
		</Box>
	);
};

const Polygon = createSvgIcon(
	<path d="M17,15.7V13H19V17L10,21L3,14L7,5H11V7H8.3L5.4,13.6L10.4,18.6L17,15.7M22,5V7H19V10H17V7H14V5H17V2H19V5H22Z" />,
	"Polygon"
);

const Fab = styled(MuiFab)(({ theme }) => ({
	boxShadow: "none",
	borderRadius: "12px",
	backgroundColor: theme.palette.primary.light,
	"&.MuiFab-sizeMedium": {
		height: "46px",
		width: "46px",
		"& .MuiSvgIcon-root": {
			height: "25px",
			width: "25px",
		},
	},
	"&.MuiFab-sizeSmall": {
		height: "36px",
		width: "36px",
		"& .MuiSvgIcon-root": {
			height: "20px",
			width: "20px",
		},
	},
}));
