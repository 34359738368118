import {Amplify} from 'aws-amplify'
import config from './cognito.json'

 function configure (){
    Amplify.configure({
        Auth: {
            //mandatorySignIn: true,
            mandatorySignIn: false,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        },
        Storage: {
            AWSS3: {
                bucket: config.cognito.BUCKET_CAMERAS,
                region: config.cognito.REGION,
            }
        }
    })
 } 

export default configure;