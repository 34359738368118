import React from "react";
import { useTranslation } from 'react-i18next'
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { ChevronLeft } from "@mui/icons-material";
import logo from "../../../assets/logo.svg";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actions/* , selectLoggedIn */ } from "../../../redux/slices/userSlice";
import { actions as sidebarActions } from "../../../redux/slices/sidebarSlice.js";

export default function DrawerHeader({ open, toggleDrawer }) {
	const history = useHistory();
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const loggedIn = useSelector((state) => state.user?.isLoggedIn);
	const userData = useSelector((state) => state.user.userData);

	const closeDrawer = () => {
		dispatch(sidebarActions.closeDrawer());
	};
	const handleSignOut = () => {
		dispatch(actions.signOut());
	};

	return (
		<StyledDrawerHeader>
			<Box display={open ? "none" : "initial"}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={toggleDrawer}
				>
					<MenuIcon />
				</IconButton>
			</Box>
			<Box
				visibility={open ? "visible" : "hidden"}
				display="flex"
				flexDirection="column"
				gap="15px"
				width="100%"
				sx={{ padding: "0 10px 0 20px" }}
			>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box
						display="flex"
						flexDirection="row"
						gap="10px"
						alignItems="center"
					>
						<img src={logo} alt="logo" />
						<Typography variant="h1" fontSize="1.5rem" fontWeight="600">
							Satellites on Fire
						</Typography>
					</Box>
					<IconButton onClick={closeDrawer}>
						<ChevronLeft />
					</IconButton>
				</Box>
				{loggedIn ? (
					<>
						<Box display="flex" flexDirection="row" gap="10px">
							<Avatar
								aria-controls="customized-menu"
								aria-haspopup="true"
								className="avatar-login"
							>
								{userData?.userData.name[0]?.toUpperCase()}
							</Avatar>
							<Box display="flex" flexDirection="column" gap="5px">
								<Typography variant="h1" fontSize="1.1rem" fontWeight="500">
									{userData?.userData.name}
								</Typography>
								<Typography variant="h1" fontSize="1rem" fontWeight="400">
									{userData?.userData.email}
								</Typography>
							</Box>
						</Box>
						<Button variant="outlined" onClick={handleSignOut}>
						{t('cerrar-sesion')}
						</Button>
					</>
				) : (
					<Button variant="contained" onClick={() => history.push("/login")}>
						{t("iniciar-sesion")}
					</Button>
				)}
				<Divider sx={{ marginTop: "10px" }} />
			</Box>
		</StyledDrawerHeader>
	);
}

const StyledDrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(1),
	...theme.mixins.toolbar,
}));

const Button = styled(MuiButton)(({ theme }) => ({
	width: "fit-content",
	borderRadius: "20px",
	padding: theme.spacing(1, 2),
	fontSize: ".8rem",
	fontWeight: "500",
	fontVariantCaps: "normal",
	textTransform: "none",
}));
