import React from 'react'
import VerifyPasswordComponent from "../../components/user/VerifyPasswordComponent"

export default function VerifyPassword() {
    return (
        <div>
            <VerifyPasswordComponent />
            
        </div>
    )
}
