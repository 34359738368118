import { useTranslation } from 'react-i18next'
import { Box, Typography } from "@mui/material";
import React from "react";
import { Button } from "../../StyledComponents/Button";
import PanelHeader from "../Panel/PanelHeader";
import loginIlustration from "../../../assets/loginIlustration.svg";
import { useHistory } from "react-router-dom";

export default function Configuracion() {
	const history = useHistory();
	const { t } = useTranslation()
	return (
		<>
			<Box sx={{ height: "100%" }} display="flex" flexDirection="column">
				<PanelHeader title={t("configuracion-titulo-menu")}/>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					gap="10px"
					overflow="hidden"
					sx={{ height: "100%" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						height="100%"
						textAlign="center"
						gap="20px"
						sx={{
							"& img": {
								width: "90%",
								maxHeight: "40%",
							},
							"& span.MuiTypography-root": {
								width: "95%",
							},
						}}
					>
						<img src={loginIlustration} alt="ilustración" />
						<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t("no-logueado-configuracion")}
						</Typography>
						<Button
							variant="contained"
							sx={{ width: "fit-content" }}
							onClick={() => history.push("/login")}
						>
							{t("iniciar-sesion")}
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
}
