import React from 'react'
import Backdrop from '../utils/BackdropComponents';
//Redux
import { useDispatch, useSelector} from "react-redux";
import {
	actions as userActions,
	selectStatus
} from "../../redux/slices/userSlice";
import {useHistory} from 'react-router-dom'

export default function EmailVerification(props) {

    
    const dispatch = useDispatch();
    const history = useHistory();
    const status = useSelector(selectStatus);
    const email = props.match.params.email;
    const code = props.match.params.code;

    React.useEffect(()=>{
        dispatch(userActions.confirmEmail({username: email,code}))
        //eslint-disable-next-line
    },[])


    
    React.useEffect(()=>{
        if(status === 'succeded') {
			dispatch(userActions.clearStatus());
			history.push('/login');		
		}
        //eslint-disable-next-line
    },[status])


    return (
        <div>
            <Backdrop open={status==='loading'}/>
        </div>
    )
}
