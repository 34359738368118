import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TourState, JoyrideBasicStep } from './types/tourTypes';

const defaultSteps: JoyrideBasicStep[] = [];

const initialState: TourState = {
	run: false,
	stepIndex: 0,
	steps: defaultSteps,
	tourActive: false,
	tourModal: false,
};

const slice = createSlice({
	name: 'tour',
	initialState,
	reducers: {
		loadTour: (state, action: PayloadAction<JoyrideBasicStep[]>) => {
			state.steps = action.payload;
		},
		startTour: /* START */ (state) => {
			state.run = false;
			state.tourActive = true;
			state.tourModal = false;
		},
		restartTour: /* RESET */ (state) => {
			state.stepIndex = 0;
		},
		pauseTour: /* STOP */ (state) => {
			state.run = false;
		},
		resumeTour: /* RESUME */ (state) => {
			state.run = true;
		},
		changeStep: /* NEXT_OR_PREV */ (state, action: PayloadAction<number>) => {
			state.stepIndex = action.payload;
		},
		endTour: /* RESTART */(state) => {
			state.run = false;
			state.tourActive = false;
			state.stepIndex = 0;
		},
		openModal: /* OPENMODAL */(state) => {
			if(!state.tourActive) state.tourModal = true;
		},
		closeModal: /* CloseMODAL */(state) => {
			state.tourModal = false;
		}
	}
})

export const actions = { ...slice.actions };

export default slice.reducer;