/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Cameraswitch, ExpandMore
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box, Chip, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, MenuItem,
	Select, Switch, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//images
//import ImageViewer from 'react-simple-image-viewer';
import Viewer from 'react-viewer';
//import ReactGA from "react-ga4";
import { List } from "react-virtualized";
//import { getZone } from "../../../../api/wildfires/utils/getZone";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import { actions as appbarActions } from "../../../redux/slices/appbarSlice";
import { actions as cameraActions } from "../../../redux/slices/cameraSlice";
import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { formatConfidence, getDate, getTime } from "../../mapa/leafletUtils/FormatDateTime";
import { Button } from "../../StyledComponents/Button";
//import getCenter from "../../../panel/alertas/utils/getCenter";
import TourResumer from "../../utils/TourResumer";
import DatesOverlay from "../Misc/DatesOverlay";
import PanelHeader from "../Panel/PanelHeader";
import { NotLoggedIn } from "../Tabs/MisZonas";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const validationColors = {
	"Y": "#1ECD97",
	"N": "#E64949",
	"D": "#F2D361",
}

const validationTexts = {
	"Y": "✔",
	"N": "X",
	"D": "?",
}

export default function ZonaCamaras(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let query = useQuery();

	const sub = useSelector((state) => state.user?.userData?.sub);

	const cameraID = query.get("id");
	//const fireID = query.get("coords");

	//const userType = useSelector((state) => state.user?.userData?.userData?.userType);
	const wildfires = useSelector((state) => state.camera.wildfiresCameras);
	const cameras = useSelector((state) => state.camera.cameras);
	const alertsLoading = useSelector((state) => state.polygons.status.alertsLoading);
	//const onMove = useSelector((state) => state.map.onMove);
	const wildfireClicked = useSelector((state) => state.map.wildfireClicked);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const [currentCamera, setCurrentCamera] = useState();

	const [images, setImages] = useState();
	const [visible, setVisible] = useState(false);
	const [index, setIndex] = useState(0);


	//const [zone, setZone] = React.useState(); //Variable que contiene la zona de cada uno de los focos con reverse geocoding
	//let center;

	//Paginación
	const [horasFocos, setHorasFocos] = React.useState(24);
	const startDate = new Date();
	startDate.setTime(startDate.getTime() - horasFocos * 60 * 60 * 1000);

	const [cameraMovementDialog, setCameraMovementDialog] = React.useState(false);
	const [cameraMovement, setCameraMovement] = React.useState();

	const closeCameraMovementDialog = () => setCameraMovementDialog(false);
	const openCameraMovementDialog = () => setCameraMovementDialog(true);

	const textInput = React.useRef();


	const getWildfires = () => {
		const params = {
			cameraID: cameraID,
			backwardHours: horasFocos
		}
		dispatch(cameraActions.getWildfiresCameras(params));
	}

	React.useEffect(() => {
		if (isLoggedIn) dispatch(cameraActions.getCameras());
	}, [isLoggedIn]);


	React.useEffect(() => {
		if (cameraID) {
			getWildfires();
			const currentCam = cameras.filter((item) => (
				item.cameraId === cameraID
			))
			setCurrentCamera(currentCam[0])
		}

	}, [cameras, cameraID]);

	React.useEffect(() => {
		if (!currentCamera) return;
		setCameraMovement(currentCamera.movementType || "man");
	}, [currentCamera]);


	React.useEffect(() => {
		if (wildfires.length === 0) return;

		loadImages();

	}, [wildfires]);

	React.useEffect(() => {
		dispatch(mapActions.setWildfireClicked(null));


		dispatch(
			appbarActions.setMenuItems([
				{
					title: 'Movimiento cámara',
					icon: Cameraswitch,
					action: openCameraMovementDialog,
				},
			])
		);

	}, []);

	React.useEffect(() => {
		if (currentCamera) getWildfires();
	}, [horasFocos, isLoggedIn, /* Agregado esto */]);

	async function handleExpanded(newExpanded, index) {

		//const coords = await getZone([x, y]);
		//setZone(coords);
		//setExpanded(newExpanded === expanded ? false : newExpanded);
		if (newExpanded === wildfireClicked) {
			setExpandedItem(-1);
			dispatch(mapActions.setWildfireClicked(null));

			//dispatch(mapActions.setPolygon([alerts ? alerts : [], polygon]));
		} else {
			setExpandedItem(index);
			dispatch(mapActions.setWildfireClicked(newExpanded));
		}
		listRef.current.recomputeRowHeights(index);
		listRef.current.forceUpdateGrid();
	}

	const loadImages = () => {
		setImages(wildfires.map((elem) => ({
			src: getImage(elem.date_id, sub, cameraID)
		})))
	}

	function getImage(date, userId, cameraId) {
		let newDate = date.replace(/:/g, "%3A").replace("+", "%2B");
		try {
			const fileAccessURL = `https://camaras.s3.amazonaws.com/Pictures/Boxed/${userId}/${cameraId}/${newDate}.jpg`
			return fileAccessURL;
		} catch (err) {
			console.log(err)
		}
	}

	const onValidationClick = (wildfireID, answer) => {
		const data = {
			cameraId: cameraID,
			date_id: wildfireID,
			confirmWildfire: answer
		}
		dispatch(cameraActions.updateCameraEvent(data));
	}

	/* React.useEffect(() => {
		if (wildfireClicked !== null && wildfireClicked !== undefined) {
			const clickedIndex = wildfires.findIndex((alert) => alert.date_id === wildfireClicked);
			setExpandedItem(clickedIndex);
			if (wildfireClicked) {
				listRef.current?.scrollToRow(clickedIndex + 1);
				setTimeout(() => {
					const divIncendio = document.getElementById(wildfireClicked);
					if (divIncendio) divIncendio.scrollIntoView({ block: "center", behavior: "smooth" });
				}, 100);
			}
			listRef.current.recomputeRowHeights();
			listRef.current.forceUpdateGrid();
		}
	}, [wildfireClicked]); */

	const listRef = React.useRef();

	function renderRow(props) {
		const { index, key, style } = props;
		const el = wildfires[index];


		if (!el) return <></>;

		return (
			<>
				<TourResumer wakeUpSteps={[8]} />
				<Box
					style={style}
					display="flex"
					flexDirection="column"
					sx={{ paddingBottom: "5px" }}
				>
					<Accordion
						className="foco"
						sx={{
							backgroundColor: "#E1E1EC",
							borderRadius: "10px !important",
							padding: "0 5px",
						}}
						elevation={0}
						id={el.date_id}
						expanded={wildfireClicked === el.date_id}
						onChange={() => handleExpanded(el.date_id, index)}
						//onClick={() => handleWildfireClick(el)}
						key={key}
					>
						<AccordionSummary
							sx={{
								width: "100%",
								overflow: "hidden",
								padding: 0,
								"& .MuiAccordionSummary-content": {
									width: "100%",
									overflow: "hidden",
								},
								"& .MuiAccordionSummary-expandIconWrapper": {
									justifyContent: "center",
									width: "34px",
								},
							}}
							expandIcon={<ExpandMore />}
						>
							<Box
								display="flex"
								flexDirection="row"
								height="3em"
								alignItems="center"
								gap="5px"
							//sx={wildfireClicked === el.date_id && { alignSelf: "center", margin: "0 auto", height: "6rem" }}
							>
								<img
									src={getImage(el.date_id, sub, cameraID)}
									style={wildfireClicked !== el.date_id ?
										{ width: "60px", height: "60px" } :
										{ height: "90%", width: "90%", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px" }}
									alt="Imagen"
									onClick={() => { setVisible(true); setIndex(index) }}
								/>
								{wildfireClicked !== el.date_id && (
									<Chip
										sx={{
											position: "absolute",
											top: "3px",
											right: "0rem",
											width: "40px",
											fontSize: "15px",
											"& span": {
												padding: "0"
											},
											backgroundColor: validationColors[el.confirmWildfire],
										}}
										label={el.confirmWildfire ? validationTexts[el.confirmWildfire] : "-"} /* deleteIcon={validationTexts[el.confirmWildfire]} */ size="small"
									/>
								)}

								<Box display="flex" flexDirection="column">
									{wildfireClicked !== el.date_id && (
										<>
											<Typography
												whiteSpace="nowrap"
												fontSize=".8rem"
												color="rgba(0,0,0,0.7)"
											>
												{getDate(el.date_id)}
												{" a las "}
												{getTime(el.date_id)}
											</Typography>
										</>
									)}
								</Box>
							</Box>
						</AccordionSummary>
						<AccordionDetails
							sx={{ padding: "0px 16px 8px", fontSize: "15px !important" }}
						>
							{wildfireClicked === el.date_id && (
								<>
									<Box display="flex" flexDirection="column">
										<Typography>
											<b>{t('hora-campo-foco')} </b>
											{getTime(el.date_id)}
										</Typography>
										<Typography>
											<b>{t('fecha-campo-foco')} </b>
											{getDate(el.date_id)}
										</Typography>
										<Typography>
											<b>Probabilidad: </b>
											{formatConfidence(el.confidence)}
										</Typography>
										{el.x &&
											<Typography>
												<b>Coordenadas: </b>
												{`   ${el.x?.toFixed(6)}, ${el.y?.toFixed(6)} `}
											</Typography>
										}

										<Box marginTop="5px">
											<Typography style={{ fontSize: "14px", display: 'table', margin: '0 auto' }}>¿Es un incendio?</Typography>
											<Box display="flex" justifyContent="center" gap="10px">

												<Button onClick={(e) => onValidationClick(el.date_id, "Y")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem", backgroundColor: el.confirmWildfire === "Y" && validationColors.Y }} variant="outlined">Si</Button>
												<Button onClick={(e) => onValidationClick(el.date_id, "N")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem", backgroundColor: el.confirmWildfire === "N" && validationColors.N }} variant="outlined">No</Button>
												<Button onClick={(e) => onValidationClick(el.date_id, "D")} style={{ padding: "0.75rem", minWidth: "0", width: "4rem", backgroundColor: el.confirmWildfire === "D" && validationColors.D }} variant="outlined">No sé</Button>
											</Box>
										</Box>
										{/* <Typography>
											<b>{t('coords-campo-foco')} </b>
											
										</Typography> */}
										{/* <Typography>
											<b>{t('zona-campo-foco')} </b>
										</Typography> */}
									</Box>
								</>
							)}
						</AccordionDetails>
					</Accordion>
				</Box>
			</>


		);
	}

	const [expandedItem, setExpandedItem] = React.useState(-1);

	function rowHeight({ index }) {
		if (expandedItem === index) return 290;
		return 77;
	}

	const NoFires = () => {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
				textAlign="center"
				padding="30px 0"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="100%"
					gap="20px"
					sx={{
						maxWidth: "90%",
						"& img": {
							width: "80%",
							maxHeight: "50%",
						},
					}}
				>
					<img src={noZoneIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{`${t("no-encontramos-focos")} ${textInput.current?.firstChild.textContent}`}
					</Typography>
				</Box>
			</Box>
		);
	};

	return (
		<>
			{isLoggedIn === false ? (
				<NotLoggedIn />
			) : (
				<>
					<DatesOverlay startDate={startDate} endDate={new Date()} />
					<CameraMovementDialog
						open={cameraMovementDialog}
						close={closeCameraMovementDialog}
						config={cameraMovement}
						setConfig={setCameraMovement}
						cameraID={cameraID}
					/>
					<Box sx={{ height: "100%" }} display="flex" flexDirection="column" gap="10px">
						<PanelHeader
							title={currentCamera?.name}
							action={
								<Box display="flex" flexDirection="row">
									<IconButton onClick={openCameraMovementDialog}>
										<Cameraswitch />
									</IconButton>
								</Box>
							}
						/>
						<FormControlLabel
							control={
								<Select defaultValue={24} value={horasFocos} onChange={(e) => setHorasFocos(e.target.value)} ref={textInput}>
									<MenuItem value={12}>{`12 ${t('horas')}`}</MenuItem>
									<MenuItem value={24}>{`24 ${t('horas')}`}</MenuItem>
									<MenuItem value={48}>{`48 ${t('horas')}`}</MenuItem>
									<MenuItem value={72}>{`72 ${t('horas')}`}</MenuItem>
									<MenuItem value={120}>{`5 ${t('dias')}`}</MenuItem>
									<MenuItem value={168}>{`7 ${t('dias')}`}</MenuItem>
									<MenuItem value={720}>{`30 ${t('dias')}`}</MenuItem>
								</Select>
							}
							//label={t('rango-de-focos-zona')}
							label={"Imágenes de las últimas"}
							labelPlacement="start"
							sx={{
								margin: 0,
								justifyContent: "space-between",
								gap: "10px",
							}}
						/>

						< Viewer
							visible={visible}
							onClose={() => setVisible(false)}
							images={images}
							//drag={false}
							scalable={false}
							rotatable={false}
							zoomSpeed={0.4}
							minScale={0.7}
							downloadInNewWindow={true}
							activeIndex={index}

						/>
						{/* Otra libreria de imagenes
					{isViewerOpen && (

					
						  {<ImageViewer
							src={images}
							currentIndex={currentImage}
							disableScroll={false}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
							
						/>}
						)} */}
						{
							alertsLoading ? (
								<Box
									display="flex"
									flexDirection="column"
									alignItems="center"
									justifyContent="space-between"
									height="100%"
									textAlign="center"
									padding="30px 0"
								/>
							) : (
								wildfires.length > 0 ? (
									<List
										id="tour-8"
										ref={listRef}
										width={300}
										height={360}
										rowHeight={rowHeight}
										rowRenderer={renderRow}
										rowCount={wildfires?.length}
										style={{ height: '100%', width: 'calc(100% + 15px)' }}
									/>
								) : (
									<NoFires />
								)
							)
						}
					</Box>
				</>
			)}
		</>
	);
}

const CameraMovementDialog = ({ open, close, config, setConfig, cameraID }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleSwitchChange = (e) => {
		if (e.target.value === "man") setConfig("auto")
		if (e.target.value === "auto") setConfig("man")
	};

	const submitChanges = (e) => {
		const data = {
			cameraId: cameraID,
			movementType: config
		}
		dispatch(cameraActions.updateCamera(data))
		close()
	}

	return (

		<Dialog open={open} onClose={close}>
			<DialogTitle /* sx={{ padding: "20px 0 10px 0px" }} */>Movimiento de la cámara</DialogTitle>
			<DialogContent>
				<DialogContentText>Puedes configurar el movimiento de la cámara de forma automática o manual.</DialogContentText>
			</DialogContent>
			<Box alignSelf='center'>

				<FormControlLabel
					control={
						<Switch
							name="movementType"
							color="primary"
							checked={config === 'auto'}
							onChange={handleSwitchChange}
							value={config}
						/>
					}
					label={config === 'man' ? "Manual" : "Automático"}
					labelPlacement="start"
					sx={{
						width: "100%",
						justifyContent: "space-between",
						"& .MuiFormControlLabel-label": {
							marginRight: "10px",
						},
					}}
				/>
			</Box>
			<DialogActions>
				<Button onClick={close}>{t('boton-cancelar')}</Button>
				<Button onClick={submitChanges} >
					{t('boton-guardar-cambios')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
