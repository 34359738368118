import Map from "./map";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CountryAvaidabilityChecker from "./notAvaidableCountry";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function LoginRequiredDialog (props) {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Dialog open={props.open}>
            <DialogTitle>{t("login-requerido")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("login-requerido-descripcion")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => history.replace(("/login"))}>{t("iniciar-sesion")}</Button>
                <Button variant="contained" onClick={() => history.replace("/register")}>{t("registrarse")}</Button>
            </DialogActions>
        </Dialog>
    )
}

LoginRequiredDialog.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default function LoginRequiredPath(props) {

    const loadUserStatus = useSelector(state => state.user.status);
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showCountryDialog, setShowCountryDialog] = useState(false);
    const [hasCheckedCountry, setHasCheckedCountry] = useState(false);

    useEffect(() => {
        if (showCountryDialog === false && hasCheckedCountry === true) {
            const timer = setTimeout(() => {
                if (isLoggedIn || ((loadUserStatus === 'init' || loadUserStatus === 'loading') && isLoggedIn === false)) 
                    setShowLoginDialog(false);
                else
                    setShowLoginDialog(true);
            }, 8000);
            return () => clearTimeout(timer);
        }
    }, [showCountryDialog, hasCheckedCountry, isLoggedIn, loadUserStatus])

    return (
        <>
            <CountryAvaidabilityChecker open={showCountryDialog} setOpen={setShowCountryDialog} setHasCheckedCountry={setHasCheckedCountry}/>
            <LoginRequiredDialog open={showLoginDialog} />
            <Map {...props} />
        </>
    )

}