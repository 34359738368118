import i18n from '../../../i18nextConf';

const categories: { [id: number]: string } = {
  0: i18n.t('deteccion-ia'),
  10: i18n.t('procesado'),
  30: i18n.t('procesado'),
  11: i18n.t('saturado'),
  31: i18n.t('saturado'),
  12: i18n.t('contaminado-por-nubes'),
  32: i18n.t('contaminado-por-nubes'),
  13: i18n.t('probabilidad-alta'),
  33: i18n.t('probabilidad-alta'),
  90: i18n.t('probabilidad-alta'),
  14: i18n.t('probabilidad-media'),
  34: i18n.t('probabilidad-media'),
  50: i18n.t('probabilidad-media'),
  15: i18n.t('probabilidad-baja'),
  35: i18n.t('probabilidad-baja'),
  20: i18n.t('probabilidad-baja'),
  101: i18n.t('rayo'),
};

export function getCathegoryText (sat: string, conf: number): string {
  if (sat === "sr-goes" || sat === "sr-goes-v2") return categories[0];
  if (sat === "noaa-goes16" || sat === "noaa-goes17" || sat === "noaa-goes18") return categories[conf];
  if (sat === "VIIRS Suomi NPP" || sat === "VIIRS NOAA-20" || sat === "VIIRS NOAA-21" || sat === "VIIRS N20" || sat === "VIIRS N21") return categories[conf];
  if (sat === "MODIS Terra" || sat === "MODIS Aqua" || sat === "VIIRS 1.0") {
    if (conf >= 0 && conf <= 35) return categories[15];
    if (conf > 35 && conf <= 65) return categories[14];
    if (conf > 65 && conf <= 100) return categories[13];
  };
  return "N/a";
}

const colorIcon : { [id: number]: string } = {
  0: "#996DD7",
  10: "#771717",
  30: "#771717",
  11: "#B71C1C",
  31: "#B71C1C",
  12: "#90A4AE",
  32: "#90A4AE",
  13: "#F44336",
  33: "#F44336",
  90: "#F44336",
  14: "#FF7A00",
  34: "#FF7A00",
  50: "#FF7A00",
  15: "#F6BF26",
  35: "#F6BF26",
  20: "#F6BF26",
  101: "#4264FB",
};

export function getWildfireColor (sat: string, conf: number): string {
  if(sat === "sr-goes" || sat === "sr-goes-v2") return colorIcon[0];
  if(sat === "noaa-goes16" || sat === "noaa-goes17" || sat === "noaa-goes18") return colorIcon[conf];
  if(sat === "VIIRS Suomi NPP" || sat === "VIIRS NOAA-20" || sat === "VIIRS NOAA-21" || sat === "VIIRS N20" || sat === "VIIRS N21") return colorIcon[conf];
  if(sat === "MODIS Terra" || sat === "MODIS Aqua" || sat === "VIIRS 1.0"){
      if(conf >= 0 && conf <=35) return colorIcon[15];
      if(conf > 35 && conf <=65) return colorIcon[14];
      if(conf > 65 && conf <=100) return colorIcon[13];
  };
  return "N/a";
}

const GOESCategories: { [id: number]: string } = {
  0: "deteccion-ia",
  10: "procesado",
  30: "procesado",
  11: "saturado",
  31: "saturado",
  12: "contaminado-por-nubes",
  32: "contaminado-por-nubes",
  13: "probabilidad-alta",
  33: "probabilidad-alta",
  14: "probabilidad-media",
  34: "probabilidad-media",
  15: "probabilidad-baja",
  35: "probabilidad-baja",
  101: "rayo"
};

const VIIRSCategories: { [id: number]: string } = {
  20: "probabilidad-baja",
  50: "probabilidad-media",
  90: "probabilidad-alta",
};

export function PickSatCategory(sat: string, conf: number): string {
  if(sat === "sr-goes" || sat === "sr-goes-v2") return `${i18n.t(GOESCategories[conf])}`;
  if(sat === "noaa-goes16" || sat === "noaa-goes17" || sat === "noaa-goes18") return `${i18n.t(GOESCategories[conf])}`;
  if(sat === "VIIRS Suomi NPP" || sat === "VIIRS NOAA-20" || sat === "VIIRS NOAA-21" || sat === "VIIRS N20" || sat === "VIIRS N21") return `${i18n.t(VIIRSCategories[conf])}`;
  if(sat === "MODIS Terra" || sat === "MODIS Aqua" || sat === "VIIRS 1.0") return `${conf}% ${i18n.t('de-probabilidad')}`;
  return "N/a";
}