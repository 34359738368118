import React from "react";
import { useTranslation } from 'react-i18next'
import { DialogContentText, DialogActions, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions as polygonsActions } from "../../redux/slices/polygonsSlice";
import "../../scss/bottombar.scss";
import {
	MobileDialog,
	Dialog,
	MobileDialogTitle,
	DialogTitle,
	DialogContent,
} from "../StyledComponents/Dialog";
import { Button } from "../StyledComponents/Button";
import { DeleteOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const mobile = useSelector((state) => state.sidebar.mobile);
	const zone = props.zone;
	function handleClose(result) {
		if (result) dispatch(polygonsActions.deletePolygon({ id: zone.id }));
		props.setOpen(false);
		if (result && props.afterClose) props.afterClose();
	}

	return (
		<>
			{mobile ? (
				<MobileDialog
					open={props.open}
					onClose={() => props.setOpen(false)}
					TransitionComponent={Transition}
				>
					<DeleteOutlined
						color="primary"
						sx={{ alignSelf: "center", height: "30px", width: "30px" }}
					/>
					<MobileDialogTitle>{t('borrar-zona-titulo')}</MobileDialogTitle>
					<DialogContent>
						<DialogContentText>
							{`${t('borrar-zona-texto')} ${zone?.name}?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose(false)}>{t("boton-cancelar")}</Button>
						<Button variant="contained" onClick={() => handleClose(true)}>
							{t("boton-eliminar-zona")}
						</Button>
					</DialogActions>
				</MobileDialog>
			) : (
				<>
					<Dialog
						open={props.open}
						onClose={() => props.setOpen(false)}
						TransitionComponent={Transition}
					>
						<DialogTitle>{t('borrar-zona-titulo')}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{`${t('borrar-zona-texto')} ${zone?.name}?`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => handleClose(false)}>{t("boton-cancelar")}</Button>
							<Button variant="contained" onClick={() => handleClose(true)}>
								{t("boton-eliminar-zona")}
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
}
