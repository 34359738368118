import React from "react";
import { useTranslation } from 'react-i18next';
import { DialogContentText, DialogActions, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {actions as modalActions} from "../../redux/slices/modalsSlice"
import "../../scss/bottombar.scss";
import {
	MobileDialog,
	Dialog,
	MobileDialogTitle,
	DialogTitle,
	DialogContent,
} from "../StyledComponents/Dialog";
import { Button } from "../StyledComponents/Button";
import { NewReleases } from "@mui/icons-material";
import ReactGA from "react-ga4";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewUpdate(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const mobile = useSelector((state) => state.sidebar.mobile);
	// httpsconst openNewVersionDialog = useSelector((state) => state.tour.openNewVersionDialog);
	const openNewVersionDialog = useSelector((state) => state.modal.openNewVersionDialog);
	const sub = useSelector((state) => state.user?.userData?.sub);
	
	function handleClose() {
		ReactGA.event({ category: "Nueva Actualización", action: sub || "No logueado" });
		dispatch(modalActions.toggleNewVersionDialog())
		//if (result && props.afterClose) props.afterClose();
	}

	return (
		<>
			{mobile ? (
				<MobileDialog
					open={openNewVersionDialog}
					onClose={() => handleClose()}
					TransitionComponent={Transition}
				>
					<NewReleases
						color="primary"
						sx={{ alignSelf: "center", height: "30px", width: "30px" }}
					/>
					<MobileDialogTitle>{t('new-version-titulo')}</MobileDialogTitle>
					<DialogContent>
						<DialogContentText>
                        {t('new-version-texto')}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose()}>{t('boton-entendido')}</Button>
					</DialogActions>
				</MobileDialog>
			) : (
				<>
					<Dialog
						open={openNewVersionDialog}
						onClose={() => handleClose()}
						TransitionComponent={Transition}
					>
						<NewReleases
						color="primary"
						sx={{ alignSelf: "center", height: "30px", width: "30px" }}
					/>
						<DialogTitle sx={{ alignSelf: "center"}}>{t('new-version-titulo')}</DialogTitle>
						<DialogContent>
							<DialogContentText>
                            {t('new-version-texto')}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => handleClose(false)}>{t('boton-entendido')}</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
}
