export function formatDateTime (date: string): string {
  const currentDate = new Date(date.substring(0,25));
  const dateStr = `${currentDate.getFullYear()}/${currentDate.getMonth()+1<10?'0':''}${currentDate.getMonth()+1}/${currentDate.getDate()}`
  const timeStr = `${currentDate.getHours()}:${(currentDate.getMinutes()<10?'0':'')}${currentDate.getMinutes()}${currentDate.getHours()>=12 ? "pm" : "am"}`;
  return dateStr + " " + timeStr;
}

export function getDate (fileString: string): string {
  const dateStr = fileString.split('_')[0];
  const date: Date = new Date(dateStr);
  return `${date.getFullYear()}/${date.getMonth()+1<10?'0':''}${date.getMonth()+1}/${date.getDate()}`
}

export function getTime (fileString: string) {
  const dateStr = fileString.split('_')[0];
  const date: Date = new Date(dateStr);
  return `${date.getHours()}:${(date.getMinutes()<10?'0':'')}${date.getMinutes()}${date.getHours()>=12 ? "pm" : "am"}`;
}

export function formatConfidence (conf: number){
  const confidence = conf * 100;
  return `${confidence.toFixed(0)}%`
}