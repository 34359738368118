import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiCameras from '../../api/cameras'
import { CameraState, Wildfire } from './types/cameraTypes';
import swal from "sweetalert";
import i18n from '../../i18nextConf';
import bugsnag from '@bugsnag/js';


const initialState: CameraState = {
    cameras: [],
    wildfiresCameras: [],
    status: {
        status: 'idle',
        loading: false,
    },
    error: null,
};

export const getCameras = createAsyncThunk('cameras/getCameras', async (args, thunkAPI: any) => {
    const state = thunkAPI.getState();
    if (!state.user.isLoggedIn) throw new Error("User is not logged in");

    try {
        const response = await apiCameras.getCams();
        return response
    } catch (err: any) {
        swal(i18n.t('swal-error-mostrando-poligono'), err.message, "error")
        throw err;
    }
});

export const getWildfiresCameras = createAsyncThunk('cameras/getWildfiresCameras', async (args: any, thunkAPI: any) => {
    const state = thunkAPI.getState();
    if (!state.user.isLoggedIn) throw new Error("User is not logged in");
    console.log(args.cameraID)
    try {
        const response = await apiCameras.get(args.cameraID, args.backwardHours);
        return response
    } catch (err: any) {
        swal(i18n.t('swal-error-mostrando-poligono'), err.message, "error")
        throw err;
    }
});

export const updateCameraEvent = createAsyncThunk('cameras/updateCameraEvent', async (data: any, thunkAPI: any) => {
    const state = thunkAPI.getState();
    if (!state.user.isLoggedIn) throw new Error("User is not logged in");
    try {
        const response = await apiCameras.putCameraEvent(data);
        return response
    } catch (err: any) {
        swal(i18n.t('swal-error-mostrando-poligono'), err.message, "error")
        throw err;
    }
});

export const updateCamera = createAsyncThunk('cameras/updateCamera', async (data: any, thunkAPI: any) => {
    const state = thunkAPI.getState();
    if (!state.user.isLoggedIn) throw new Error("User is not logged in");
    try {
        const response = await apiCameras.putCamera(data);
        return response
    } catch (err: any) {
        swal(i18n.t('swal-error-mostrando-poligono'), err.message, "error")
        throw err;
    }
});

const slice = createSlice({
    name: 'cameras',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(getCameras.fulfilled, (state, action) => {
                state.cameras = action.payload;
                state.status.status = 'succeded';
                state.status.loading = false;
            })
            .addCase(getWildfiresCameras.fulfilled, (state, action: any) => {
                state.wildfiresCameras = action.payload;
                state.status.status = 'succeded';
                state.status.loading = false;
            })
            .addCase(updateCameraEvent.fulfilled, (state, action) => {
                const { bbox, classNumber, confidence, confirmWildfire, date_id, hasBeenDetectedByHuman} = action.payload.data.updateCameraEvent;
				state.wildfiresCameras.forEach((wildfire: Wildfire, index) => {
					if (wildfire.date_id === action.payload.data.updateCameraEvent.date_id) state.wildfiresCameras[index] = { bbox, classNumber, confidence, confirmWildfire, date_id, hasBeenDetectedByHuman};

				})
				//state.status.CRUDStatus = 'succeded';
				//state.status.loading = false;
                console.log(action.payload)
                //state.wildfiresCameras = action.payload;
                state.status.status = 'succeded';
                state.status.loading = false;
            })
            .addCase(updateCamera.fulfilled, (state, action) => {
                state.status.status = 'succeded';
                state.status.loading = false;
            })
            .addMatcher(
                action => action.type.endsWith('pending') && (action.type.startsWith("cameras")),
                (state, action) => {
                    if(action.type.startsWith("cameras")) state.status.loading = true;
                }
            )
            .addMatcher(
                action => action.type.endsWith('rejected') && (action.type.startsWith("cameras")),
                (state, action) => {
                    state.status.status = 'failed';
                    bugsnag.notify(new Error(action.error.message));
                    state.status.loading = false;
                    state.error = action.error.message;
                }
            );
    },
});


export const actions = { ...slice.actions, getCameras, getWildfiresCameras, updateCameraEvent, updateCamera };

export default slice.reducer;