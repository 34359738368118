import React from 'react';
import EmailVerification from '../../components/user/EmailVerification'


function VerifyEmail(props){
    return(
        <>
            <EmailVerification {...props}/>
        </>
    );
}

export default VerifyEmail