import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bugsnag from '@bugsnag/js';
import notifications from '../../api/notifications';

const initialState = {
	notificationsEnabled: false,
	snackbarButton: false,
    status: 'idle',
};

export const enableAlerts = createAsyncThunk("notifications/enable", async (args, thunkAPI) => {
	await notifications.Subscribe();
	await notifications.TestNotification();
    
});

export const disableAlerts = createAsyncThunk("notifications/disable", async (args, thunkAPI) => {
	await notifications.Unsubscribe();
})

export const checkNotificationsEnabled = createAsyncThunk("notifications/isEnabled", async (args, thunkAPI) => {
	return await notifications.IsSubscribed();
})

const slice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		setAlertSubscription: (state, action) => {
			state.notificationsEnabled = action.payload;
		},
		toggleSnackbarButton: (state) =>{
			if(state.snackbarButton=== false) state.snackbarButton = !state.snackbarButton;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(enableAlerts.fulfilled, (state) => {
				state.notificationsEnabled = true;
				state.status = false;
			})
			.addCase(disableAlerts.fulfilled, (state) => {
				state.notificationsEnabled = false;
				state.status = false;
			})
			.addCase(checkNotificationsEnabled.fulfilled, (state, action) => {
				state.notificationsEnabled = action.payload;
				state.status = false;
			})
			.addMatcher(
				(action) => action.type.endsWith("pending") && action.type.startsWith("notifications"),
				(state, action) => {
					state.status = "loading";
				}
			)
			.addMatcher(
				(action) => action.type.endsWith("rejected") && action.type.startsWith("notifications"),
				(state, action) => {
					bugsnag.notify(new Error(action.error.message));
					state.status = "failed";
					state.error = action.error.message;
				}
			);
	},
});

export const actions = {
	...slice.actions, enableAlerts, disableAlerts, checkNotificationsEnabled
};

export const selectNotifications = (state) => state.settings.notificationsEnabled;
export const selectStatus = (state) => state.settings.status;

export default slice.reducer;
