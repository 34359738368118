import gql from "graphql-tag";
import i18n from '../../i18nextConf';
import { CognitoConfig } from "../wildfires/utils/CognitoConfig";
import {
	createNotificationEndpoint,
	updateNotificationEndpoint,
	deleteNotificationEndpoint,
} from "../../graphql/mutations";

// Private functions
function isPushNotificationSupported() {
	return (
		"serviceWorker" in navigator &&
		"PushManager" in window &&
		"Notification" in window
	);
}

async function askUserPermission() {
	if (!isPushNotificationSupported())
		throw new Error("No están disponibles las notificaciones");
	const response = await Notification.requestPermission();
	if (response === "granted") return response;
	else throw new Error("No se ha otorgado el permiso de notificaciones");
}

async function createNotificationSubscription() {
	const serviceWorker = await navigator.serviceWorker.ready;
	return await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey:
			"BBHWR-ZKeC_P9zetexB9MubP-SOYCcYiXkKSydBPkAv-Jksf2H8h16qAT3nPQ-IH0iRKjK0BSZghhJzo5LympWA",
	});
}

async function getSubscription() {
	const serviceWorker = await navigator.serviceWorker.ready;
	return await serviceWorker.pushManager.getSubscription();
}

async function enableNotifications() {
	await askUserPermission();
	return await createNotificationSubscription();
}

// Public functions:

async function IsSubscribed() {
	if (!isPushNotificationSupported()) return false;

	if (
		(await getSubscription()) === null ||
		localStorage.getItem("notificationEndpoint") === null ||
		Notification.permission !== "granted"
	)
		return false;
	else return true;
}

async function Subscribe() {
	if (await IsSubscribed()) return;

	const data = await enableNotifications();

	if (localStorage.getItem("notificationEndpoint") === null) {
		const input = { endpoint: JSON.stringify(data.toJSON()) };
		const createdEndpoint = await CognitoConfig.mutate({
			mutation: gql(createNotificationEndpoint),
			variables: { input },
		});
		const endpointId =
			createdEndpoint.data.createNotificationEndpoint.endpointId;
		localStorage.setItem("notificationEndpoint", endpointId);
	} else {
		const endpointId = localStorage.getItem("notificationEndpoint");
		const input = {
			endpointId: endpointId,
			endpoint: JSON.stringify(data.toJSON()),
		};
		await CognitoConfig.mutate({
			mutation: gql(updateNotificationEndpoint),
			variables: { input },
		});
	}
}

async function Unsubscribe() {
	if (!isPushNotificationSupported()) return;
	const endpointId = localStorage.getItem("notificationEndpoint");

	if (!(await IsSubscribed())) {
		console.warn("User not subscribed to notifications");
		return;
	}

	const serviceWorker = await navigator.serviceWorker.ready;

	const subscription = await serviceWorker.pushManager.getSubscription();
	//const subscription = await serviceWorker.safari.pushNotification.getSubscription();
	await subscription.unsubscribe();

	const input = { endpointId: endpointId };
	await CognitoConfig.mutate({
		mutation: gql(deleteNotificationEndpoint),
		variables: { input },
	});
	localStorage.removeItem("notificationEndpoint");
}

// Write a function that sends a test notification to the user
async function TestNotification() {
	if (!isPushNotificationSupported() || !(await IsSubscribed())) return;
	console.log("Sending test notification!");
	/* const serviceWorker = await navigator.serviceWorker.ready; */
	const subscription = await navigator.serviceWorker.getRegistration();

	// Parametros: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification
	const data = {
		title: i18n.t("notificacion-prueba-titulo"),
		body: i18n.t("notificacion-prueba-texto"),
		icon: "logo.png",
		requireInteraction: true,
		vibrate: [100, 50, 100],
		data: {
			dateOfArrival: Date.now(),
			primaryKey: 1,
		},
	};
	await subscription.showNotification(i18n.t("notificacion-prueba-titulo"), data);
}

const exportSuscribe = {
	IsSubscribed,
	Subscribe,
	Unsubscribe,
	TestNotification,
};
export default exportSuscribe;

// https://itnext.io/react-push-notifications-with-hooks-d293d36f4836
// https://developer.mozilla.org/en-US/docs/Web/API/Push_API
