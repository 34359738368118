/* eslint-disable import/no-anonymous-default-export */
import gql from "graphql-tag";
import { getUserConfig } from "../../graphql/queries";
import { updateUserConfig, redeemPromoCode } from "../../graphql/mutations";
import { CognitoConfig } from "../wildfires/utils/CognitoConfig";

async function userConfig() {
	try {
		const { data } = await CognitoConfig.query({
			query: gql(getUserConfig),
			fetchPolicy: "network-only",
		});
		return data.getUserConfig;
	} catch (err) {
		console.error(err);
	}
}

async function updateConfig(data) {
	const newTodo = await CognitoConfig.mutate({
		mutation: gql(updateUserConfig),
		variables: { input: data },
	});
	return newTodo;
}

async function redeemCode(data) {
	const newTodo = await CognitoConfig.mutate({
		mutation: gql(redeemPromoCode),
		variables: { promoCode: data },
	});
	return newTodo;
}
export default { userConfig, updateConfig, redeemCode };
