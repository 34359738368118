import { DialogContentText, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Button } from "../StyledComponents/Button";
import {
	DialogContent,
	MobileDialog, MobileDialogActions, MobileDialogTitle
} from "../StyledComponents/Dialog";
import AppBar from "./AppBar/AppBar";
import BottomNavBar from "./BottomNavBar/BottomNavBar";
import CrearZona from "./MisZonas/CrearZona";
import EditarZona from "./MisZonas/EditarZona";
import Zona from "./MisZonas/Zona";
import ZonaCamaras from "./MisZonas/ZonaCamaras";
import MisCamaras from "./MisZonas/MisCamaras";
import Ajustes from "./Tabs/Ajustes";
import FAQ from "./Tabs/FAQ";
import Feedback from "./Tabs/Feedback";
import FocosHistoricos from "./Tabs/FocosHistoricos";
import MisZonas from "./Tabs/MisZonas";
import TiempoReal from "./Tabs/TiempoReal";

export default function Container({ mapElement }) {
	const [selectedItem, setSelectedItemN] = useState(-1);
	const [showMap, setShowMap] = useState(true);
	const history = useHistory();
	const notificationsStatus = useSelector((state) => state.settings.status);

	useEffect(() => {
		switch (history.location.pathname) {
			case "/":
				setShowMap(true);
				setSelectedItemN(0);
				break;
			case "/focos-historicos":
				setShowMap(true);
				setSelectedItemN(1);
				break;
			case "/alertas":
				setShowMap(false);
				setSelectedItemN(2);
				break;
			case "/camaras":
				setShowMap(false);
				setSelectedItemN(3);
				break;
			default:
				if (history.location.pathname.includes("/alertas")) setSelectedItemN(2);
				else setSelectedItemN(-1);
				break;
		}
	}, [history.location.pathname]);

	const setSelectedItem = (newValue) => {
		switch (newValue) {
			case 0:
				history.push("/");
				break;
			case 1:
				history.push("/focos-historicos");
				break;
			case 2:
				history.push("/alertas");
				break;
			case 3:
				history.push("/camaras")
				/* if (!loggedIn) history.push("/login");
				else setSettingsOpen(true); */
				return;
			case 4:
				/* setFeedbackOpen(true); */
				return;
			default:
				return;
		}
	};

	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const closeConfirmationDialog = () => {
		setConfirmationDialogOpen(false);
	};
	window.abrirInstructivo = () => setConfirmationDialogOpen(true);

	return (
		<>
			<MobileDialog maxWidth="xs" open={confirmationDialogOpen} onClose={closeConfirmationDialog}>
				<MobileDialogTitle>Abrir instructivo</MobileDialogTitle>
				<DialogContent>
					<DialogContentText>¿Estás seguro de que quieres abrir el instructivo?</DialogContentText>
				</DialogContent>
				<MobileDialogActions>
					<Button onClick={closeConfirmationDialog}>Cancelar</Button>
					<Button
						onClick={() => {
							closeConfirmationDialog();
							window
								.open("https://sof-wildfire-bucket.s3.amazonaws.com/Instructivo+SoF.pdf", "_blank")
								.focus();
						}}
					>
						Abrir
					</Button>
				</MobileDialogActions>
			</MobileDialog>

			<AppBar />
			{notificationsStatus === "loading" && <LinearProgress />}

			<Switch>
				<Redirect from="/zonas" to="/alertas" />
				<Route exact path="/" component={TiempoReal} />
				<Route exact path="/configuracion">
					<Ajustes setShowMap={setShowMap} />
				</Route>
				<Route exact path="/feedback">
					<Feedback setShowMap={setShowMap} />
				</Route>
				<Route exact path="/faq">
					<FAQ setShowMap={setShowMap} />
				</Route>
				{/* <Route exact path="/info">
					<InfoDialog open={history.location.pathname === "/info"} close={close} setShowMap={setShowMap} />
				</Route> */}
				<Route exact path="/focos-historicos" component={FocosHistoricos} />
				<Route exact path="/alertas" component={MisZonas} />
				<Route path="/alertas/crear">
					<CrearZona setShowMap={setShowMap} />
				</Route>
				<Route path="/alertas/editar">
					<EditarZona setShowMap={setShowMap} />
				</Route>
				<Route exact path="/alertas/zona">
					<Zona setShowMap={setShowMap} showMap={showMap} />
				</Route>
				<Route exact path="/camaras" component={MisCamaras}/>
				<Route exact path="/camaras/cam">
					<ZonaCamaras setShowMap={setShowMap} showMap={showMap} />
				</Route>
			</Switch>

			{showMap ? mapElement : <></>}
			<BottomNavBar
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
			/>
		</>
	);
}
