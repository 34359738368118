import { createSlice } from "@reduxjs/toolkit";
//import state from 'sweetalert/typings/modules/state';

const initialState = {
	// status: 'idle', // status: 'idle' | 'loading' | 'succeeded' | 'failed',
	// error: null, // error: string | null
	// userData: {},
	map: null,
	layerMapbox: "satellite-v9" /*  'satellite-v9' */,
	layerLeaflet: 0,
	center: [],
	polygon: [],
	wildfireClicked: null,
	onMove: false,
	coordsMarker: ""
};

const slice = createSlice({
	name: "incendios",
	initialState,
	reducers: {
		setLayer: (state, action) => {
			state.layerMapbox = action.payload;
		},
		toggleLayerByName: (state, action) => {
			state.layerMapbox = action.payload;
		},
		toggleLayerLeaflet: (state) => {
			if (state.layerLeaflet === 0) state.layerLeaflet = 1;
			else state.layerLeaflet = 0;
		},
		createMap: (state, action) => {
			state.map = action.payload;
		},
		// La siguiente función puede no ser útil porque fue eliminada al implementar Leaflet:
		setOnMove: (state, action) => {
			state.onMove = action.payload;
		},
		setCenter: (state, action) => {
			state.center = action.payload;
		},
		setPolygon: (state, action) => {
			state.polygon = action.payload;
		},
		setWildfireClicked: (state, action) => {
			state.wildfireClicked = action.payload;
		},
		setCoordsMarker: (state, action) => {
			state.coordsMarker = action.payload;
		},
		deletePolygon: (state) => {
			state.polygon = [];
		},
	}
});
// Selectors
export const selectLayer = (state) => state.map.layerMapbox;
export const selectMap = (state) => state.map.map;
export const selectCenter = (state) => state.map.center;
export const selectPolygon = (state) => state.map.polygon;
export const selectOnMove = (state) => state.map.onMove;
export const selectWildfireClicked = (state) => state.map.wildfireClicked;
export const selectCoordsMarker = (state) => state.map.coordsMarker;

// Actions
export const actions = { ...slice.actions };

export default slice.reducer;
