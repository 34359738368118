import notifications from "../../api/notifications";
import userConfig from "../../api/user";
//import { userConfig } from "../../api/user";
import { actions as settings } from "./settingsSlice";
import { actions as mapActions } from "./mapSlice";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import bugsnag from '@bugsnag/js';
import i18n from '../../i18nextConf';

const initialState = {
	status: "init", // status: 'init' | 'idle' | 'loading' | 'succeeded' | 'failed',
	isLoggedIn: false,
	error: null, // error: string | null
	userData: {},
	daysOfAlerts: null
};

async function getUserData() {
	const result = await userConfig.userConfig();
	return result;
}

export const refreshUserData = createAsyncThunk(
	"user/refreshUserData",
	async () => {
		const res = await Auth.currentSession();
		const token = res.getIdToken().getJwtToken();
		const userInfo = await Auth.currentUserInfo();
		const username = userInfo.attributes.name;
		const email = userInfo.attributes.email;
		const sub = userInfo.attributes.sub;
		const userData = await getUserData();

		return { token, username, email, sub, userData };
	}
);


export const retrieveUserData = createAsyncThunk(
	"user/retrieveUser",
	async (args, thunkAPI) => {

		const res = await Auth.currentSession();
		const token = res.getIdToken().getJwtToken();
		const userInfo = await Auth.currentUserInfo();
		const username = userInfo.attributes.name;
		const email = userInfo.attributes.email;
		const sub = userInfo.attributes.sub;
		const userData = await getUserData();
		const layer = userData.defaultLayer;
		if (layer !== null) thunkAPI.dispatch(mapActions.setLayer(layer));

		userData.language = i18n.language.includes("es") ? "es" : "en";
		updateUserConfig(userData);

		return { token, username, email, sub, userData };
	}
);

export const updateUserConfig = createAsyncThunk(
	"user/updateUserConfig",
	async (
		{
			name,
			surname,
			defaultLayer,
			email,
			language,
			country,
			phone,
			notifyByEmail,
			notifyByPush,
			notifyMinuteGap,
			typeOfAlert,
			notifyByPhone,
			userType,
			userTypeTill,
		},
		thunkAPI
	) => {
		const state = thunkAPI.getState();
		if (!state.user.isLoggedIn) throw new Error("User is not logged in");

		//coords = JSON.stringify(coords);

		const data = {
			name,
			surname,
			country,
			phone,
			notifyByEmail,
			notifyByPush,
			notifyByPhone,
			defaultLayer,
			notifyMinuteGap,
			typeOfAlert,
			language
		};
		try {
			const response = await userConfig.updateConfig(data);
			thunkAPI.dispatch(mapActions.setLayer(defaultLayer));
			swal(i18n.t('swal-configuracion-modificada-titulo'), i18n.t('swal-configuracion-modificada-texto'), "success");
			return response;
		} catch (err) {
			swal(i18n.t('swal-error-configuracion-modificada'), err.message, "error");
			throw err;
		}
	}
);
export const signIn = createAsyncThunk(
	"user/signIn",
	async ({ username, password, email }, thunkAPI) => {
		const data = { username, password, email };
		try {
			const res = await Auth.signIn(data);
			const email = res.attributes.email;
			const sub = res.attributes.sub;
			const session = await Auth.currentSession();
			const token = session.getIdToken().getJwtToken();
			const userData = await getUserData();
			const username = userData.name;
			/* const layer = userData.defaultLayer;
			if(layer !== undefined) thunkAPI.dispatch(mapActions.setLayer(layer)); */

			swal(
				`${i18n.t('swal-bienvenida-titulo')} ${username}!`,
				i18n.t('swal-bienvenida-texto'),
				"success"
			);
			return { token, username, email, userData, sub };
		} catch (err) {
			if (err.name === "UserNotConfirmedException") {
				swal(
					i18n.t('swal-no-verificaste-mail-titulo'),
					i18n.t('swal-no-verificaste-mail-texto'),
					"error"
				);
				throw err;
			} else {
				swal(i18n.t('swal-error-login-titulo'), err.message, "error");
				throw err;
			}
		}
	}
);

export const signUp = createAsyncThunk(
	"user/signUp",
	async ({ username, password, name, country, phone, organizationType, organizationName, code }, thunkAPI) => {
		const data = { username: username, password, attributes: { name: name, "custom:promoCode": code, "custom:country": country, "custom:phone": phone, "custom:organizationType": organizationType, "custom:organizationName": organizationName }};
		try {
			await Auth.signUp(data);
			swal(
				i18n.t('swal-verificar-mail-titulo'),
				i18n.t('swal-no-verificar-mail-texto'),
				"success"
			);
			/* if (code !== undefined && code !== null && username) {
				try {
					console.log(username)
					const response = await userConfig.redeemCode(code);
					console.log(response)
					//swal("Exitoso", "Cambiaste la configuración correctamente", "success")
					return response
				} catch (err) {
					//swal("Error modificando la configuración", err.message, "error")
					throw err;
				}
			} */
			return {};
		} catch (err) {
			swal(i18n.t('swal-error-creando-usuario'), err.message, "error");
			throw err;
		}
	}
);

export const confirmEmail = createAsyncThunk(
	"user/confirmEmail",
	async ({ username, code }, thunkAPI) => {
		try {
			await Auth.confirmSignUp(username, code);
			swal(i18n.t('swal-verificaste-mail-titulo'), i18n.t('swal-verificaste-mail-texto'), "success");
			return {};
		} catch (err) {
			swal(i18n.t('swal-error-confirmando-mail'), err.message, "error");
			throw err;
		}
	}
);

export const forgotPassword = createAsyncThunk(
	"user/forgotPassword",
	async ({ email }, thunkAPI) => {
		try {
			await Auth.forgotPassword(email);
			return {};
		} catch (err) {
			swal(
				i18n.t('swal-error-ingresar-correo'),
				err.message,
				"error"
			);
			throw err;
		}
	}
);

export const verifyPassword = createAsyncThunk(
	"user/verifyPassword",
	async ({ code, email, password }, thunkAPI) => {
		try {
			await Auth.forgotPasswordSubmit(email, code, password);
			swal(
				i18n.t('swal-cambio-contrasena-titulo'),
				i18n.t('swal-cambio-contrasena-texto'),
				"success"
			);
			return {};
		} catch (err) {
			swal(i18n.t('swal-error-ingresar-codigo'), err.message, "error");
			throw err;
		}
	}
);

export const signOut = createAsyncThunk(
	"user/signOut",
	async (args, thunkAPI) => {
		await notifications.Unsubscribe();
		thunkAPI.dispatch(settings.setAlertSubscription(false));
		const signOutResponse = await Auth.signOut();
		return signOutResponse;
	}
);

export const redeemPromoCode = createAsyncThunk(
	"user/promoCode",
	async (code, thunkAPI) => {
		try {
			const response = await userConfig.redeemCode(code.code);
			code.close();
			swal(
				i18n.t('swal-redencion-codigo-suscripcion-titulo'),
				i18n.t('swal-redencion-codigo-suscripcion-texto'),
				"success"
			);
			return response;
		} catch (err) {
			swal(i18n.t('swal-erro-configuracion-modificada'), err.message, "error");
			throw err;
		}
	}
);

const slice = createSlice({
	name: "user",
	initialState,
	reducers: {
		clearStatus: (state) => {
			state.status = "idle";
		},
		updateConfiguration: (state, action) => {
			state.configure = action.payload;
			localStorage.setItem("config", JSON.stringify(action.payload));
			window.location.reload();
		},
		setDaysOfAlerts: (state, action) => {
			state.daysOfAlerts = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveUserData.fulfilled, (state, action) => {
				state.userData = action.payload;
				state.isLoggedIn = true;
				state.status = "succeded";
				//state.userData.userData.userType = "Cameras";
			})
			.addCase(retrieveUserData.rejected, (state, action) => {
				state.isLoggedIn = false;
			})
			.addCase(refreshUserData.fulfilled, (state, action) => {
				state.userData = action.payload;
				state.isLoggedIn = true;
				state.status = "succeded";
			})
			.addCase(refreshUserData.rejected, (state, action) => {
				state.isLoggedIn = false;
			})
			.addCase(updateUserConfig.fulfilled, (state, action) => {
				//console.log(action.payload)
				state.userData.userData = action.payload.data.updateUserConfig;
				state.status = "succeded";
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.userData = action.payload;
				state.isLoggedIn = true;
				state.status = "succeded";
			})
			.addCase(signOut.fulfilled, (state) => {
				state.isLoggedIn = false;
				state.userData = {};
				state.status = "idle";
			})
			.addCase(signUp.fulfilled, (state) => {
				state.status = "succeded";
			})
			.addCase(confirmEmail.fulfilled, (state) => {
				state.status = "succeded";
			})
			.addCase(forgotPassword.fulfilled, (state) => {
				state.status = "succeded";
			})
			.addCase(verifyPassword.fulfilled, (state) => {
				state.status = "succeded";
			})
			.addCase(redeemPromoCode.fulfilled, (state, action) => {
				state.userData.userData.userType = "Premium";
				state.userData.userData.userTypeTill = action.payload.userTypeTill;
				state.status = "succeded";
			})
			.addMatcher(
				(action) =>
					action.type.endsWith("pending") && action.type.startsWith("user"),
				(state, action) => {
					state.status = "loading";
				}
			)
			.addMatcher(
				(action) =>
					action.type.endsWith("rejected") && action.type.startsWith("user"),
				(state, action) => {
					bugsnag.notify(new Error(action.error.message));
					state.status = "failed";
					state.error = action.error.message;
				}
			);
	},
});

export const actions = {
	...slice.actions,
	signIn,
	signUp,
	confirmEmail,
	forgotPassword,
	signOut,
	verifyPassword,
	retrieveUserData,
	refreshUserData,
	updateUserConfig,
	redeemPromoCode,
};

// Selectors
export const selectLoggedIn = (state) => state.user.isLoggedIn;
export const selectStatus = (state) => state.user.status;
export const selectError = (state) => state.user.error;
export const selectUserData = (state) => state.user.userData;
export const selectDaysOfAlerts = (state) => state.user.daysOfAlerts;

export default slice.reducer;
