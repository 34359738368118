/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../StyledComponents/Button";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import swal from "sweetalert";

export default function Feedback({ setShowMap }) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const messageRef = useRef();
	const nameRef = React.useRef();
	const emailRef = React.useRef();
	const userData = useSelector((state) => state.user.userData.userData);
	const loggedIn = useSelector((state) => state.user?.isLoggedIn);

	useEffect(() => {
		setShowMap(false);
		dispatch(appbar.setTitle(t('titulo-comentarios')));
		dispatch(appbar.setDefaultActions());
	}, []);

	const handleSubmit = () => {
		const data = {
			name: userData ? userData.email : nameRef.current.value,
			email: userData ? userData.name : emailRef.current.value,
			msg1: messageRef.current.value,
			msg2: "",
		};

		if (messageRef.current.value.length === 0) {
			swal(
				"Has dejado campos sin completar",
				"Por favor, rellena todos los campos antes de enviar",
				"error"
			);
			return;
		}

		emailjs
			.send(
				"service_6xrfft1",
				"template_90bw56f",
				data,
				"user_nxVUgnLDmTUknTkYy4Qzg"
			)
			.then(
				(res) => {
					//dispatch(modalActions.toggleFeedbackModal());
					swal(
						t('swal-enviar-comentario-titulo'),
						t('swal-enviar-comentario-texto'),
						"success"
					);
					history.push("/");
				},
				(err) => {
					swal(
						t('swal-error-enviar-comentario-titulo'),
						t('swal-error-enviar-comentario-texto'),
						"error"
					);
				}
			);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			height="100%"
			justifyContent="space-between"
			sx={{ padding: "20px 10px" }}
		>
			<Box display="flex" flexDirection="column" gap="20px" alignItems="center">
				<Typography variant="span" color="#707176" fontSize="1rem" width="95%">
				{t('texto-comentarios')}
				</Typography>
				{!loggedIn ? (
					<>
						<TextField
							inputRef={nameRef}
							name="name"
							label="Nombre"
							variant="standard"
							sx={{ width: "90%" }}
							required
						/>
						<TextField
							inputRef={emailRef}
							name="email"
							label="Mail"
							variant="standard"
							sx={{ width: "90%" }}
							required
						/>
					</>
				) : (
					<></>
				)}
				<TextField
					inputRef={messageRef}
					name="name"
					label={t('placeholder-campo-comentarios')}
					variant="standard"
					sx={{ width: "90%" }}
				/>
			</Box>
			<Button onClick={handleSubmit} variant="contained" sx={{ width: "100%" }}>
				{t('boton-enviar-mensaje')}
			</Button>
		</Box>
	);
}
