import { PublicWildfire, UserWildfires } from "../../../graphql/API";
import { getCathegoryText, getWildfireColor } from "./CathegoryColor";
import { formatDateTime } from "./FormatDateTime";
import { MapWildfire } from "../../../redux/slices/types/wildfireTypes";

function parsePublicWildfire (wildfire: PublicWildfire): MapWildfire {
  return {
    __typename: "PublicWildfire",
    id: wildfire.id,
    color: getWildfireColor(wildfire.sat || "", wildfire.conf || 0),
    cathegory: getCathegoryText(wildfire.sat || "", wildfire.conf || 0),
    cathegoryNum: wildfire.conf || 0,
    satellite: wildfire.sat || "",
    date: formatDateTime(wildfire.id || ""),
    x: wildfire.x || 0,
    y: wildfire.y || 0
  }
}

export function parsePublicWildfires (wildfires: PublicWildfire[]): MapWildfire[] {
  return wildfires.map(parsePublicWildfire);
}

function parseUserWildfire (wildfire: UserWildfires): MapWildfire {
  return {
    __typename: "PublicWildfire",
    id: wildfire.date_id,
    color: getWildfireColor(wildfire.sat || "", wildfire.conf || 0),
    cathegory: getCathegoryText(wildfire.sat || "", wildfire.conf || 0),
    cathegoryNum: wildfire.conf || 0,
    satellite: wildfire.sat || "",
    date: formatDateTime(wildfire.date_id || ""),
    x: wildfire.x || 0,
    y: wildfire.y || 0
  }
}

export function parseUserWildfires (wildfires: UserWildfires[]): MapWildfire[] {
  return wildfires.map(parseUserWildfire);
}