import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";
import mapReducer from "./slices/mapSlice";
import wildfireReducer from "./slices/wildfireSlice";
import sidebarReducer from "./slices/sidebarSlice";
import appbarReducer from "./slices/appbarSlice";
import polygonsReducer from "./slices/polygonsSlice";
import modalsReducer from "./slices/modalsSlice";
import tourReducer from "./slices/tourSlice";
import settingsSlice from "./slices/settingsSlice";
import cameraSlice from "./slices/cameraSlice";

const store = configureStore({
	reducer: {
		map: mapReducer,
		user: userReducer,
		wildfires: wildfireReducer,
		sidebar: sidebarReducer,
		appbar: appbarReducer,
		polygons: polygonsReducer,
		modal: modalsReducer,
		tour: tourReducer,
		settings: settingsSlice,
		camera: cameraSlice
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;