import axios from "axios";
import { getWildfireGroupData } from "../../../graphql/queries";
import { ApiKeyConfig } from '../utils/ApiKeyConfig';
import gql from 'graphql-tag';

export async function getZone(coords){
	try{
		const res = await axios({
			// REVERSE GEOCODING
			method: "get",
			url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[0]},${coords[1]}.json?types=locality&language=es&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
			headers: {
				"Content-Type": "application/json",
			},
		})
		return res.data.features[0].place_name 
	} catch{
		const res = await axios({
			// REVERSE GEOCODING
			method: "get",
			url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[0]},${coords[1]}.json?types=place&language=es&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
			headers: {
				"Content-Type": "application/json",
			},
		})
		return res.data.features[0].place_name 
	}
	
}

export async function getGroup (date, id) {
	try {

		const {data: {getWildfireGroupData: group}} = await ApiKeyConfig.query({
			query: gql(getWildfireGroupData),
			variables: {
				date, id
			}
		});
		return group;

	} catch (e) {
		return null;
	}
}