import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    openInfoModal: false, //Modal con la informacion sobre la deteccion
    openConfirmationModal: false, //Dialog para confirmar si queres eliminar una zona
    openBetaModal: false, // Modal para ver la informacion beta
    openFeedbackModal: false, //Modal para dejar feedback
    openConfigurationModal: false, //Modal para la configuracion
    openGenericModal: false, //Modal generico
    openGenericSnackbar: false, //Snackbar generico
    openNewVersionDialog: false, //Dialog de nueva version
};

const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleInfoModal: state => {
            state.openInfoModal = !state.openInfoModal;
        },
        toggleConfirmationModal: state => {
            state.openConfirmationModal = !state.openConfirmationModal;
        },
        toggleBetaModal: state => {
            state.openBetaModal = !state.openBetaModal;
        },
        toggleFeedbackModal: state => {
            state.openFeedbackModal = !state.openFeedbackModal;
        },
        toggleConfigurationModal: state => {
            state.openConfigurationModal = !state.openConfigurationModal;
        },
        toggleGenericModal: state => {
            state.openGenericModal = !state.openGenericModal;
        },
        toggleGenericSnackbar: state => {
            state.openGenericSnackbar = !state.openGenericSnackbar;
        },
        toggleNewVersionDialog: state => {
            state.openNewVersionDialog = !state.openNewVersionDialog;
        },
    },
});
// Selectors 
export const selectOpenInfoModal = state => state.modal.openInfoModal;
export const selectOpenBetaModal = state => state.modal.openBetaModal;
export const selectOpenFeedbackModal = state => state.modal.openFeedbackModal;
export const selectConfirmationDialog = state => state.modal.openConfirmationModal;
export const selectOpenConfigurationModal = (state) => state.modal.openConfigurationModal;
export const selectOpenGenericModal = (state) => state.modal.openGenericModal;
export const selectOpenGenericSnackbar = (state) => state.modal.openGenericSnackbar;
export const selectOpenNewVersionDialog = (state) => state.modal.openNewVersionDialog;

// Actions
export const actions = { ...slice.actions };

export default slice.reducer;