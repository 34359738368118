import React from 'react';
import { ZoomControl, useMap } from 'react-leaflet';

import { Camera } from '../../graphql/API';
import { WildfireMarkers } from './leafletComponents/WildfireMarkers';
import { CameraMarkers } from './leafletComponents/CameraMarkers';
import { WildfirePolygon, ParsedPolygon } from './leafletComponents/WildfirePolygon';

import Box from '@mui/material/Box';
import SwitchLayerMap from './SwitchLayerMap';
import Map from "./leafletComponents/MapSelector";
import DropDown from '../desktop/DropDown/DropDown';
import { PointerMarker } from './leafletComponents/PointerMarker';
import RulerControl from "react-leaflet-ruler";
import { FullscreenControl } from 'react-leaflet-fullscreen';
import "react-leaflet-fullscreen/styles.css";
import { useSelector } from 'react-redux';
import { selectMobile } from '../../redux/slices/sidebarSlice';
import { MapWildfire } from '../../redux/slices/types/wildfireTypes';
import { ControlPosition } from 'leaflet';

interface MapProps {
  layer: string;
  center: [number, number];
  wildfires: MapWildfire[];
  polygon: ParsedPolygon;
  polygons: ParsedPolygon[];
  cameras: Camera[];
  coordsMarker: number[];
  wildfireType: 'user' | 'public';
  onClickPoly: (id: string) => void;
  onClickWildfire: (id: string) => void;
  onClickCamera: (cameraId: string) => void;
}

function CenterController (props: {center: number[]}) {
  const map = useMap();

  React.useEffect(() => {
    const center = props.center;
		if (center.length > 2 && center[0] && center[1])
			map.flyTo([center[0], center[1]], center[2]+2);
  }, [props.center, map]);

  return null;
}

function MapControls () {
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="absolute"
      bottom="20px"
      right="20px"
      gap="20px"
      alignItems="flex-end"
      sx={{ "& *": { zIndex: 10 } }}
    >
      <SwitchLayerMap />
      <DropDown />
    </Box>
  )
}

export function BaseMap(props: MapProps) {
  const mobile = useSelector(selectMobile);
  const controlPosition: ControlPosition = (mobile ? "topleft" : "topright");

  return (
    <div style={{height: "100%", position: "relative"}}>
      <Map
        layer={props.layer}
        center={[-20.5,-60.5]}
        zoom={4}
      >
        <WildfireMarkers
          wildfireType={props.wildfireType} wildfires={props.wildfires} onClickWildfire={props.onClickWildfire}/>
        <WildfirePolygon
          polygon={props.polygon} onClickPolygon={props.onClickPoly}/>
        <CameraMarkers
          cameras={props.cameras} onClickCamera={props.onClickCamera} />
        <PointerMarker point={props.coordsMarker}/>
        <CenterController center={props.center}/>
        <ZoomControl position={controlPosition} />
        <FullscreenControl position={controlPosition} />
        <RulerControl /> {/* Leaflet-ruler no tiene opciones para cambiar la posición */}
      </Map>
      <MapControls />
    </div>
  );
};

export default BaseMap;