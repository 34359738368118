import React from 'react';
import {useAppSelector, useAppDispatch} from '../../hooks';
import {actions as tourActions} from '../../redux/slices/tourSlice';

type TourResumerProps = {
    wakeUpSteps: Number[];
}

function TourResumer (props: TourResumerProps) : React.FC {
    const dispatch = useAppDispatch();
    const stepIndex = useAppSelector((state) => state.tour.stepIndex);
    const tourActive = useAppSelector((state) => state.tour.tourActive);

    React.useEffect(() => {
        if (tourActive && props.wakeUpSteps.includes(stepIndex)){
            const timeoutTime = stepIndex === 0? 600 : 100;
            setTimeout(() => {
                dispatch(tourActions.resumeTour());
            }, timeoutTime)
        } 
    }, [stepIndex, tourActive, dispatch, props.wakeUpSteps]);

    return React.Fragment;
}

export default TourResumer;