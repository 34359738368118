import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import MuiLoadingButton from "@mui/lab/LoadingButton";

const Button = styled(MuiButton)(({ theme }) => ({
	borderRadius: "40px",
	padding: theme.spacing(1.5, 2),
	fontSize: ".8rem",
	fontWeight: "500",
	lineHeight: "normal",
	textTransform: "none",
	boxShadow: "none",
	height: "fit-content",
}));

const InvertedLoadingButton = styled(MuiButton)(({ theme }) => ({
	borderRadius: "20px",
	padding: theme.spacing(1.5, 2),
	fontSize: ".8rem",
	fontWeight: "500",
	lineHeight: "normal",
	textTransform: "none",
	backgroundColor: "white",
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: "#ececec",
	},
}));

const LightButton = styled(MuiLoadingButton)(({ theme }) => ({
	width: "100%",
	borderRadius: "20px",
	padding: theme.spacing(1, 2),
	fontSize: ".9rem",
	fontWeight: "500",
	textTransform: "none",
	backgroundColor: theme.palette.primary.light,
	color: "#00164E",
	boxShadow: "none",
	"&:hover": {
		backgroundColor: theme.palette.hovered.light,
		boxShadow: "none",
	},
}));

const LinkButton = styled(MuiLink)(({ theme }) => ({
	width: "fit-content",
	color: theme.palette.primary.main,
	fontWeight: "600",
	textTransform: "none",
	textDecoration: "none",
}));

const LoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
	borderRadius: "20px",
	padding: theme.spacing(1.5, 2),
	fontSize: ".8rem",
	fontWeight: "500",
	lineHeight: "normal",
	textTransform: "none",
	boxShadow: "none",
}));

export { Button, LightButton, LinkButton, InvertedLoadingButton, LoadingButton };
