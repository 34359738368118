import { DeleteOutlined } from "@mui/icons-material";
import { DialogActions, DialogContentText, Slide } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import "../../../scss/bottombar.scss";
import { Button } from "../../StyledComponents/Button";
import {
	Dialog, DialogContent, DialogTitle, MobileDialog, MobileDialogTitle
} from "../../StyledComponents/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmptyPhoneNumber({ open, close }) {
	const { t } = useTranslation();
	const mobile = useSelector((state) => state.sidebar.mobile);
	
	function handleClose(result) {
		close();
		//if (result && props.afterClose) props.afterClose();
	}

	return (
		<>
			{mobile ? (
				<MobileDialog
					open={open}
					onClose={() => close()}
					TransitionComponent={Transition}
				>
					<DeleteOutlined
						color="primary"
						sx={{ alignSelf: "center", height: "30px", width: "30px" }}
					/>
					<MobileDialogTitle>{"No tienes cargado un número de telefono"}</MobileDialogTitle>
					<DialogContent>
						<DialogContentText>
						{"Para recibir notificaciones por Whatsapp, debes cargar un número de teléfono."}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => close()}>{t("boton-cancelar")}</Button>
						<Button variant="contained" onClick={() => close()}>
							{t("boton-habilitar")}
						</Button>
					</DialogActions>
				</MobileDialog>
			) : (
				<>
					<Dialog
						open={open}
						onClose={() => close()}
						TransitionComponent={Transition}
					>
						<DialogTitle>{"No tienes cargado un número de telefono"}</DialogTitle>
						<DialogContent>
							<DialogContentText>
							{"Para recibir notificaciones por Whatsapp, debes cargar un número de teléfono."}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={() => handleClose(true)}>
								{t('boton-entendido')}
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
}
