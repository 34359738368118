import React, { useEffect, useRef, useState } from "react";

//Components
import Ajustes from "./Dialogs/Ajustes";
import Feedback from "./Dialogs/Feedback";
import Drawer from "./Drawer/Drawer";
import Panel from "./Panel/Panel";

import { Box } from "@mui/material";

import { useHistory } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slices/sidebarSlice";
import FAQ from "./Dialogs/FAQ";

export default function Container({ mapElement }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const collapsed = useSelector((state) => state.sidebar.collapsed);
	const panelVisible = useSelector((state) => state.sidebar.panelVisible);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

	const [selectedItem, setSelectedItemN] = useState(0);
	const [viewportHeight, setViewportHeight] = useState(window.visualViewport.height / 2);
	const drawerWrapperRef = useRef();

	useEffect(() => {
		window.addEventListener("resize", () => setViewportHeight(window.visualViewport.height / 2));

		return () => {
			window.removeEventListener("resize", () =>
				setViewportHeight(window.visualViewport.height / 2)
			);
		};
	}, []);

	const setSelectedItem = (newValue) => {
		closeDrawer();
		switch (newValue) {
			case 0:
				history.push("/");
				break;
			case 1:
				history.push("/focos-historicos");
				break;
			case 2:
				history.push("/alertas");
				break;
			case 3:
				history.push("/configuracion");
				if (isLoggedIn) {
					if (selectedItem === 3) setSelectedItemN(0);
					return openDrawer();
				}
				break;
			case 4:
				history.push("/feedback");
				toggleDrawer();
				return;
			case 5:
				history.push("/faq");
				toggleDrawer();
				return;
			case 6:
				window.open(
					"https://sof-wildfire-bucket.s3.amazonaws.com/Instructivo+SoF.pdf" /* , "_blank" */
				);
				return;
			case 7:
				history.push("/camaras");
				break;
			default:
				return;
		}
		setSelectedItemN(newValue);
	};

	useEffect(() => {
		switch (history.location.pathname) {
			case "/":
				setSelectedItem(0);
				break;
			case "/focos-historicos":
				setSelectedItem(1);
				break;
			case "/alertas":
				setSelectedItem(2);
				break;
			case "/configuracion":
				setSelectedItem(3);
				break;
			case "/camaras":
				setSelectedItem(7);
				break;

			default:
				break;
		}
		if (history.location.pathname !== "" && history.location.pathname !== "/configuracion")
			closeDrawer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn]);

	const toggleDrawer = () => {
		dispatch(actions.toggleCollapse());
	};

	const closeDrawer = () => {
		dispatch(actions.closeCollapse());
	};
	const openDrawer = () => {
		dispatch(actions.openCollapse());
	};

	const closeDialog = () => {
		switch (selectedItem) {
			case 0:
				history.push("/");
				break;
			case 1:
				history.push("/focos-historicos");
				break;
			case 2:
				history.push("/alertas");
				break;
			case 3:
				history.push("/camaras");
				break;
			default:
				history.push("/");
				return;
		}
		closeDrawer();
	};

	return (
		<>
			{mapElement}
			<Ajustes
				open={history.location.pathname === "/configuracion" && isLoggedIn}
				close={closeDialog}
			/>
			<Feedback open={history.location.pathname === "/feedback"} close={closeDialog} />
			<FAQ open={history.location.pathname === "/faq"} close={closeDialog} />
			{/*   */}
			<Box
				display="flex"
				flexDirection="row"
				gap="20px"
				ref={drawerWrapperRef}
				sx={{
					position: "absolute",
					top: drawerWrapperRef.current
						? `${viewportHeight - drawerWrapperRef.current.clientHeight / 2}px`
						: "30%",
				}}
			>
				<Drawer
					open={collapsed}
					toggleDrawer={toggleDrawer}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>
				<Panel show={panelVisible} />
			</Box>
		</>
	);
}
