import React, {useEffect} from 'react';
import { Snackbar, Button } from '@mui/material';
import {register} from './serviceWorkerRegistration';
import { useTranslation } from 'react-i18next'


const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);
  const {t} = useTranslation();

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={t('actualizar-texto')}
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          {t('boton-actualizar')}
        </Button>
      }
    />
  );
}


export default ServiceWorkerWrapper;