/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Check, ExpandMore, FileDownloadOutlined, LocalFireDepartment, Whatshot
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box, DialogActions,
	DialogContentText, FormControlLabel,
	IconButton,
	MenuItem,
	Select, Tooltip, Typography, useTheme
} from "@mui/material";
import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { List } from "react-virtualized";
import swal from "sweetalert";
import userAccess from "../../../api/user/userAccess.json";
import { downloadKML } from "../../../api/alerts";
import { getZone } from "../../../api/wildfires/utils/getZone";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import RayoVector from "../../../assets/Vector.png";
import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import { actions as wildfireActions, selectReviews } from "../../../redux/slices/wildfireSlice";
import { selectUserData } from "../../../redux/slices/userSlice";
import { getDate, getTime  } from "../../mapa/leafletUtils/FormatDateTime";
import { getCathegoryText, getWildfireColor } from "../../mapa/leafletUtils/CathegoryColor";
import { Button, LoadingButton } from "../../StyledComponents/Button";
import { Dialog, DialogContent, DialogTitle } from "../../StyledComponents/Dialog";
import getCenter from "../../utils/getCenter";
import TourResumer from "../../utils/TourResumer";
import EditarZona from "../Dialogs/EditarZona";
import ConfirmWildfire from "../Dialogs/ConfirmWildfire";
import DatesOverlay from "../Misc/DatesOverlay";
import PanelHeader from "../Panel/PanelHeader";
import { NotLoggedIn } from "../Tabs/MisZonas";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Zona(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let query = useQuery();

	const userData = useSelector(selectUserData).userData;

	const id = query.get("id");
	const fireID = query.get("coords");

	const userReviews = useSelector(selectReviews);
	const alerts = useSelector((state) => state.polygons.alerts);
	//const wildfires = useSelector((state) => state.polygons.wildfires);
	const rays = useSelector((state) => state.polygons.rays);
	const [selectedAlerts, setSelectedAlerts] = React.useState(alerts);

	const [currentScreen, setCurrentScreen] = React.useState(2);
	const polygons = useSelector((state) => state.polygons.polygons);
	const alertsLoading = useSelector((state) => state.polygons.status.alertsLoading);
	const onMove = useSelector((state) => state.map.onMove);
	const wildfireClicked = useSelector((state) => state.map.wildfireClicked);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const selectedFires = useSelector((state) => state.sidebar.selectedFires);
	const [polygon, setPolygon] = React.useState(); //Poligono de la zona

	const [zone, setZone] = React.useState(); //Variable que contiene la zona de cada uno de los focos con reverse geocoding
	let center;

	//Paginación
	const [horasFocos, setHorasFocos] = React.useState(24);
	const startDate = new Date();
	startDate.setTime(startDate.getTime() - horasFocos * 60 * 60 * 1000);

	const textInput = React.useRef();

	// Comienzo de funciones de traer alertas
	const getAlerts = (cat) => {
		if (isLoggedIn && userAccess.alerts.includes(userData.userType))
			if (cat === 0) dispatch(polygonActions.getAlerts({ id, backwardHours: horasFocos }));
			else if (cat === 1) dispatch(polygonActions.getRays({ id, backwardHours: horasFocos }));
			else console.warn("No se ha traido ni rayos ni incendios debido a que el número de pantalla es incorrecto.")
	}

	/*
	React.useEffect(() => {
		if (currentScreen === 0) {
			setSelectedAlerts(selectFireType(alerts, selectedFires));
		}
	}, [userReviews])
	*/

	React.useEffect(() => {
		const fires = selectedFires.map((item) => !item);

		if (currentScreen === 0) {
			setSelectedAlerts(selectFireType(alerts, selectedFires));
			//setSelectValue(48)
		}
		else if (currentScreen === 1) {
			setSelectedAlerts(selectFireType(rays, fires));
			//setSelectValue(24)
		}

	}, [selectedFires, alerts, rays, currentScreen]);

	React.useEffect(() => {
		if (typeof (polygon?.detectRays) === "undefined") return;
		else if (typeof (polygon?.detectWildfires) == "undefined") return;

		if (polygon.detectWildfires || polygon.detectWildfires === null) setCurrentScreen(0);
		else if (polygon.detectRays) setCurrentScreen(1);
		else setCurrentScreen(2);

	}, [polygon?.detectRays, polygon?.detectWildfires])

	// Fin de funciones de traer alertas

	React.useEffect(() => {
		if (isLoggedIn) dispatch(polygonActions.getPolygons()); 
	}, [isLoggedIn]);

	React.useEffect(() => {
		dispatch(mapActions.setWildfireClicked(null));
	}, []);

	React.useEffect(() => {
		getAlerts(currentScreen);
	}, [horasFocos, isLoggedIn, /* Agregado esto */ currentScreen]);

	//Para el comienzo cuando currentScreen arranca en 2
	//React.useEffect(() => {
	//	if (selectedAlerts.length === 0) getAlerts(currentScreen);
	//}, [currentScreen]);

	React.useEffect(() => {
		if (!onMove && polygon && center === undefined)
			dispatch(mapActions.setPolygon([selectedAlerts, polygon]));
	}, [onMove]);

	React.useEffect(() => {
		if (fireID !== null && selectedAlerts.length > 0) {
			let correctfireID = fireID.split(" ");
			correctfireID = correctfireID[0] + "+" + correctfireID[1];
			selectedAlerts.map((elem) => {
				if (elem.date_id === correctfireID) {
					const x = elem.x;
					const y = elem.y;
					dispatch(mapActions.setCoordsMarker([y, x]));
					dispatch(mapActions.setCenter([y, x, 10]));
					dispatch(mapActions.setWildfireClicked(correctfireID));
					dispatch(mapActions.setPolygon([[elem], polygon])); // Center en la posición 4 tiene el incendio seleccionado
				}
			});
		}
	}, [selectedAlerts, fireID]);

	React.useEffect(() => {
		//Se consigue el poligono con el ID del path
		if (polygons.length > 0) {
			polygons.forEach((poly) => {
				if (poly.id === id) setPolygon(poly);
			});
		}
	}, [polygons]);

	React.useEffect(() => {
		if (polygon && center === undefined) {
			if (fireID === null) {
				dispatch(mapActions.setPolygon([selectedAlerts.length ? selectedAlerts : [], polygon]));
				center = getCenter(polygon.coords);
				dispatch(mapActions.setCenter([center[0], center[1], 5]));
			}
		}
	}, [selectedAlerts, polygon]);
	
	async function handleExpanded(newExpanded, x, y, index) {
		const coords = await getZone([x, y]);
		setZone(coords);
		//setExpanded(newExpanded === expanded ? false : newExpanded);
		if (newExpanded === wildfireClicked) {
			setExpandedItem(-1);
			dispatch(mapActions.setWildfireClicked(null));
			//dispatch(mapActions.setPolygon([selectedAlerts ? selectedAlerts : [], polygon]));
		} else {
			setExpandedItem(index);
			dispatch(mapActions.setWildfireClicked(newExpanded));
		}

		if (listRef.current) {
			listRef.current.recomputeRowHeights(index);
			listRef.current.forceUpdateGrid();
		}
	}

	function handleScreen(name) {

		const casos = {
			'focos-de-calor': () => setCurrentScreen(0),
			'focos-de-rayos': () => setCurrentScreen(1),
		}

		if (name) casos[name]();
	}

	//Scrollea en la sidebar el item seleccionado
	React.useEffect(() => {
		if (wildfireClicked !== null && wildfireClicked !== undefined) {
			const clickedIndex = selectedAlerts.findIndex((alert) => alert.date_id === wildfireClicked);
			setExpandedItem(clickedIndex);
			if (clickedIndex >= 0) {
				if (listRef.current) {
					listRef.current.scrollToRow(clickedIndex + 1);
				}

				setTimeout(() => {
					const divIncendio = document.getElementById(wildfireClicked);
					if (divIncendio) divIncendio.scrollIntoView({ block: "center", behavior: "smooth" });
				}, 100);
			}

			if (listRef.current) {
				listRef.current.recomputeRowHeights();
				listRef.current.forceUpdateGrid();
			}
		}
	}, [wildfireClicked]);

	// Nota: por defecto, listRef.current es null hasta que se cargue la lista en el DOM
	// Hay que asegurarse que no es null antes de llamar a los métodos
	const listRef = React.useRef();

	const onValidationClick = (element, answer) => {
		const { date_id, sat, conf, x, y } = element;
		const data = {
			wildfire_id: date_id,
			result: answer,
			origin: "user",
			wildfire_date: new Date(date_id.split('_')[0]),
			user_description: `${userData.name} (${userData.email})`,
			source: sat,
			cathegory: conf,
			x: x,
			y: y
		}
		if (answer === 'Y') {
			openConfirmeWildfireDialog(data);
		} else {
			dispatch(wildfireActions.reviewWildfire({
				...data,
				wildfire_date: null
			}));
		}
	}

	function renderRow(props) {
		const { index, key, style } = props;
		const el = selectedAlerts[index];
		const review = userReviews[el.date_id];
		const reviewed = !!review;
		const finishedReview = review && review.result !== 'D';

		if (!el) return <></>;
		return (
			<>
				<TourResumer wakeUpSteps={[8]}/>
				<Box
					style={style}
					display="flex"
					flexDirection="column"
					sx={{ paddingBottom: "5px" }}
				>
					<Accordion
						className="foco"
						sx={{
							backgroundColor: "#E1E1EC",
							borderRadius: "10px !important",
							padding: "0 5px",
						}}
						elevation={0}
						id={el.date_id}
						expanded={wildfireClicked === el.date_id}
						onChange={() => handleExpanded(el.date_id, el.x, el.y, index)}
						//onClick={() => handleWildfireClick(el)}
						key={key}
					>
						<AccordionSummary
							sx={{
								width: "100%",
								overflow: "hidden",
								padding: 0,
								"& .MuiAccordionSummary-content": {
									width: "100%",
									overflow: "hidden",
									justifyContent: "space-between",
									alignItems: "center",
								},
								"& .MuiAccordionSummary-expandIconWrapper": {
									justifyContent: "center",
									width: "34px",
								},
							}}
							expandIcon={<ExpandMore />}
						>
							<Box
								display="flex"
								flexDirection="row"
								height="3em"
								alignItems="center"
								gap="5px"
							>
								<Whatshot
									sx={{ color: getWildfireColor(el.sat, el.conf) }}
									style={{ width: "auto", height: "80%" }}
								/>
								<Box display="flex" flexDirection="column">
									<Typography whiteSpace="nowrap" fontWeight="600" fontSize=".95rem">
										{ getCathegoryText(el.sat, el.conf) }
									</Typography>
									{wildfireClicked !== el.date_id && (
										<Typography
											whiteSpace="nowrap"
											fontSize=".8rem"
											color="rgba(0,0,0,0.7)"
										>
											{ `${getDate(el.date_id)} a las ${getTime(el.date_id)}` }
										</Typography>
									)}
								</Box>
							</Box>
							{
								reviewed && (
									<Tooltip title={`Confirmado el día ${getDate(review.review_date)} a las ${getTime(review.review_date)}`}>
										<Check style={{ color: 'inherit' }} />
									</Tooltip>
								)
							}
						</AccordionSummary>
						<AccordionDetails>
							{wildfireClicked === el.date_id && (
								<>
									<Box diaplay="flex" flexDirection="column">
										<Typography>
											<b>{t('hora-campo-foco')} </b>
											{getTime(el.date_id)}
										</Typography>
										<Typography>
											<b>{t('fecha-campo-foco')} </b>
											{getDate(el.date_id)}
										</Typography>
										<Typography>
											<b>{t('satelite-campo-foco')} </b> {el.sat}
										</Typography>
										<Typography>
											<b>{t('coords-campo-foco')} </b>
											{`   ${el.y.toFixed(6)}, ${el.x.toFixed(6)} `}
										</Typography>
										<Typography>
											<b>{t('zona-campo-foco')} </b> {zone}
										</Typography>
										{
											!finishedReview && (
												<Box marginTop="5px">
													<Typography style={{ fontSize: "14px", display: 'table', margin: '0 auto' }}>¿Es un incendio?</Typography>
													<Box display="flex" justifyContent="center" gap="10px">
														<Button onClick={(e) => onValidationClick(el, "Y")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">Si</Button>
														<Button onClick={(e) => onValidationClick(el, "N")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">No</Button>
														<Button onClick={(e) => onValidationClick(el, "D")} style={{ padding: "0.75rem", minWidth: "0", width: "4rem" }} variant="outlined">No sé</Button>
													</Box>
												</Box>
											)
										}
									</Box>
								</>
							)}
						</AccordionDetails>
					</Accordion>
				</Box>
			</>


		);
	}

	const [expandedItem, setExpandedItem] = React.useState(-1);

	function rowHeight({ index }) {
		if (expandedItem === index) return 350;
		return 77;
	}

	const [editDialogOpen, setEditDialogOpen] = React.useState(false);
	const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
	const [confirmWildfireOpen, setConfirmWildfireOpen] = React.useState(false);
	const [selectedWildfire, setSelectedWildfire] = React.useState(null);

	React.useEffect(() => {
		if (selectedWildfire !== null) {
			setConfirmWildfireOpen(true);
		} else {
			setConfirmWildfireOpen(false);
		}
	}, [selectedWildfire]);

	const closeEditDialog = () => setEditDialogOpen(false);
	//const openEditDialog = () => setEditDialogOpen(true);

	const closeDownloadDialog = () => setDownloadDialogOpen(false);
	const openDownloadDialog = () => setDownloadDialogOpen(true);

	const openConfirmeWildfireDialog = (data) => {
		setSelectedWildfire(data);
	};
	const closeReviewDialog = () => {
		setSelectedWildfire(null);
	};
	const handleConfirmSubmit = (date) => {
		if (!selectedWildfire) return

		dispatch(wildfireActions.reviewWildfire({
			...selectedWildfire,
			wildfire_date: date.toISOString().slice(0, 16)
		}));
		setSelectedWildfire(null);
	};

	const download = (link) => {
		var element = document.createElement("a");
		element.setAttribute("href", link);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	const handleDownloadKML = async () => {
		const path = await downloadKML(polygon.id);

		ReactGA.event({ category: "DownloadKML", action: userData.sub });

		if (path === "no_wildfires") {
			swal(t("swal-no-encontramos-focos-titulo"), "", "info");
			return;
		}
		const link = "https://kmlrepo.s3.amazonaws.com/" + path;
		download(link);
	};

	const Item = ({ name, item, enabled, id }) => {
		const theme = useTheme();
		const { t } = useTranslation();
		return (
			!enabled ? (
				<Tooltip title='Habilitar desde "Editar Zona"'>
					<Box
						display="flex"
						flexDirection="row"
						gap="5px"
						alignItems="center"
						sx={{
							padding: "4px 6px",
							//padding: "6px 16px 6px 8px",
							/* border: item
								? "1px solid " + theme.palette.primary.light
								: "1px solid #44464E", */
							borderRadius: "6px",
							background: "rgba(73, 69, 79, 0.12)",
							boxSizing: "border-box",
							height: "40px",
							width: "130px",
							fontSize: "14px",
							fontWeight: "500",
							cursor: "default",
						}}
					>
						{name === t('focos-de-calor') ? <LocalFireDepartment style={{ width: "15px", height: "15px" }} color="inherit" /> : <img src={RayoVector} alt="Imagen Rayo" />}
						<span>{name}</span>
					</Box>
				</Tooltip>
			) : (
				<Box
					display="flex"
					flexDirection="row"
					gap="5px"
					alignItems="center"
					sx={{
						padding: "4px 6px",
						border: item
							? "1px solid " + theme.palette.primary.light
							: "1px solid #44464E",
						borderRadius: "6px",
						color: item ? "#00164E" : "#44464E",
						bgcolor: item ? "primary.light" : undefined,
						boxSizing: "border-box",
						height: "40px",
						width: "130px",
						fontSize: "14px",
						fontWeight: "500",
						cursor: "pointer",
					}}
					onClick={() => handleScreen(id)}
				>
					{name === t('focos-de-calor') ? <LocalFireDepartment style={{ width: "15px", height: "15px" }} color="inherit" /> : <img src={RayoVector} alt="Imagen Rayo" />}
					<span>{name}</span>
				</Box>
			)
		);
	};

	const NoFires = () => {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
				textAlign="center"
				padding="30px 0"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="100%"
					gap="20px"
					sx={{
						maxWidth: "90%",
						"& img": {
							width: "80%",
							maxHeight: "50%",
						},
					}}
				>
					<img src={noZoneIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{`${currentScreen === 1 ? t("no-encontramos-rayos") : t("no-encontramos-focos")} ${textInput.current?.firstChild.textContent}`}
					</Typography>
				</Box>
			</Box>
		);
	};

	return (
		<>
			{isLoggedIn === false ? (
				<NotLoggedIn />
			) : (
				<>
					<DatesOverlay startDate={startDate} endDate={new Date()} screen={currentScreen} />
					<DownloadDialog
						open={downloadDialogOpen}
						close={closeDownloadDialog}
						download={handleDownloadKML}
					/>
					<EditarZona open={editDialogOpen} close={closeEditDialog} polygon={polygon} />
					<ConfirmWildfire
						open={confirmWildfireOpen}
						close={closeReviewDialog}
						initialDate={selectedWildfire?.wildfire_date}
						submit={handleConfirmSubmit}
					/>
					<Box sx={{ height: "100%" }} display="flex" flexDirection="column" gap="10px">
						<PanelHeader
							title={polygon?.name}
							action={
								<Box display="flex" flexDirection="row">
									{/* <IconButton onClick={openEditDialog}>
								<EditOutlined />
							</IconButton> */}
								{polygon?.detectWildfires && (
									<IconButton id="tour-11" onClick={openDownloadDialog}>
										<FileDownloadOutlined />
									</IconButton>
								)}
								</Box>
							}
						/>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							id="tour-9"
						>
							<Item
								name={t("focos-de-calor")}
								item={currentScreen === 0}
								enabled={polygon?.detectWildfires || polygon?.detectWildfires === null}
								id="focos-de-calor"
							/>
							<Item
								name={t("focos-de-rayos")}
								item={currentScreen === 1}
								enabled={polygon?.detectRays}
								id="focos-de-rayos"
							/>
						</Box>
							<>
								<FormControlLabel
									control={
										<Select defaultValue={24} /* value={selectValue}  */ onChange={(e) => setHorasFocos(e.target.value)} ref={textInput}>
											<MenuItem value={12}>{`12 ${t('horas')}`}</MenuItem>
											<MenuItem value={24}>{`24 ${t('horas')}`}</MenuItem>
											<MenuItem value={48}>{`48 ${t('horas')}`}</MenuItem>
											<MenuItem value={72}>{`72 ${t('horas')}`}</MenuItem>
											<MenuItem value={120}>{`5 ${t('dias')}`}</MenuItem>
											<MenuItem value={168}>{`7 ${t('dias')}`}</MenuItem>
										</Select>
									}
									label={currentScreen === 1 ? t('rango-de-rayos-zona') : t('rango-de-focos-zona')}
									labelPlacement="start"
									id="tour-10"
									sx={{
										margin: 0,
										justifyContent: "space-between",
										gap: "10px",
									}}
								/>
								{/*Tener cuidado con el tour*/}
									{
										alertsLoading ? (
											<Box
												display="flex"
												flexDirection="column"
												alignItems="center"
												justifyContent="space-between"
												height="100%"
												textAlign="center"
												padding="30px 0"
											/>
										) : (
											selectedAlerts.length > 0 ? (
												<List
													id="tour-8"
													ref={listRef}
													width={300}
													height={360}
													rowHeight={rowHeight}
													rowRenderer={renderRow}
													rowCount={selectedAlerts?.length}
													style={{ height: '100%', width: 'calc(100% + 15px)' }}
												/>
											) : (
												<NoFires />
											)
										)
									}
							</>
					</Box>
				</>
			)}
		</>
	);
}

const DownloadDialog = ({ open, close, download }) => {
	const [loading, setLoading] = React.useState(false);
	const { t } = useTranslation();

	const handleDownload = async () => {
		setLoading(true);
		await download();
		setLoading(false);
		close();
	};

	return (
		<Dialog open={open} onClose={close}>
			<DialogTitle sx={{ padding: "20px 0 10px 0px" }}>{t("descargar-focos-titulo")}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t("descargar-focos-texto")}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={close}>{t("boton-cancelar")}</Button>
				<LoadingButton variant="contained" onClick={handleDownload} loading={loading}>
					{t("boton-descargar-focos")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

// Funcion para filtrar incendios por tipo
function selectFireType(wildfires, selectedfires) {
	let tempIncendios = wildfires;
	for (let i = 0; i <= 6; i++) {
		if (!selectedfires[i]) {
			tempIncendios = tempIncendios.filter((incendio) => {
				if (incendio.sat === "MODIS Terra" || incendio.sat === "MODIS Aqua") {
					if (incendio.conf >= 0 && incendio.conf <= 35) return i !== 4;
					if (incendio.conf > 35 && incendio.conf <= 65) return i !== 3;
					if (incendio.conf > 65 && incendio.conf <= 100) return i !== 2;
				} else {
					if (incendio.conf === 0 || incendio.conf === 10 || incendio.conf === 30) return i !== 0;
					if (incendio.conf === 11 || incendio.conf === 31) return i !== 1;
					if (incendio.conf === 12 || incendio.conf === 32) return i !== 2;
					if (incendio.conf === 13 || incendio.conf === 33 || incendio.conf === 90) return i !== 3;
					if (incendio.conf === 14 || incendio.conf === 34 || incendio.conf === 50) return i !== 4;
					if (incendio.conf === 15 || incendio.conf === 35 || incendio.conf === 20) return i !== 5;
					if (incendio.conf === 101) return i !== 6;
				}
			});
		}
	}
	return tempIncendios;
}
