import {
	ArrowBack,
	ChevronLeft,
	FeedbackOutlined, HelpOutline,
	QuizOutlined, SettingsOutlined, VideoCameraBack
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Avatar, Box,
	Button, Chip, ClickAwayListener, Divider,
	Drawer, Grow, ListItem,
	ListItemIcon,
	ListItemText, MenuItem,
	MenuList,
	Paper,
	Popper,
	styled
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { actions as userActions } from "../../../redux/slices/userSlice";
import logo from "../../../assets/logo.svg";

export default function AppBar() {
	const { t } = useTranslation();
	const actions = useSelector((state) => state.appbar.actions);
	const title = useSelector((state) => state.appbar.title);
	const menuOpen = useSelector((state) => state.appbar.menuOpen);
	const menuItems = useSelector((state) => state.appbar.menuItems);
	const anchorRef = useSelector((state) => state.appbar.menuAnchorRef);
	const history = useHistory();
	const userType = useSelector((state) => state.user?.userData?.userData?.userType);
	const dispatch = useDispatch();

	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleClose = (event) => {
		dispatch(appbar.closeMenu());
	};

	const prevOpen = React.useRef(menuOpen);
	React.useEffect(() => {
		document.getElementById("menuAnchorRef")?.focus();
		prevOpen.current = menuOpen;
	}, [menuOpen]);

	React.useEffect(() => {
		console.log(menuItems)
	}, [menuItems]);

	const [content, setContent] = useState();
	const [shadow, setShadow] = useState("none");

	React.useEffect(() => {
		setContent(document.querySelector("#appbar + *"));
	}, [history.location.pathname]);

	React.useEffect(() => {
		if (!content) return;
		content.addEventListener("scroll", () => {
			if (content.scrollTop > 0) {
				setShadow(undefined);
			} else {
				setShadow("none");
			}
		});

		return () => {
			content.removeEventListener("scroll", () => {
				if (content.scrollTop > 0) {
					setShadow(undefined);
				} else {
					setShadow("none");
				}
			});
		};
	}, [content]);

	const toggleDrawer = () => {
		setDrawerOpen((old) => !old);
	};

	return (
		<>
			<Box
				id="appbar"
				sx={{ backgroundColor: "white", marginBottom: "-1px", zIndex: 10 }}
			>
				<MuiAppBar position="static" color="inherit" sx={{ boxShadow: shadow }}>
					<Toolbar>
						{history.location.pathname.split("/").length > 2 ? (
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2 }}
								onClick={() => {
									const newPath = history.location.pathname.split("/");
									newPath.pop();
									history.push(newPath.join("/"));
								}}
							>
								<ArrowBack />
							</IconButton>
						) : (
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2 }}
								onClick={toggleDrawer}
								id="tour-13"
							>
								<MenuIcon />
							</IconButton>
						)}
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1 }}
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{title}
						</Typography>
						{actions}
						<Menu
							open={menuOpen}
							anchorRef={anchorRef}
							menuItems={menuItems}
							handleClose={handleClose}
						/>
					</Toolbar>
				</MuiAppBar>
			</Box>
			<React.Fragment>
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={toggleDrawer}
					sx={{ "& .MuiPaper-root": { borderRadius: "0px 12px 12px 00px" } }}
				>
					<Box
						sx={{
							width: "90vw",
						}}
						role="presentation"
					>
						<DrawerHeader open={drawerOpen} toggleDrawer={toggleDrawer} />
						{userType === "Cameras" &&
							<>
								<ListItem
									button
									onClick={() => {
										toggleDrawer();
										history.push("/camaras");
									}}
								>
									<ListItemIcon>
										<VideoCameraBack />
									</ListItemIcon>
									<ListItemText sx={{flex: "0 1 auto"}}primary={"Cámaras"} />
									<Chip
										sx={{
											width: "2rem",
											margin: "0 0.5rem",
											//fontSize: "12px",
											"& span": {
												padding: "0",
												fontSize: "10px"
											},
										}}
										label="BETA" color="primary" size="small" />
								</ListItem>

							</>
						}
						<ListItem
							button
							onClick={() => {
								toggleDrawer();
								history.push("/configuracion");
							}}
						>
							<ListItemIcon>
								<SettingsOutlined />
							</ListItemIcon>
							<ListItemText primary={t('configuracion-titulo-menu')} />
						</ListItem>
						<ListItem
							button
							onClick={() => {
								toggleDrawer();
								history.push("/feedback");
							}}
						>
							<ListItemIcon>
								<FeedbackOutlined />
							</ListItemIcon>
							<ListItemText primary={t('titulo-comentarios')} />
						</ListItem>

						<ListItem
							button
							onClick={() => {
								toggleDrawer();
								history.push("/faq");
							}}
						>
							<ListItemIcon>
								<QuizOutlined />
							</ListItemIcon>
							<ListItemText primary={t('titulo-faq')} />
						</ListItem>
						<ListItem
							button
							onClick={() => {
								toggleDrawer();
								window.abrirInstructivo();
							}}
						>
							<ListItemIcon>
								<HelpOutline />
							</ListItemIcon>
							<ListItemText primary={t('instructivo-titulo-menu')} />
						</ListItem>
					</Box>
				</Drawer>
			</React.Fragment>
		</>
	);
}

const Menu = ({ open, anchorRef, menuItems, handleClose }) => {
	return (
		<Popper
			open={open}
			anchorEl={() => document.getElementById("menuAnchorRef")}
			role={undefined}
			placement="bottom-start"
			transition
			disablePortal
			style={{ zIndex: "20" }}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
					}}
				>
					<Paper sx={{ width: "fit-content", maxWidth: "100%" }}>
						<ClickAwayListener onClickAway={handleClose}>
							<MenuList>
								{menuItems.map((item, i) => {
									return (
										<MenuItem
											onClick={item.action}
											key={`appbar-menu-item-${i}`}
											sx={{ justifyContent: "space-between", gap: "20px" }}
										>
											<ListItemText>{item.title}</ListItemText>
											<ListItemIcon sx={{ justifyContent: "flex-end" }}>
												<item.icon fontSize="small" />
											</ListItemIcon>
										</MenuItem>
									);
								})}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};

function DrawerHeader({ open, toggleDrawer }) {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const loggedIn = useSelector((state) => state.user?.isLoggedIn);
	const userData = useSelector((state) => state.user.userData);

	const handleSignOut = () => {
		dispatch(userActions.signOut());
	};

	return (
		<StyledDrawerHeader>
			<Box display={open ? "none" : "initial"}>
				<IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
					<MenuIcon />
				</IconButton>
			</Box>
			<Box
				visibility={open ? "visible" : "hidden"}
				display="flex"
				flexDirection="column"
				gap="15px"
				width="100%"
				sx={{ padding: "0 10px 0 10px" }}
			>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box display="flex" flexDirection="row" gap="10px" alignItems="center">
						<img src={logo} alt="logo" />
						<Typography variant="h1" fontSize="1.5rem" fontWeight="600">
							Satellites on Fire
						</Typography>
					</Box>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeft />
					</IconButton>
				</Box>
				{loggedIn ? (
					<>
						<Box display="flex" flexDirection="row" gap="10px">
							<Avatar
								aria-controls="customized-menu"
								aria-haspopup="true"
								className="avatar-login"
							>
								{userData?.userData.name[0]?.toUpperCase()}
							</Avatar>
							<Box display="flex" flexDirection="column" gap="5px">
								<Typography variant="h1" fontSize="1.1rem" fontWeight="500">
									{userData?.userData.name}
								</Typography>
								<Typography variant="h1" fontSize="1rem" fontWeight="500">
									{userData?.userData.email}
								</Typography>
							</Box>
						</Box>
						<DrawerButton variant="outlined" onClick={handleSignOut}>
							{t("cerrar-sesion")}
						</DrawerButton>
					</>
				) : (
					<DrawerButton
						variant="contained"
						onClick={() => {
							toggleDrawer();
							history.push("/login");
						}}
					>
						{t("iniciar-sesion")}
					</DrawerButton>
				)}
				<Divider sx={{ marginTop: "10px" }} />
			</Box>
		</StyledDrawerHeader>
	);
}

const StyledDrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(1),
	...theme.mixins.toolbar,
}));

const DrawerButton = styled(Button)(({ theme }) => ({
	width: "fit-content",
	borderRadius: "20px",
	padding: theme.spacing(1.3, 2),
	fontSize: ".8rem",
	lineHeight: "initial",
	fontWeight: "200",
	fontVariantCaps: "normal",
	textTransform: "none",
}));
