import React from 'react'
import {Backdrop, CircularProgress} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));


export default function BackdropComponent(props){
    const classes = useStyles();

    return(
            <Backdrop className={classes.backdrop} open={props.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
    );
}