import { Box, Button, styled } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from 'react-i18next'

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: "20px",
		padding: "20px 40px",
		position: "relative",
		//minHeight: "-webkit-fill-available",
	},
}));

const Dialog = (props) => {
	const {t} = useTranslation();
	// additional ready will state if translations are loaded or not
	return (
		<>
			<StyledDialog
				{...props}
				sx={{ "& .MuiPaper-root": { overflow: "visible", marginTop: "50px" } }}
			>
				<Box
					sx={{
						position: "absolute",
						top: "-40px",
						left: "10px",
						color: "white",
					}}
				>
					<Button
						variant="text"
						color="inherit"
						startIcon={<ArrowBackIcon />}
						sx={{ textTransform: "none" }}
						onClick={props.onClose}
					>
						{t("boton-atras")}
					</Button>
					{/* <ArrowBackIcon />
					<Typography variant="span" color="white">
						Atrás
					</Typography> */}
				</Box>
				{props.children}
			</StyledDialog>
		</>
	);
};

const MobileDialog = styled(MuiDialog)(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: "20px",
		padding: "20px 20px",
		position: "relative",
	},
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "bold",
	padding: "20px 0 10px 10px",
}));

const MobileDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	fontSize: "1.3rem",
	fontWeight: "bold",
	padding: "5px 0 10px 0px",
	textAlign: "center",
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
	padding: "20px 0",
	display: "flex",
	flexDirection: "column",
	gap: "10px",
}));

const MobileDialogActions = styled(MuiDialogActions)(({ theme }) => ({
	"& .MuiButton-root": { fontSize: ".9rem" },
}));

export {
	Dialog,
	MobileDialog,
	DialogTitle,
	MobileDialogTitle,
	DialogContent,
	MobileDialogActions,
};
