import {useEffect} from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { actions as polygonActions } from '../../redux/slices/polygonsSlice';
import { actions as tourActions } from '../../redux/slices/tourSlice';
import { useTranslation } from "react-i18next";
import { JoyrideBasicStep } from "../../redux/slices/types/tourTypes";

const Tour = () => {

	const history = useHistory();
	const i18n = useTranslation().i18n;

	const tourKeys : string[] = ["#tour-0", "#tour-1", "#tour-2", "#tour-3", "#tour-4", "#tour-5", "#tour-6", "#tour-7", "#tour-8", "#tour-9", "#tour-10",  "#tour-11", "#tour-12", "#tour-13", "#tour-14", "#tour-15", "#root"];
	
	const { run, stepIndex, steps } = useAppSelector((state) => state.tour);
	const dispatch = useAppDispatch();

	// Tour loader begin
	useEffect(() => {
		const tour : JoyrideBasicStep[] = tourKeys.map((e) => {
			return {
				target: e,
				content: i18n.t(e),
				disableBeacon: true
			}
		});
		dispatch(tourActions.loadTour(tour));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);
	// Tour loader end

	const handleCallback = (data: CallBackProps) => {
		const { action, index, type, status } = data;
		if (EVENTS.STEP_AFTER === type || EVENTS.TARGET_NOT_FOUND === type) {
			const nextIndex: number = index + 1;
			switch (nextIndex) {
				case 0:
					history.push("/alertas");
					break;
				case 1:
					dispatch(tourActions.pauseTour());
					history.push("/alertas/crear");
					break;
				case 6:
					dispatch(polygonActions.startMockModeWithPolygons());
					dispatch(polygonActions.getPolygons());
					dispatch(tourActions.pauseTour());
					history.push("/alertas");
					break;
				case 8:
					dispatch(tourActions.pauseTour());
					history.push("/alertas/zona?id=8002a75a-1ee9-42f0-80ac-ff2d32dc5943");
					break;
				case 12:
					dispatch(tourActions.pauseTour());
					history.push("/alertas");
					break;
				case 14:
					dispatch(tourActions.pauseTour());
					history.push("/configuracion");
					break;
				case 16:
					dispatch(tourActions.pauseTour());
					history.push("/alertas");
					break;
			}
			dispatch(tourActions.changeStep(index + (action === ACTIONS.PREV ? -1 : 1)));
		}
		
		if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && run) || status === STATUS.FINISHED) {
			dispatch(polygonActions.endMockMode());
			dispatch(polygonActions.getPolygons());
			history.push("/alertas");
			dispatch(tourActions.endTour());
		} 
	};

	return (
		<>
			<Joyride
				continuous
				scrollToFirstStep
				showProgress
				disableOverlayClose
				hideBackButton

				callback={handleCallback}
				run={run}
				stepIndex={stepIndex}
				steps={steps}
				floaterProps={{
					disableAnimation: true
				}}

				styles={{
					tooltipContainer: {
						textAlign: "left",
					},
					options: {
						zIndex: 2048,
						primaryColor: '#3E5AA9',
					},
					buttonBack: {
						marginRight: 10,
					},
				}}
				locale={{
					last: i18n.t("tour-last"),
					back: i18n.t("tour-back"),
					close: i18n.t("tour-close"), 
					next: i18n.t("tour-next"),
					skip: i18n.t("tour-skip"),
				}}
			/>
		</>
	);
};
export default Tour;