/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import i18n from '../../../i18nextConf';
import { useTranslation } from 'react-i18next'
import {
	Box,
	Divider,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
	Select,
	MenuItem,
	Checkbox,
	Autocomplete,
	IconButton
} from "@mui/material";
import { PlayCircle } from "@mui/icons-material"
import { Button, LightButton } from "../../StyledComponents/Button";
import { useSelector, useDispatch } from "react-redux";
import {
	selectUserData,
	updateUserConfig,
} from "../../../redux/slices/userSlice";
import { filtrosDeFoco } from "../../utils/Utils";
import WppIcon from "../../../assets/WhatsApp.png";
import MailIcon from "../../../assets/Mail.png";
import PushIcon from "../../../assets/Push.png";
import mapaSatPrev from "../../../assets/mapa-sat-preview.png";
import mapaPolPrev from "../../../assets/mapa-pol-preview.png";
import { useHistory } from "react-router-dom";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { actions as tourActions } from "../../../redux/slices/tourSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import RedeemCode from "../Dialogs/RedeemCode";
import EmptyPhoneNumber from "../Dialogs/EmptyPhoneNumber";
import loginIlustration from "../../../assets/loginIlustration.svg";
import MuiPhoneNumber from 'material-ui-phone-number';
import TourResumer from "../../utils/TourResumer";
import userAccess from "../../../api/user/userAccess.json"


export default function Ajustes({ setShowMap }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	let defaultConfig = useSelector(selectUserData).userData;
	let tillDays;
	if (defaultConfig && defaultConfig.userTypeTill) {
		const parts = defaultConfig.userTypeTill.split("-");
		const tillDate = new Date(parts[0], parts[1] - 1, parts[2]);
		tillDays = datediff(new Date(), tillDate);
	}
	const userType = useSelector(
		(state) => state.user?.userData?.userData?.userType
	);
	const loggingIn = useSelector((state) => state.user.status);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const [datos, setDatos] = useState({
		country: "",
		defaultLayer: "satellite-v9",
		email: "",
		freeTrial: null,
		name: "",
		notifyByEmail: false,
		notifyByPhone: false,
		notifyByPush: false,
		notifyMinuteGap: 10,
		phone: "0",
		state: null,
		surname: null,
		typeOfAlert: null,
		userType: "",
		userTypeTill: "",
		language: "",
	});
	const [errorMessage, setErrorMessage] = useState(false);
	const [focos, setFocos] = useState([]);
	const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);

	const closeRedeemDialog = () => {
		setRedeemDialogOpen(false);
	};

	const openRedeemDialog = () => {
		setRedeemDialogOpen(true);
	};

	const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);

	const closePhoneDialog = () => {
		setPhoneDialogOpen(false);
	};

	const openPhoneDialog = () => {
		setPhoneDialogOpen(true);
	};

	useEffect(() => {
		setShowMap(false);
		dispatch(appbar.setTitle(t("configuracion-titulo-menu")));
		dispatch(appbar.setDefaultActions());
		if (loggingIn !== "succeeded" && !isLoggedIn) return;
		if (!isLoggedIn) return;
		setDatos({
			...defaultConfig,
			language: i18n.language.includes("es") ? "Español" : "English",
			typeOfAlert: JSON.parse(defaultConfig.typeOfAlert),
		});
		if (defaultConfig.typeOfAlert === null) {
			setFocos(filtrosDeFoco.map((filtro) => filtro.value));
		} else {
			setFocos(JSON.parse(defaultConfig.typeOfAlert));
		}
	}, [defaultConfig, loggingIn, isLoggedIn]);

	useEffect(() => {
		if (focos.length === 7) {
			setDatos((old) => {
				return {
					...old,
					typeOfAlert: null,
				};
			});
		} else {
			setDatos((old) => {
				return {
					...old,
					typeOfAlert: focos,
				};
			});
		}
	}, [focos]);

	const handleChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handlePhoneChange = (e) => {
		setDatos({
			...datos,
			phone: e,
		});
	};

	const handleSwitchChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
		if (e.target.name === "notifyByPhone" && e.target.checked)
			if (datos.phone === "0" || datos.phone === "") {
				openPhoneDialog();
				setDatos({ ...datos, [e.target.name]: false })
			}
	};

	const handleLayerChange = (layer) => {
		setDatos({
			...datos,
			defaultLayer: layer,
		});
	};

	const handleFiltrosChange = (e, value) => {
		if (focos.includes(value)) {
			setFocos((oldFocos) => oldFocos.filter((foco) => foco !== value));
		} else {
			setFocos((oldFocos) => [...oldFocos, value]);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!phoneValidation(datos.phone)) return;
		if (datos.language) i18n.changeLanguage(datos.language.includes("Es") ? "es" : "en");
		const sendData = {
			...datos,
			typeOfAlert: JSON.stringify(datos.typeOfAlert),
			language: datos.language.includes("Es") ? "es" : "en",
		};
		dispatch(updateUserConfig(sendData));
		history.push("/");
	};

	const playTour = () => {
		history.push('/alertas')
		dispatch(polygonActions.startMockModeNoPolygons());
		dispatch(polygonActions.getPolygons());
		dispatch(tourActions.startTour())
	}

	const phoneValidation = (number) => {
		var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);
		if (pattern.test(number) === false) {
			setErrorMessage(true);
			return false;
		} else if (number.length > 1 && number.length < 8) {
			setErrorMessage(true);
			return false;
		}
		return true;
	}

	if (!isLoggedIn) return <NotLoggedIn />;
	return (
		<>
			<TourResumer wakeUpSteps={[14]} />
			<RedeemCode open={redeemDialogOpen} close={closeRedeemDialog} />
			<EmptyPhoneNumber open={phoneDialogOpen} close={closePhoneDialog} />
			<Box
				component="form"
				onSubmit={handleSubmit}
				display="flex"
				flexDirection="column"
				gap="30px"
				alignSelf="center"
				padding="20px 10px"
				width="100%"
				sx={{ flexGrow: 1, minHeight: 0, overflowY: "auto" }}
			>
				<Box display="flex" flexDirection="column" gap="20px">
					<Typography variant="span" color="#707176" fontSize="1rem">
						{t('datos-personales-typography-configuracion-general')}
					</Typography>
					<Box
						display="flex"
						flexDirection="column"
						gap="10px"
						width="95%"
						alignSelf="center"
					>
						<TextField
							name="name"
							label={t("campo-nombre")}
							variant="standard"
							value={datos?.name}
							onChange={handleChange}
							required
						/>
						<TextField
							disabled
							name="email"
							label={t("campo-mail")}
							variant="standard"
							value={datos?.email}
							onChange={handleChange}
							required
						/>
					</Box>
				</Box>

				<Box display="flex" flexDirection="column" gap="20px">
					<Typography variant="span" color="#707176" fontSize="1rem">
						{t('vista-predeterminada-typography-configuracion-general')}
					</Typography>
					<Box
						display="flex"
						flexDirection="column"
						gap="10px"
						width="95%"
						alignSelf="center"
					>
						<DefaultMapItem
							selected={datos?.defaultLayer !== "streets-v11"}
							handleLayerChange={() => handleLayerChange("satellite-v9")}
							title={t('layer-satelital')}
							img={mapaSatPrev}
						/>
						<DefaultMapItem
							selected={datos?.defaultLayer === "streets-v11"}
							handleLayerChange={() => handleLayerChange("streets-v11")}
							title={t('layer-politica')}
							img={mapaPolPrev}
						/>
					</Box>
				</Box>

				<Box display="flex" flexDirection="column" gap="20px">
					<Typography variant="span" color="#707176" fontSize="1rem">
						{t('idioma')}
					</Typography>
					<Box sx={{ width: '100%' }}>
						<Autocomplete
							blurOnSelect
							options={["Español", "English"]}
							style={{ width: 300 }}
							value={datos?.language}
							defaultValue={i18n.language.includes("es") ? "Español" : "English"}
							disableClearable
							onChange={(event, newValue) => {
								setDatos({
									...datos,
									language: newValue,
								});
							}}
							renderInput={(params) => (
								<TextField {...params} fullWidth />
							)}
						/>
					</Box>
				</Box>


				<Box display="flex" flexDirection="column" gap="10px">
					<Box
						display="flex"
						flexDirection="row"
						alignItems="flex-end"
						justifyContent="space-between"

					>
						<Typography
							sx={{
								fontSize: "1.3rem",
								fontWeight: "500",
							}}
						>
							{t('titulo-configuracion-alertas')}
						</Typography>
						{tillDays && tillDays <= 90 && userAccess.alerts.includes(userType) ? (
							<Typography color="#BA1B1B" fontSize=".9rem">
								{tillDays} {t('dias-restantes')}
							</Typography>
						) : (
							<></>
						)}
					</Box>
					<Divider sx={{ width: "100%", alignSelf: "center" }} />
					{userAccess.alerts.includes(userType) ? (
						<Box
							display="flex"
							flexDirection="column"
							gap="30px"
							sx={{ marginTop: "10px" }}
						>
							<Box display="flex" flexDirection="column" gap="20px" id="tour-14">
								<Typography variant="span" color="#707176" fontSize="1rem">
									{t('preferencias-typography-configuracion-alertas')}
								</Typography>
								<Box
									display="flex"
									flexDirection="column"
									gap="20px"
									width="95%"
									alignSelf="center"
								>
									<Box display="flex" gap="10px">
										<img src={MailIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
										<FormControlLabel
											control={
												<Switch
													name="notifyByEmail"
													color="primary"
													checked={datos?.notifyByEmail}
													onChange={handleSwitchChange}
												/>
											}
											label={t('switch-notificaciones-por-correo')}
											labelPlacement="start"
											sx={{
												width: "100%",
												justifyContent: "space-between",
												margin: 0,
												"& .MuiFormControlLabel-label": {
													marginRight: "10px",
												},
											}}
										/>
									</Box>
									{userAccess.alerts.includes(userType) && (
										<Box display="flex" gap="10px">
											<img src={WppIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
											<FormControlLabel
												control={
													<Switch
														name="notifyByPhone"
														color="primary"
														checked={datos?.notifyByPhone}
														onChange={handleSwitchChange}
													/>
												}
												label={t('switch-notificaciones-wpp')}
												labelPlacement="start"
												sx={{
													width: "100%",
													justifySelf: "center",
													justifyContent: "space-between",
													margin: 0,
													"& .MuiFormControlLabel-label": {
														marginRight: "10px",
													},
												}}
											/>
										</Box>
									)}
									<Box display="flex" gap="10px">
										<img src={PushIcon} alt="notificaciones por sms" height="25px" width="25px" style={{ alignSelf: "center" }} />
										<FormControlLabel
											control={
												<Switch
													name="notifyByPush"
													color="primary"
													checked={datos?.notifyByPush}
													onChange={handleSwitchChange}
												/>
											}
											label={
												<>
													<Typography>{t('switch-notificaciones-push')}</Typography>
													<Typography fontSize=".8rem" color="#5c5c5c">
														{t('subtitulo-switch-notificaciones-push')}
													</Typography>
												</>
											}
											labelPlacement="start"
											sx={{
												width: "100%",
												justifyContent: "space-between",
												margin: 0,
												"& .MuiFormControlLabel-label": {
													marginRight: "10px",
												},
											}}
										/>
									</Box>
									<FormControlLabel
										control={
											<Select
												name="notifyMinuteGap"
												value={
													datos?.notifyMinuteGap ? datos?.notifyMinuteGap : 0
												}
												onChange={handleChange}
											>
												<MenuItem value={0}>{t('espera-ultima-notificacion-no-esperar')}</MenuItem>
												<MenuItem value={10}>{`10 ${t('minutos')}`}</MenuItem>
												<MenuItem value={20}>{`20 ${t('minutos')}`}</MenuItem>
												<MenuItem value={30}>{`30 ${t('minutos')}`}</MenuItem>
												<MenuItem value={60}>{`1 ${t('hora')}`}</MenuItem>
												<MenuItem value={120}>{`2 ${t('horas')}`}</MenuItem>
											</Select>
										}
										label={t('espera-ultima-notificacion')}
										labelPlacement="start"
										sx={{
											margin: 0,
											justifySelf: "center",
											alignSelf: "flex-start",
											"& .MuiFormControlLabel-label": {
												marginRight: "10px",
											},
										}}
									/>
									{ userAccess.alerts.includes(userType) && (
										<>
											<FormControlLabel
												control={
													<Box id="tour-15">
														<MuiPhoneNumber
															autoFormat={false}
															defaultCountry={'ar'}
															onChange={handlePhoneChange}
															name="phone"
															value={datos?.phone === "0" ? "" : datos?.phone}
															regions={'america'}
														/>
														{errorMessage && <Typography color="red" fontSize="0.9rem">{t('numero-invalido')}</Typography>}
													</Box>
												}
												label={
													<>
														<Typography>{t('numero-de-telefono')}</Typography>
														<Typography fontSize=".8rem" color="#5c5c5c">
															(+54 9 11 34084691)
														</Typography>
													</>
												}
												labelPlacement="start"
												sx={{
													justifySelf: "start",
													//width: "100%",
													justifyContent: "flex-end",
													margin: 0,
													"& .MuiFormControlLabel-label": {
														marginRight: "10px",
													},
												}}
											/>
											<Box>
												<FormControlLabel
													control={
														<IconButton
															variant="contained"
															size="large"
															aria-label="Tutorial"
															onClick={() => playTour()}
														>
															<PlayCircle sx={{ width: "40px", height: "40px" }} />
														</IconButton>
													}
													label={
														<>
															<Typography>{t("tutorial-alertas")}</Typography>
														</>
													}
													labelPlacement="start"
													sx={{
														width: "100%",
														justifyContent: "space-between",
														justifySelf: "start",
														margin: 0,
														"& .MuiFormControlLabel-label": {
															marginRight: "10px",
														},
													}}
												/>
											</Box>
										</>
									)}

								</Box>

							</Box>

							<Box display="flex" flexDirection="column" gap="20px">
								<Typography variant="span" color="#707176" fontSize="1rem">
									{t('focos-a-notificar-typography-configuracion-alertas')}
								</Typography>
								<Box
									display="grid"
									gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
									width="95%"
									alignSelf="center"
								>
									{filtrosDeFoco.map((item, i) => {
										return (
											<FormControlLabel
												key={`foco-${item.label}`}
												control={
													<Checkbox
														style={{
															color: item.color,
														}}
														edge="start"
														checked={
															focos === null || focos.includes(item.value)
														}
														disableRipple
														onChange={(e) => handleFiltrosChange(e, item.value)}
													/>
												}
												label={t(item.label)}
												labelPlacement="end"
												sx={{ margin: 0 }}
											/>
										);
									})}
								</Box>
							</Box>
						</Box>
					) : (
						<>
							<LightButton onClick={openRedeemDialog}>
								{t('ingresar-codigo-suscripcion')}
							</LightButton>
						</>
					)}
				</Box>
				<Button
					variant="contained"
					type="submit"
					sx={{ width: "100%", marginTop: "auto" }}
				>
					{t('boton-guardar-cambios')}
				</Button>
			</Box>
		</>
	);
}

const DefaultMapItem = ({ title, img, selected, handleLayerChange }) => {
	return (
		<Box
			display="flex"
			flexDirection="row"
			gap="20px"
			alignItems="center"
			justifyContent="space-between"
			sx={{
				padding: "0 0 0 15px",
				backgroundColor: "#E1E1EC",
				width: "100%",
				border: selected ? "2px solid #3E5AA9" : "2px solid transparent",
				borderRadius: "10px",
				overflow: "hidden",
				height: "60px",
				cursor: "pointer",
			}}
			onClick={handleLayerChange}
		>
			<Typography variant="span" color="#1B1B1F" fontSize="1.1rem">
				{title}
			</Typography>
			<img src={img} alt="preview mapa satelital" width="60px" />
		</Box>
	);
};

function datediff(first, second) {
	return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
}

const NotLoggedIn = () => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="space-between"
			height="100%"
			textAlign="center"
			padding="30px 0"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="100%"
				gap="20px"
				sx={{
					maxWidth: "90%",
					"& img": {
						width: "60%",
						maxHeight: "50%",
					},
				}}
			>
				<img src={loginIlustration} alt="ilustración" />
				<Typography variant="span" color="#1B1B1F" fontSize="1rem">
					{t("no-logueado-configuracion")}
				</Typography>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "90%" }}
				onClick={() => history.push("/login")}
			>
				{t("iniciar-sesion")}
			</Button>
		</Box>
	);
};
