import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/slices/sidebarSlice.js";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import { useHistory } from "react-router-dom";

export default function PanelHeader({ title, action }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const handleBack = () => {
		dispatch(polygonActions.deleteAlerts());
		if (history.location.pathname.split("/").length > 2) {
			const newPath = history.location.pathname.split("/");
			newPath.pop();
			history.push(newPath.join("/"));
		} else {
			dispatch(actions.toggleCollapse());
		}
	};
	return (
		<Box display="flex" flexDirection="row" justifyContent="space-between">
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				gap="5px"
				overflow="hidden"
			>
				<IconButton onClick={handleBack}>
					<ArrowBack sx={{ color: "black" }} />
				</IconButton>
				<Typography
					variant="h2"
					fontSize="1.2rem"
					fontWeight="bold"
					lineHeight="initial"
					whiteSpace="nowrap"
					overflow="hidden"
					textOverflow="ellipsis"
					className=".my-first-step" 
				>
					{title}
				</Typography>
			</Box>
			{action}
		</Box>
	);
}
