import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Slide,
	Typography,
	Button,
} from "@mui/material";
import { actions as modalActions } from "../../../redux/slices/modalsSlice";
import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../../StyledComponents/Dialog";
import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import i18n from '../../../i18nextConf';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function FAQ({ open, close }) {
	const { t } = useTranslation();

	const handleClose = async () => {
		close();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="lg"
				scroll="body"
				TransitionComponent={Transition}
			>
				<DialogTitle>{t("titulo-faq")}</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "40px",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
						<Box display="flex" flexDirection="column" gap="20px" alignItems="center">
							<Typography variant="span" color="#707176" fontSize="1rem" width="95%">
								{t("texto-faq")}
							</Typography>

							<Box
								display="flex"
								flexDirection="row"
								gap="20px"
								alignItems="center"
								width="95%"
								sx={{ flexWrap: "wrap" }}
							>
								<Typography variant="span" color="#000000" fontSize="1rem">
									{t('texto-wpp-faq')}
								</Typography>
								<Box display="flex" gap="10px" alignItems="center" width="fit-content">
									<svg
										onClick={()=> window.open("https://wa.me/5491165715780?text=Hola!%20tengo%20una%20consulta...", "_blank")}
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M17.415 14.3821C17.117 14.2331 15.656 13.5151 15.384 13.4151C15.112 13.3161 14.914 13.2671 14.715 13.5651C14.517 13.8611 13.948 14.5311 13.775 14.7291C13.601 14.9281 13.428 14.9521 13.131 14.8041C12.834 14.6541 11.876 14.3411 10.741 13.3291C9.858 12.5411 9.261 11.5681 9.088 11.2701C8.915 10.9731 9.069 10.8121 9.218 10.6641C9.352 10.5311 9.515 10.3171 9.664 10.1441C9.813 9.97005 9.862 9.84605 9.961 9.64705C10.061 9.44905 10.011 9.27605 9.936 9.12705C9.862 8.97805 9.268 7.51505 9.02 6.92005C8.779 6.34105 8.534 6.42005 8.352 6.41005C8.178 6.40205 7.98 6.40005 7.782 6.40005C7.584 6.40005 7.262 6.47405 6.99 6.77205C6.717 7.06905 5.95 7.78805 5.95 9.25105C5.95 10.7131 7.014 12.1261 7.163 12.3251C7.312 12.5231 9.258 15.5251 12.239 16.8121C12.949 17.1181 13.502 17.3011 13.933 17.4371C14.645 17.6641 15.293 17.6321 15.805 17.5551C16.375 17.4701 17.563 16.8361 17.811 16.1421C18.058 15.4481 18.058 14.8531 17.984 14.7291C17.91 14.6051 17.712 14.5311 17.414 14.3821H17.415ZM11.993 21.7851H11.989C10.2184 21.7854 8.48037 21.3094 6.957 20.4071L6.597 20.1931L2.855 21.1751L3.854 17.5271L3.619 17.1531C2.62914 15.5774 2.1053 13.7538 2.108 11.8931C2.11 6.44305 6.544 2.00904 11.997 2.00904C14.637 2.00904 17.119 3.03904 18.985 4.90705C19.9054 5.82363 20.6349 6.91361 21.1313 8.11395C21.6277 9.31428 21.8811 10.6011 21.877 11.9001C21.875 17.3501 17.441 21.7851 11.993 21.7851ZM20.405 3.48804C19.3032 2.37897 17.9922 1.49958 16.5481 0.900842C15.1039 0.302105 13.5553 -0.00407625 11.992 4.09776e-05C5.438 4.09776e-05 0.102 5.33505 0.1 11.8921C0.096963 13.9788 0.644375 16.0294 1.687 17.8371L0 24.0001L6.304 22.3461C8.04788 23.2962 10.0021 23.794 11.988 23.7941H11.993C18.547 23.7941 23.883 18.4591 23.885 11.9011C23.8898 10.3383 23.5848 8.79015 22.9874 7.34608C22.3901 5.90201 21.5124 4.59072 20.405 3.48804Z"
											fill="#3E5AA9"
										/>
									</svg>
									<Button variant="outlined" onClick={()=> window.open("https://wa.me/5491165715780?text=Hola!%20tengo%20una%20consulta...", "_blank")}>{t('contact-us')}</Button>
								</Box>
							</Box>
						</Box>

						<Box display="flex" flexDirection="column" gap="20px">
							<Question
								question={t("q1-fac")}
								answer={t("a1-fac")}
							/>
							<Question
								question={t("q2-fac")}
								answer={t("a2-fac")}
								id={2}
							/>
							<Question
								question={t("q3-fac")}
								answer={t("a3-fac")}
							/>
							<Question
								question={t("q4-fac")}
								answer={t("a4-fac")}
								id={4}
							/>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}

function Question({ question, answer, id }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const español = i18n.language.includes("es");
	return (
		<Accordion
			sx={{
				backgroundColor: "#E1E1EC",
				borderRadius: "12px !important",
				padding: "1rem 1% !important",
				margin: "0 !important",
			}}
			elevation={0}
		>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography variant="h7" color="#000">
					{/* {t(`${question}`)} */}
					{question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box display="flex" flexDirection="column" width="100%" gap="20px">
					
					<Typography variant="body1">{answer}</Typography>
					{(id === 2 && <Typography className="link" onClick={()=> window.open("https://www.youtube.com/watch?v=wC0LV76PR3U", "_blank")}variant="body1">{t('ver-tuto')}</Typography>)}
					{(id === 4 && español) && 
						<Button sx={{width:"6rem"}} onClick={(e) => { dispatch(modalActions.toggleInfoModal()); e.stopPropagation() }} variant="contained" endIcon={<InfoOutlined />}>
							Abrir
						</Button>
					}

					{/* <Box display="flex" gap="20px" sx={{ alignSelf: "flex-end" }}>
						<Button>No me sirvió</Button>
						<Button variant="contained">Me sirvió</Button>
					</Box> */}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
