export function getMockAlerts(category) {
    const dateString = new Date().toISOString().slice(0,-5);
    if (category === "W") return [
        {
            date_id: dateString + '+00:00_c0191e05-5a9d-4c3b-a3de-81a0db673a0e',
            cat: 'W',
            conf: 30,
            sat: 'noaa-goes16',
            x: -68.37067413330078,
            y: -31.90079116821289,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_8f021361-7c81-4e4c-9efe-5840df9a52ff',
            cat: 'W',
            conf: 32,
            sat: 'noaa-goes16',
            x: -67.6832504272461,
            y: -31.21154022216797,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_55c425a0-b852-4681-a678-42ef35fdd9d8',
            cat: 'W',
            conf: 30,
            sat: 'noaa-goes16',
            x: -60.783447265625,
            y: -34.846927642822266,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_46e5b482-c285-4aed-a702-e991b3f1a5ba',
            cat: 'W',
            conf: 30,
            sat: 'noaa-goes16',
            x: -65.33506774902344,
            y: -36.01676940917969,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_03153f82-d62c-4ff8-aae3-1c1d2bd8b820',
            cat: 'W',
            conf: 15,
            sat: 'noaa-goes16',
            x: -62.547950744628906,
            y: -33.82799530029297,
            __typename: 'UserWildfires'
        }]
    else if (category === "L") return [
        {
            date_id: dateString + '+00:00_dafa205c-ec40-4e08-97a7-22e54c355d74',
            cat: 'L',
            conf: 101,
            sat: 'noaa-goes16',
            x: -61.84038543701172,
            y: -32.713932037353516,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_57bd3a83-d097-46a5-be8b-0927b665310b',
            cat: 'L',
            conf: 101,
            sat: 'noaa-goes16',
            x: -61.5154914855957,
            y: -32.923377990722656,
            __typename: 'UserWildfires'
        },
        {
            date_id: dateString + '+00:00_3d145e73-af0f-437a-a2df-48caaf2be60b',
            cat: 'L',
            conf: 101,
            sat: 'noaa-goes16',
            x: -61.195091247558594,
            y: -32.90873718261719,
            __typename: 'UserWildfires'
        }
    ];
}

const exported = { getMockAlerts };

export default exported;