import { Polyline } from "react-leaflet";

export interface ParsedPolygon {
  id: string;
  coords: [number, number][] | undefined;
}

export function WildfirePolygon (props: {
    polygon: ParsedPolygon, 
    onClickPolygon: (id: string) => void;
  }) {
  
  const polygon = props.polygon;
  const coords = polygon.coords?.concat([polygon.coords[0]]) || [];
  if (polygon.coords && polygon.coords.length < 3) return null;

  return (
    <Polyline
      key={polygon.id}
      positions={coords}
      weight={4}
      opacity={1}
      fillOpacity={0}
      color="white"
      />
  );
}

export default WildfirePolygon;