/* eslint-disable react-hooks/exhaustive-deps */
import {
	Add,
	EditOutlined,
	NotificationImportantOutlined,
	NotificationsActiveOutlined,
	NotificationsOffOutlined,
} from "@mui/icons-material";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import MuiListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loginIlustration from "../../../assets/loginIlustration.svg";
import notPremiumIlustration from "../../../assets/notPremiumIlustration.svg";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { actions as polygonsActions } from "../../../redux/slices/polygonsSlice";
import { actions as settings } from "../../../redux/slices/settingsSlice";
import { actions as tourActions } from "../../../redux/slices/tourSlice";
import NotificationsDialog from "../../modals/NotificationsDialog";
import TourResumer from '../../utils/TourResumer';
import { Button } from "../../StyledComponents/Button";
import userAccess from "../../../api/user/userAccess.json";


export default function MisZonas() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const userType = useSelector((state) => state.user?.userData?.userData?.userType);
	const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const polygons = useSelector((state) => state.polygons.polygons);
	const notificationsEnabled = useSelector(
		(state) => state.settings.notificationsEnabled
	);
	const polygonStatus = useSelector((state) => state.polygons.status.createStatus)

	const [notiButtonState, setNotiButtonState] = useState(false);

	useEffect(() => {
		dispatch(appbar.setTitle(t('alertas')));
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px" >
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						aria-label="menu"
						onClick={() => history.push("/alertas/crear")}
					>
						<Add />
					</IconButton>
					<IconButton size="large" edge="end" color="inherit">
						{notiButtonState ? <NotificationImportantOutlined /> : <></>}
					</IconButton>
					{/* <IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => window.abrirInstructivo()}
					>
						<InfoOutlined />
					</IconButton> */}
				</Box>
			)
		);
	}, []);

	useEffect(() => {

		if (!(history.location.pathname === "/alertas" &&
			userAccess.alerts.includes(userType) &&
			!localStorage.getItem("tour") &&
			polygons.length === 0)) return;

		localStorage.setItem("tour", "true");

		dispatch(tourActions.openModal());

	}, [history.location.pathname, polygons, userType]);

	useEffect(() => {
		if (isLoggedIn) dispatch(polygonsActions.getPolygons());
		//eslint-disable-next-line
	}, [isLoggedIn]);

	useEffect(() => {
		dispatch(settings.checkNotificationsEnabled());
	}, [dispatch]);

	useEffect(() => {
		if (polygonStatus === 'succeded')
			if (!notificationsEnabled) {
				setTimeout(function () {
					setNotificationsDialogOpen(true)
				}, 2000)
				dispatch(polygonsActions.clearStatus());
			}
		//eslint-disable-next-line
	}, [polygonStatus]);

	useEffect(() => {
		if (notificationsEnabled) setNotiButtonState(true);
		else setNotiButtonState(false);
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px">
					{isLoggedIn && (userAccess.alerts.includes(userType)) ? (
						<>
							<IconButton
								size="large"
								edge="end"
								color="inherit"
								aria-label="menu"
								onClick={() => history.push("alertas/crear")}
							>
								<Add />
							</IconButton>
							<IconButton
								size="large"
								edge="end"
								color="inherit"
								id="tour-12"
								onClick={
									notificationsEnabled
										? () => dispatch(settings.disableAlerts())
										: () => dispatch(settings.enableAlerts())
								}
							>
								{notificationsEnabled ? (
									<NotificationsActiveOutlined />
								) : (
									<NotificationsOffOutlined />
								)}
							</IconButton>
						</>
					) : (
						<></>
					)}
					{/* <IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={() => window.abrirInstructivo()}
					>
						<InfoOutlined />
					</IconButton> */}
				</Box>
			)
		);
	}, [notificationsEnabled, isLoggedIn]);

	const handleSendToForm = () => {
		history.push("/form");
	}

	const NotPremium = () => {
		const { t } = useTranslation();
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				gap="10px"
				overflow="hidden"
				sx={{ marginTop: "20px", height: "100%" }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="100%"
					textAlign="center"
					gap="20px"
					sx={{
						"& img": {
							width: "90%",
							maxHeight: "40%",
						},
						"& span.MuiTypography-root": {
							width: "95%",
						},
					}}
				>
					<img src={notPremiumIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t('rellenar-form-texto')}
					</Typography>
					<Button
						variant="contained"
						sx={{ width: "100%" }}
						onClick={() => handleSendToForm()}
					>
						{t('boton-rellenar-form')}
					</Button>
				</Box>
			</Box>
		);
	};

	return (
		<>
			<Box
				sx={{ height: "100%" }}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<NotificationsDialog
					open={notificationsDialogOpen}
					setOpen={setNotificationsDialogOpen}
				/>
				{isLoggedIn ? (
					<>
						<TourResumer wakeUpSteps={[0, 6, 12, 16]} />
						{userAccess.alerts.includes(userType) ? (
							<Box
								id="tour-6"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								gap="10px"
								overflow="hidden"
								sx={{ marginTop: "20px", height: "100%", width: "90%" }}
							>
								{Object.values(polygons).length ? (
									<Box
										display="flex"
										flexDirection="column"
										gap="10px"
										overflow="hidden"
									>
										<Typography
											variant="span"
											color="#44464E"
											fontSize=".9rem"
											textAlign="center"
										>
											{t('typography-alertas')}
										</Typography>

										<List id="tour-7" sx={{ overflowY: "auto" }}>
											{Object.values(polygons).map((item, i) => {
												return <ListItem key={`zone-${item.id}`} item={item} />;
											})}
										</List>
									</Box>
								) : (
									<NoZones />
								)}
								{/* {userType === "Cameras" &&
									<Box position="relative" alignSelf="end">
										<Button onClick={() => history.push("/camaras")} style={{ width: "6rem", alignSelf: "end", margin: "10px" }} variant="outlined" startIcon={<VideoCameraBack />}>
											Camaras
										</Button>
											<Chip
												sx={{
													position: "absolute",
													//top: "-0.5rem",
													right: "0rem",
													width: "40px",
													fontSize: "10px",
													"& span": {
														padding: "0"
													},
												}}
												label="BETA" color="primary" size="small" />
									</Box>
								} */}
							</Box>
						) : (
							<NotPremium />
						)
						}
					</>
				) : (
					<NotLoggedIn />
				)}
			</Box>
		</>
	);
}

const ListItem = ({ item }) => {
	const history = useHistory();
	return (
		<>
			<MuiListItem
				secondaryAction={
					<IconButton
						onClick={() => history.push("/alertas/editar?id=" + item.id)}
					>
						<EditOutlined sx={{ color: "primary.main" }} />
					</IconButton>
				}
				disablePadding
			>
				<ListItemButton
					onClick={() => history.push("/alertas/zona?id=" + item.id)}
				>
					<ListItemText primary={item.name} secondary={item.description} />
				</ListItemButton>
			</MuiListItem>
			<Divider />
		</>
	);
};

export const NotLoggedIn = () => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="space-between"
			height="100%"
			textAlign="center"
			padding="30px 0"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="100%"
				gap="20px"
				sx={{
					maxWidth: "90%",
					"& img": {
						width: "60%",
						maxHeight: "50%",
					},
				}}
			>
				<img src={loginIlustration} alt="ilustración" />
				<Typography variant="span" color="#1B1B1F" fontSize="1rem">
					{t("no-logueado-alertas")}
				</Typography>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "90%" }}
				onClick={() => history.push("/login")}
			>
				{t("iniciar-sesion")}
			</Button>
		</Box>
	);
};

const NoZones = () => {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
				textAlign="center"
				padding="30px 0"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="100%"
					gap="20px"
					sx={{
						maxWidth: "90%",
						"& img": {
							width: "80%",
							maxHeight: "50%",
						},
					}}
				>
					<img src={noZoneIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t("no-zonas-texto")}
					</Typography>
				</Box>
				<Button
					id="tour-0"
					variant="contained"
					sx={{ width: "90%" }}
					startIcon={<Add />}
					onClick={() => history.push("alertas/crear")}
				>
					{t('agregar-zona')}
				</Button>
				{/* <Box display="flex" justifyContent="space-between" gap="30px">
					<LightButton
						variant="contained"
						startIcon={<PlayCircle />}
						sx={{ width: "100%" }}
						onClick={() => dispatch(tourActions.startTour())}
					>
						Tutorial
					</LightButton>
					<Button
						id="tour-0"
						variant="contained"
						sx={{ width: "90%" }}
						startIcon={<Add />}
						onClick={() => history.push("alertas/crear")}
					>
						{t('agregar-zona')}
					</Button>
				</Box> */}
			</Box>
		</>
	);
};
