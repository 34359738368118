import { Marker } from "react-leaflet";
import { Icon } from "leaflet";

import imageCamera from "../../../assets/Camara.png";
import { Camera } from "../../../graphql/API";

function CameraMarker (props: {camera: Camera, onClickCamera: (cameraId: string) => void}) {

  const camera = props.camera;
  
  const icon = new Icon({
    iconUrl: imageCamera,
    iconSize: [50, 50]
  })

  return (
    <Marker
      position={[camera.y || 0, camera.x || 0]}
      key={camera.cameraId || ""}
      icon={icon}
      eventHandlers={{
        click: () => props.onClickCamera(camera.cameraId || "")
      }}
      >
    </Marker>
  )
}

export function CameraMarkers (props: {cameras: Camera[], onClickCamera: (cameraId: string) => void}) {
  return (
    <>
      {props.cameras.map((camera: Camera) =>
        <CameraMarker
          key={camera.cameraId || ""}
          camera={camera}
          onClickCamera={props.onClickCamera}
        />
      )}
    </>
  );
}

export default CameraMarkers;