/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { Box, TextField, Slider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import { useDispatch } from "react-redux";
import { actions as wildfireActions } from "../../../redux/slices/wildfireSlice";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { Button, LightButton } from "../../StyledComponents/Button";
import { Today } from "@mui/icons-material";
import { MobileDialog } from "../../StyledComponents/Dialog";
import DatesOverlay from "../Misc/DatesOverlay";

export default function FocosHistoricos() {
	const { t } = useTranslation();
	var actualDate = new Date();
	actualDate.setDate(actualDate.getDate() - 1);

	const dispatch = useDispatch();
	const [date, setDate] = useState(actualDate);
	const newDate = new Date(date);
	newDate.setHours(date.getHours() + 1);
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		dispatch(appbar.setTitle(t("focos-historicos")));
		dispatch(appbar.setDefaultActions());
	}, []);

	useEffect(() => {
		dispatch(wildfireActions.getWildfiresByDate(date));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const openDialog = () => setDialogOpen(true);
	const closeDialog = () => setDialogOpen(false);

	const onDialogSubmit = (newDate) => {
		setDate(newDate);
	};

	return (
		<>
			<DatePickerDialog
				open={dialogOpen}
				close={closeDialog}
				initialDate={date}
				onSubmit={onDialogSubmit}
			/>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				gap="10px"
				sx={{
					backgroundColor: "white",
					padding: "5px 10px 0px 10px",
					width: "100%",
					height: "fit-content",
					boxSizing: "border-box",
					zIndex: 10,
				}}
			>
				<LightButton
					onClick={openDialog}
					startIcon={<Today />}
					sx={{ padding: "8px 0" }}
				>
					{t('mobile-texto-boton')}
				</LightButton>
				<DatesOverlay startDate={new Date(date.setMinutes(0))} endDate={newDate} />
			</Box>
		</>
	);
}

const DatePickerDialog = ({ open, close, initialDate, onSubmit }) => {
	const {t} = useTranslation();
	const [date, setDate] = useState(initialDate);

	const onDateChange = (newDate) => {
		setDate(newDate);
	};

	const onTimeChange = (e, newTime) => {
		const copiedDate = new Date(date.getTime());

		copiedDate.setHours(newTime);
		copiedDate.setMinutes(0);
		copiedDate.setSeconds(0);
		copiedDate.setMilliseconds(0);

		setDate(copiedDate);
	};

	const submit = () => {
		onSubmit(date);
		close();
	};

	return (
		<MobileDialog
			open={open}
			onClose={close}
			sx={{
				"& .MuiPaper-root": {
					borderRadius: "20px",
					padding: "30px 40px 20px 40px",
					position: "relative",
				},
			}}
		>
			<Box display="flex" flexDirection="column" gap="10px" padding="0 10px">
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<MobileDatePicker
						label={t('focos-historicos')}
						value={date}
						onChange={onDateChange}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>

				<Slider
					aria-label="Small steps"
					defaultValue={date.getHours()}
					valueLabelFormat={valuetext}
					onChangeCommitted={onTimeChange}
					step={1}
					marks={marks}
					min={0}
					max={23}
					valueLabelDisplay="auto"
				/>
			</Box>
			<Box
				display="flex"
				flexDirection="row"
				gap="5px"
				marginTop="30px"
				sx={{ alignSelf: "flex-end" }}
			>
				<Button variant="text" onClick={close}>
					{t("boton-cancelar")}
				</Button>
				<Button variant="contained" onClick={submit}>
					{t("boton-confirmar")}
				</Button>
			</Box>
		</MobileDialog>
	);
};

function valuetext(value) {
	return `${value} hs`;
}

const marks = [
	{
		value: 0,
		label: "0hs",
	},
	{
		value: 6,
		label: "6",
	},
	{
		value: 12,
		label: "12",
	},
	{
		value: 18,
		label: "18",
	},
	{
		value: 23,
		label: "23hs",
	},
];
