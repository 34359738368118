import React,{useEffect} from "react";
import MuiDrawer from "@mui/material/Drawer";
import { List, styled } from "@mui/material";
import DrawerHeader from "./DrawerHeader";
import { items } from "../../utils/Utils";
import DrawerListItem from "./DrawerListItem";
import { useSelector } from "react-redux";

import {
	VideoCameraBack
} from "@mui/icons-material";


export default function Drawer({ open, toggleDrawer, setSelectedItem, selectedItem }) {
	const userType = useSelector((state) => state.user?.userData?.userData?.userType);

	useEffect(()=>{
		if(userType === "Cameras" && items.length === 7) insertCamItem();
	},[userType])
	return (
		<StyledDrawer id="drawer" variant="permanent" open={open}>
			<DrawerHeader open={open} toggleDrawer={toggleDrawer} />
			<List sx={{ fontSize: "1.5rem" }}>
				{items.map((item, index) => {
					return (
						<DrawerListItem
						key={`drawer-item-${index}`}
						item={item}
						index={index}
						open={open}
						selected={selectedItem === index}
						setSelectedItem={setSelectedItem}
						/>
						);
					})}
			</List>
		</StyledDrawer>
	);
}

const StyledDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: "fit-content",
	height: "fit-content",
	marginLeft: "20px",
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	"& .MuiDrawer-paper": {
		backgroundColor: "#F4F3FB",
		position: "relative",
		height: "fit-content",
		borderRadius: "10px",
		zIndex: 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: open ? `clamp(320px, 21vw, 400px)` : `calc(${theme.spacing(7)} + 1px)`,
		padding: theme.spacing(2, .5, 5, .5),
		boxSizing: "content-box",
	},
}));

const insertCamItem = () =>{
	const item = {
		icon: VideoCameraBack,
		label: "Camaras",
	}
	items.splice(8, 0, item);
}
