import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Configuracion from "../Tabs/Configuracion";
import MisZonas from "../Tabs/MisZonas";
import TiempoReal from "../Tabs/TiempoReal";
import FocosHistoricos from "../Tabs/FocosHistoricos";
import { Switch, Route } from "react-router-dom";
import Zona from "../MisZonas/Zona";
import ZonaCamaras from "../MisZonas/ZonaCamaras";
import { useSelector } from "react-redux";
import MisCamaras from "../MisZonas/MisCamaras";

export default function Panel({ show }) {
	const [drawerHeight, setDrawerHeight] = useState(0);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

	useEffect(() => {
		setDrawerHeight(document.getElementById("drawer").clientHeight);
		const observer = new ResizeObserver((e) => {
			setDrawerHeight(document.getElementById("drawer").clientHeight);
		});
		observer.observe(document.querySelector("#drawer"));
		return () => observer.disconnect();
	}, []);

	return (
		<Box
			sx={{
				backgroundColor: "#F4F3FB",
				borderRadius: "10px",
				width: show ? "0px" : "clamp(310px, 21vw, 400px)",
				maxHeight: drawerHeight,
				padding: show ? "20px 0px" : "20px 20px",
				boxSizing: "border-box",
				transition: "all .2s",
				overflow: "hidden",
				zIndex: 1,
			}}
		>
			<Switch>
				<Route exact path="/" component={TiempoReal} />
				<Route exact path="/focos-historicos" component={FocosHistoricos} />
				<Route exact path="/alertas/zona" component={Zona} />
				<Route exact path="/camaras" component={MisCamaras} />
				<Route exact path="/camaras/cam" component={ZonaCamaras} />
				<Route path="/alertas" component={MisZonas} />
				{isLoggedIn ? (
					<></>
				) : (
					<Route exact path="/configuracion" component={Configuracion} />
				)}
			</Switch>
		</Box>
	);
}
