import React from "react";
import { useTranslation } from 'react-i18next'
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
	Update,
	HistoryToggleOff,
	AddAlertOutlined,
} from "@mui/icons-material";

export default function BottomNavBar({ selectedItem, setSelectedItem }) {
	const {t} = useTranslation();
	return (
		<BottomNavigation
			showLabels
			value={selectedItem}
			onChange={(e, newValue) => {
				setSelectedItem(newValue);
			}}
			sx={{
				zIndex: 10,
				backgroundColor: "#FEFBFF",
				height: "fit-content",
				boxShadow: "0px -2px 5px 1px rgba(0,0,0,0.2)",
				"& .MuiBottomNavigationAction-root": {
					backgroundColor: "inherit",
					gap: "3px",
				},
				"& .MuiSvgIcon-root": { padding: "3px 0", boxSizing: "content-box" },
				"& .Mui-selected": {
					color: "#00164F",
					"& .MuiSvgIcon-root": {
						fill: "#00164F",
						position: "relative",
						bgcolor: "primary.light",
						width: "60px",
						borderRadius: "20px",
					},
				},
			}}
		>
			<BottomNavigationAction label={t('tiempo-real')} icon={<HistoryToggleOff />} />
			<BottomNavigationAction label={t('focos-historicos')} icon={<Update />} />
			<BottomNavigationAction
				label={t('alertas')}
				icon={<AddAlertOutlined />}
			/>
		</BottomNavigation>
	);
}
