import { Box, Divider, Slide, TextField, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next'
import React, { useState } from "react";
import {
	MobileDialog,
	DialogContent,
	MobileDialogTitle,
	MobileDialogActions,
} from "../../StyledComponents/Dialog";
import { Button, LoadingButton } from "../../StyledComponents/Button";
import { useDispatch } from "react-redux";
import { actions as userActions } from "../../../redux/slices/userSlice";
import { NotificationsActiveOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedeemCode({ open, close }) {
	//const { t} = useTranslation('ns1', { useSuspense: false });
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const codeRef = React.useRef();
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = () => {
		setSubmitting(true);
		dispatch(
			userActions.redeemPromoCode({
				code: codeRef.current.value,
				close: handleClose,
			})
		);
	};

	const handleClose = async () => {
		close();
		setSubmitting(false);
	};

	return (
		<>
			<MobileDialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="md"
				TransitionComponent={Transition}
			>
				<NotificationsActiveOutlined
					color="primary"
					sx={{ alignSelf: "center", height: "30px", width: "30px" }}
				/>
				<MobileDialogTitle>{t('suscripcion-alertas-titulo')}</MobileDialogTitle>
				<Divider />
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "40px",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						gap="20px"
						alignItems="center"
					>
						<Typography
							variant="span"
							color="#707176"
							fontSize="1rem"
							width="95%"
						>
							{t('suscripcion-alertas-texto')}
						</Typography>
						<TextField
							inputRef={codeRef}
							name="name"
							label={t('ingresar-codigo-suscripcion')}
							variant="standard"
							sx={{ width: "90%" }}
							required
						/>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						gap="10px"
						sx={{ alignSelf: "flex-end" }}
					></Box>
				</DialogContent>
				<MobileDialogActions>
					<Button onClick={close} variant="text" sx={{ width: "fit-content" }}>
					{t("boton-cancelar")}
					</Button>
					<LoadingButton
						loading={submitting}
						onClick={handleSubmit}
						sx={{ width: "fit-content", alignSelf: "flex-end" }}
					>
						{t("boton-ingresar")}
					</LoadingButton>
				</MobileDialogActions>
			</MobileDialog>
		</>
	);
}
