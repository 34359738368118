import React from 'react'
import '../../scss/modal.scss'
import GenericModal from '../modals/GenericModal'
import NewVersionDialog from '../modals/NewUpdate'
import TourModal from '../modals/TourModal'

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { selectOpenConfigurationModal, selectOpenGenericModal, actions as modalActions} from '../../redux/slices/modalsSlice'
import {
    Modal,
    Backdrop,
    Fade,
} from '@mui/material';


export default function GeneralModal() {

    const dispatch = useDispatch();
    const openConfigurationModal = useSelector(selectOpenConfigurationModal);
    const openGenericModal = useSelector(selectOpenGenericModal);

    return (
    <>
    <Modal
        open={openConfigurationModal}
        onClose={() => dispatch(modalActions.toggleConfigurationModal())}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        closeAfterTransition
    >
        <Fade in={openConfigurationModal} >
            <div className="mody"/* {classes.modalStyle} */>
            </div>
        </Fade>

    </Modal>

    <Modal
        open={openGenericModal}
        onClose={() => dispatch(modalActions.toggleGenericModal())}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        closeAfterTransition
    >
        <Fade in={openGenericModal} >
            <div className="mody-beta">
                <GenericModal/>
            </div>
        </Fade>

    </Modal>

    {/* <Modal
        open={openBetaModal}
        onClick={()=>ReactGA.event({ category: 'BETA', action: sub})}
        onClose={() => dispatch(modalActions.toggleBetaModal())}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        closeAfterTransition
    >
        <Fade in={openBetaModal} >
            <div className="mody-beta"{classes.modalStyle}>
                <BetaDescription/>
            </div>
        </Fade>

    </Modal> */}

    <TourModal/>

    {/* <Modal
        open={openNewVersionDialog}
        //onClick={()=>ReactGA.event({ category: 'BETA', action: sub})}
        onClose={() => dispatch(modalActions.toggleNewVersionDialog())}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        closeAfterTransition
    >
        <Fade in={openNewVersionDialog} >
            <BetaDescription/>
        </Fade>

    </Modal> */}
    <NewVersionDialog/>

    </>
    );
}
