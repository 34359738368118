import { useTranslation } from 'react-i18next'
import { Box, Divider, Slide, TextField, Typography } from "@mui/material";
import React from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
} from "../../StyledComponents/Dialog";
import { Button, LoadingButton } from "../../StyledComponents/Button";
import { useDispatch } from "react-redux";
import { actions as userActions } from "../../../redux/slices/userSlice";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedeemCode({ open, close }) {
	const dispatch = useDispatch();
	const { t } = useTranslation()
	const codeRef = React.useRef();
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = () => {
		setSubmitting(true);
		dispatch(
			userActions.redeemPromoCode({
				code: codeRef.current.value,
				close: handleClose,
			})
		);
	};

	const handleClose = async () => {
		close();
		setSubmitting(false);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="sm"
				TransitionComponent={Transition}
			>
				<DialogTitle>{t('suscripcion-alertas-texto')}</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "40px",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						gap="20px"
						alignItems="center"
					>
						<Typography
							variant="span"
							color="#707176"
							fontSize="1rem"
							width="95%"
						>
							{t('suscripcion-alertas-texto')}
						</Typography>
						<TextField
							inputRef={codeRef}
							name="name"
							label={t('ingresar-codigo-suscripcion')}
							variant="standard"
							sx={{ width: "90%" }}
							required
						/>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						gap="10px"
						sx={{ alignSelf: "flex-end" }}
					>
						<Button
							onClick={close}
							variant="text"
							sx={{ width: "fit-content" }}
						>
							{t("boton-cancelar")}
						</Button>
						<LoadingButton
							loading={submitting}
							onClick={handleSubmit}
							variant="contained"
							sx={{ width: "fit-content", alignSelf: "flex-end" }}
						>
							{t("boton-ingresar")}
						</LoadingButton>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
