export function getMockPolygons () {
    return [
        {
            id: '8002a75a-1ee9-42f0-80ac-ff2d32dc5943',
            name: 'Poligono de Ejemplo',
            description: 'Este es un ejemplo de poligono para usar en la App',
            coords: [
              [
                -13.019276068411179,
                -62.375907897949226
              ],
              [
                -12.981235880316984,
                -66.18816375732423
              ],
              [
                -37.69531866327759,
                -71.55498504638673
              ],
              [
                -43.04153759713123,
                -71.50314331054689
              ],
              [
                -45.91231673410844,
                -67.6747512817383
              ],
              [
                -42.888175161607,
                -64.73419189453126
              ],
              [
                -39.94220998614376,
                -64.88903045654298
              ],
              [
                -40.89819310077423,
                -62.87647247314454
              ],
              [
                -39.09171030993697,
                -61.690292358398445
              ],
              [
                -22.099799315495037,
                -42.22354888916016
              ],
              [
                -17.412388867461456,
                -53.64624023437501
              ]
            ],
            detectRays: true,
            detectWildfires: true,
            __typename: 'Polygon'
        }
    ]
}

const exported = { getMockPolygons }

export default exported;