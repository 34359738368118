import { LeafletContextInterface, LeafletProvider } from "@react-leaflet/core";
import L, { type FitBoundsOptions, type LatLngBoundsExpression, type MapOptions } from "leaflet";
import React, { type CSSProperties, type ReactNode, type Ref } from "react";

export type WindyMapLayer = "wind" | "temp" | "pressure";

export interface MapContainerProps extends MapOptions {
  bounds?: LatLngBoundsExpression
  boundsOptions?: FitBoundsOptions
  children?: ReactNode
  className?: string
  placeholder?: ReactNode
  id?: string
  style?: CSSProperties
  whenReady?: () => void
  windyLayer: WindyMapLayer
}

function BaseMap<Props extends MapContainerProps = MapContainerProps,> ({
  bounds, boundsOptions, center, children, className, id, placeholder, style, whenReady, zoom, windyLayer,
  ...options}: Props, forwardedRef: Ref<L.Map | null>) {
  const props = { className, id, style };
  const [context, setContext] = React.useState<LeafletContextInterface | null>(null);
  const [script, setScript] = React.useState<HTMLScriptElement | null>(null);
  const [store, setStore] = React.useState<any>(null); // Tenemos que usar any, la API de windy no tiene tipos

  React.useImperativeHandle(forwardedRef, () => context?.map ?? null, [context]);

  const mapRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null && context === null && script === null) {
      const script = document.createElement("script");
      script.src = "https://api.windy.com/assets/map-forecast/libBoot.js";
      script.async = true;
  
      script.onload = () => {
        const options = {
          key: process.env.WINDY_API_KEY || "5cITMYOmmv5DeLJ1hu8LtpiNkhdfzowU",
          verbose: false,
          lat: -20.5,
          lon: -60.5,
          zoom: 4,
        };
  
        // @ts-ignore
        windyInit(options, windyAPI => {
          const map: L.Map = windyAPI.map;
          map.doubleClickZoom.disable();

          map.on('dblclick', function(event) {
            var lat = event.latlng.lat;
            var lon = event.latlng.lng;
            windyAPI.picker.open({ lat, lon })
          });

          setStore(windyAPI.store);
          setContext({ map, __version: 1 });
        });
      };
  
      document.body.appendChild(script);
      setScript(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      window.W = void 0;
      // @ts-ignore
      window.wError = void 0;
      // @ts-ignore
      window.windyInit = void 0;
      // @ts-ignore
      window.windySentErrors = void 0;
      
      // @ts-ignore
      [...document.querySelectorAll('script[src*="windy"]')]
        .forEach((el) => el.remove());
      
      context?.map.remove();
      script?.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (store) {
      store.set("overlay", windyLayer);
    }
  }, [store, windyLayer]);

  const contents = context ? (
    <LeafletProvider value={context}>{children}</LeafletProvider>
  ) : (
    placeholder ?? null
  )

  return (
    <div ref={mapRef} {...props} id="windy">
      {contents}
    </div>
  );
};

export const WindyMap = React.forwardRef(BaseMap);
export default WindyMap;