import { useTranslation } from 'react-i18next'
import React from "react";
import { Typography, Box, TextField, Slider } from "@mui/material";
import PanelHeader from "../Panel/PanelHeader";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import { useDispatch } from "react-redux";
import { actions as wildfireActions } from "../../../redux/slices/wildfireSlice";
import DatesOverlay from "../Misc/DatesOverlay";

export default function FocosHistoricos() {
	const { t } = useTranslation()
	var actualDate = new Date();
	actualDate.setHours(actualDate.getHours() - 1);

	const dispatch = useDispatch();
	const [date, setDate] = React.useState(actualDate);
	const newDate = new Date(date);
	newDate.setHours(date.getHours() + 1);

	React.useEffect(() => {
		dispatch(wildfireActions.getWildfiresByDate(date));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const onDateChange = (newDate) => {
		setDate(newDate);
	};

	const onTimeChange = (e, newTime) => {
		const copiedDate = new Date(date.getTime());

		copiedDate.setHours(newTime);
		copiedDate.setMinutes(0);
		copiedDate.setSeconds(0);
		copiedDate.setMilliseconds(0);

		setDate(copiedDate);
	};

	return (
		<>
			<DatesOverlay startDate={new Date(date.setMinutes(0))} endDate={newDate} />
			<Box sx={{ height: "100%" }} display="flex" flexDirection="column">
				<PanelHeader title={t('focos-historicos')} />
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					gap="10px"
					sx={{ marginTop: "20px", height: "100%" }}
				>
					<Box display="flex" flexDirection="column" gap="30px">
						<Typography variant="span" color="#44464E" fontSize=".9rem">
							{t("typography-focos-historicos")}
						</Typography>

						<Box
							display="flex"
							flexDirection="column"
							gap="10px"
							padding="0 10px"
						>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label={t('focos-historicos')}
									value={date}
									onChange={onDateChange}
									renderInput={(params) => <TextField {...params} />}
									minDate={new Date("Fri Aug 06 2021 17:06:06 GMT-0300")}
									maxDate={new Date()}
								/>
							</LocalizationProvider>

							<Slider
								aria-label="Small steps"
								defaultValue={date.getHours()}
								valueLabelFormat={valuetext}
								onChangeCommitted={onTimeChange}
								step={1}
								marks={marks}
								min={0}
								max={23}
								valueLabelDisplay="auto"
							/>
						</Box>
					</Box>

					{/* <LinkButton
						href="https://sof-wildfire-bucket.s3.amazonaws.com/Instructivo+SoF.pdf"
						target="_blank"
					>
						Ver instructivo
					</LinkButton> */}
				</Box>
			</Box>
		</>
	);
}

function valuetext(value) {
	return `${value} hs`;
}

const marks = [
	{
		value: 0,
		label: "0hs",
	},
	{
		value: 6,
		label: "6",
	},
	{
		value: 12,
		label: "12",
	},
	{
		value: 18,
		label: "18",
	},
	{
		value: 23,
		label: "23hs",
	},
];
