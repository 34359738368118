import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { countryNames } from "../api/utils/countries";
import { countryToContinent } from "../api/utils/continents";

const avaidableContinents = ["SA", "NA"];

function CountryAvaidabilityCheckerDialog (props) {
// https://ipinfo.io/developers
    const { t } = useTranslation();
    
    const openForm = () => {
        props.setOpen(false);
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSd5Q19EXYoEpQAyjExokVvlRNXvPQYV-0z5pe9PIuUn9F1LPQ/viewform?usp=sf_link", "_blank");
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>{t("servicio-no-disponible-en") + " " + props.country}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("servicio-no-disponible-en-descripcion")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => props.setOpen(false)}>{t("continuar-utilizando-sistema")}</Button>
                <Button variant="contained" onClick={openForm}>{t("completar-formulario")}</Button>
            </DialogActions>
        </Dialog>
    )
}

CountryAvaidabilityCheckerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    country: PropTypes.string,
}

export default function CountryAvaidabilityChecker (props) {

    const [country, setCountry] = React.useState("");

    React.useEffect(() => {
        const asyncEvent = async () => {
            const countryIs = await axios.get("https://api.country.is/");
            const countryCode = countryIs.data.country;
            setCountry(countryNames[countryCode]);

            if (!avaidableContinents.includes(countryToContinent[countryCode])) props.setOpen(true);
            props.setHasCheckedCountry(true);
        } 
        asyncEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <CountryAvaidabilityCheckerDialog open={props.open} setOpen={props.setOpen} country={country} />
}

CountryAvaidabilityChecker.propTypes = {
    open: PropTypes.bool.isRequired,
    setHasCheckedCountry: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
}
