/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import MuiListItem from "@mui/material/ListItem";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loginIlustration from "../../../assets/loginIlustration.svg";
import notPremiumIlustration from "../../../assets/notPremiumIlustration.svg";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { actions as cameraActions } from "../../../redux/slices/cameraSlice";
import { actions as settings } from "../../../redux/slices/settingsSlice";
import TourResumer from '../../utils/TourResumer';
import { Button } from "../../StyledComponents/Button";

export default function MisCamaras() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const userType = useSelector((state) => state.user?.userData?.userData?.userType);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const cameras = useSelector((state) => state.camera.cameras);


	useEffect(() => {
		dispatch(appbar.setTitle(t('Camaras')));
	}, []);



	useEffect(() => {
		if (isLoggedIn) dispatch(cameraActions.getCameras());
	}, [isLoggedIn]);

	useEffect(() => {
		dispatch(settings.checkNotificationsEnabled());
	}, [dispatch]);


	const handleSendToForm = () => {
		history.push("/form");
	}

	const NotPremium = () => {
		const { t } = useTranslation();
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				gap="10px"
				overflow="hidden"
				sx={{ marginTop: "20px", height: "100%" }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="100%"
					textAlign="center"
					gap="20px"
					sx={{
						"& img": {
							width: "90%",
							maxHeight: "40%",
						},
						"& span.MuiTypography-root": {
							width: "95%",
						},
					}}
				>
					<img src={notPremiumIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t('rellenar-form-texto')}
					</Typography>
					<Button
						variant="contained"
						sx={{ width: "100%" }}
						onClick={() => handleSendToForm()}
					>
						{t('boton-rellenar-form')}
					</Button>
				</Box>
			</Box>
		);
	};

	return (
		<>
			<Box
				sx={{ height: "100%" }}
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				{isLoggedIn ? (
					<>
						<TourResumer wakeUpSteps={[0, 6, 12, 16]} />
						{userType === "Cameras" ? (
							<Box
								id="tour-6"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								gap="10px"
								overflow="hidden"
								sx={{ marginTop: "20px", height: "100%", width: "90%" }}
							>
								{Object.values(cameras).length ? (
									<Box
										display="flex"
										flexDirection="column"
										gap="10px"
										overflow="hidden"
									>
										<Typography
											variant="span"
											color="#44464E"
											fontSize=".9rem"
											textAlign="center"
										>
											{t('typography-alertas')}
										</Typography>

										<List /* id="tour-7" */ sx={{ overflowY: "auto" }}>
											{Object.values(cameras).map((item, i) => {
												return <ListItem key={`cam-${item.date_id}`} item={item} />;
											})}
										</List>
									</Box>
								) : (
									<NoZones />
								)}

							</Box>
						) : (
							<NotPremium />
						)
						}
					</>
				) : (
					<NotLoggedIn />
				)}
			</Box>
		</>
	);
}

const ListItem = ({ item }) => {
	const history = useHistory();
	return (
		<>
			<MuiListItem
				disablePadding
			>
				<ListItemButton
					onClick={() => history.push("/camaras/cam?id=" + item.cameraId)}
				>
					<ListItemText primary={item.name}/>
				</ListItemButton>
			</MuiListItem>
			<Divider />
		</>
	);
};

export const NotLoggedIn = () => {
	const history = useHistory();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="space-between"
			height="100%"
			textAlign="center"
			padding="30px 0"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="100%"
				gap="20px"
				sx={{
					maxWidth: "90%",
					"& img": {
						width: "60%",
						maxHeight: "50%",
					},
				}}
			>
				<img src={loginIlustration} alt="ilustración" />
				<Typography variant="span" color="#1B1B1F" fontSize="1rem">
					{t("no-logueado-alertas")}
				</Typography>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "90%" }}
				onClick={() => history.push("/login")}
			>
				{t("iniciar-sesion")}
			</Button>
		</Box>
	);
};

const NoZones = () => {

	const { t } = useTranslation();

	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
				textAlign="center"
				padding="30px 0"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="100%"
					gap="20px"
					sx={{
						maxWidth: "90%",
						"& img": {
							width: "80%",
							maxHeight: "50%",
						},
					}}
				>
					<img src={noZoneIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{t("no-zonas-texto")}
					</Typography>
				</Box>
			</Box>
		</>
	);
};
