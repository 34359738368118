import React, { useState, useEffect } from "react";
import "../../scss/form.scss";
import { Box, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { actions as userActions } from "../../redux/slices/userSlice";
import userAccess from "../../api/user/userAccess.json";
import { useAppSelector, useAppDispatch } from "../../hooks";

const INTERVAL_ATTEMPTS = 5;

function getFormUrl(email, name, surname, organizationType, organizationName, phone, country, language) {
	
	if (language.includes("es")) return `https://docs.google.com/forms/d/e/1FAIpQLScD_c-hzUnRojixUfUG056TAc87n5LIGhDquV2yOir1qaaDSQ/viewform?usp=pp_url&entry.572884849=${email}&entry.1204964377=${name}&entry.2005620554=${surname}&entry.1985826325=${organizationName}&entry.1849818375=${phone}&entry.1924109539=${country}&entry.1349903624=${organizationType}&embedded=true`;
	else return `https://docs.google.com/forms/d/e/1FAIpQLScfdOZYEEcqtJ4uyFnsGuqpm1bAXDv_8UkvkkwIDSFHCUksHQ/viewform?usp=pp_url&entry.635401831=${email}&entry.1204964377=${name}&entry.2005620554=${surname}&entry.1985826325=${organizationName}&entry.1849818375=${phone}&entry.1400024060=${country}&entry.1349903624=${organizationType}&embedded=true`;

}

export default function Form() {
	const language = useTranslation().i18n.language;

	const [loadedPages, setLoadedPages] = useState(0);
	const [fetchCount, setFetchCount] = useState(0);
	const [timeoutId, setTimeoutId] = useState(null);

	const history = useHistory();
	const dispatch = useAppDispatch();

	// Redux state

	const isLoggedIn = useAppSelector((state) => state.user?.isLoggedIn);
	const userType = useAppSelector((state) => state.user?.userData?.userData?.userType);
	const userInfo = useAppSelector((state) => state.user.userData?.userData);

	// Precompleted user data

	const email = userInfo?.email || "";
	const name = userInfo?.name.substring(0, userInfo?.name.indexOf(' ')) || userInfo?.name || "";
	const surname = userInfo?.name.substring(userInfo?.name.indexOf(' ') + 1) || "";
	const organizationType = userInfo?.organizationType || "";
	const organizationName = userInfo?.organizationName || "";
	const phone = userInfo?.phone || "";
	const country = userInfo?.country || "";

	// End of precompleted user data

	useEffect(() => {
		if (isLoggedIn === false) history.push("/login");
		//eslint-disable-next-line
	}, [isLoggedIn]);

	useEffect(() => {
		if (userAccess.alerts.includes(userType)) {
			if (timeoutId !== null) clearTimeout(timeoutId);
			history.push("/alertas");
		}
		//eslint-disable-next-line
	}, [userType, history]);

	useEffect(() => {
		if (loadedPages < 2) return;
		const timeoutId = setInterval(() => setFetchCount((befFetchCount) => befFetchCount + 1), 3500);
		setTimeoutId(timeoutId);
	}, [loadedPages]);

	useEffect(() => {
		if (fetchCount >= INTERVAL_ATTEMPTS && timeoutId !== null) clearTimeout(timeoutId);
		if (fetchCount > 0) dispatch(userActions.refreshUserData());
		//eslint-disable-next-line
	}, [fetchCount]);

	return (
		<Box height="100%" display="flex" flexDirection="column" style={{ backgroundColor: "rgb(201, 207, 225)" }}>
			<IconButton
				className="arrow-back"
				onClick={() => history.push("/")}
				size="large"
				sx={{ alignSelf: "flex-start" }}
			>
				<ArrowBack />
			</IconButton>
			<iframe
				src={getFormUrl(email, name, surname, organizationType, organizationName, phone, country, language)}
				title="Form"
				width="100%"
				height="100%"
				frameBorder="0"
				marginHeight="0"
				marginWidth="0"
				onLoad={() => setLoadedPages(loadedPages + 1)}
			>
				Cargando…
			</iframe>
		</Box>
	);
}
