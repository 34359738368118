import React from 'react';
import RegisterComponent from '../../components/user/SignUpComponent'


function Register(){
    return(
        <>
            <RegisterComponent/>
        </>
    );
}

export default Register